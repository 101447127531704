import React from "react";

import { Table, Button } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";

import { maskBr } from "js-brasil";

function TableProperty(props) {
  const {
    propertyList,
    inputSearch,
    client_name,
    setShowSearchClientModal,
    setShowEditPropertyModal,
    setShowDeletePropertyModal,
    toggleSpinner,
  } = props;

  return (
    <Table striped bordered hover size="sm" className="rounded">
      <thead>
        <tr>
          <th width="100%" colSpan="5" className="align-middle text-center">
            <Button
              variant="outline-dark"
              size="sm"
              onClick={() => setShowSearchClientModal(true)}
            >
              <FaSearch />
              &nbsp;
              {client_name === "" ? "Selecione um Produtor" : client_name}
            </Button>
          </th>
        </tr>
        <tr>
          <th width="2%">#</th>
          <th width="53%">Propriedade</th>
          <th width="25%" className="text-center">
            CNPJ/CPF
          </th>
          <th width="10%" className="text-center">
            Editar
          </th>
          <th width="10%" className="text-center">
            Apagar
          </th>
        </tr>
      </thead>
      <tbody>
        {propertyList.length === 0 && client_name && toggleSpinner === true && (
          <tr>
            <td width="100%" colSpan="5" className="align-middle text-center">
              Nenhuma Propriedade foi Encontrada
            </td>
          </tr>
        )}
        {propertyList
          .sort(function (a, b) {
            var nameA = a.name.toLowerCase(),
              nameB = b.name.toLowerCase();
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
          })
          .filter(
            (item) =>
              item.id.indexOf(inputSearch) !== -1 ||
              item.name.toUpperCase().indexOf(inputSearch.toUpperCase()) !== -1,
          )
          .map((property, index) => (
            <tr key={index}>
              <td width="2%" className="align-middle">
                <b>{index + 1}</b>
              </td>
              <td width="53%" className="align-middle">
                {property.name}
              </td>
              <td width="25%" className="text-center  align-middle">
                {property.socialID.length === 14
                  ? maskBr.cnpj(property.socialID)
                  : maskBr.cpf(property.socialID)}
              </td>
              <td width="10%" className="text-center align-middle">
                <Button
                  className="bg-system"
                  size="sm"
                  onClick={() =>
                    setShowEditPropertyModal({
                      id: property.id,
                      name: property.name,
                      socialID: property.socialID,
                      answerablePhone: property.answerablePhone,
                      answerableName: property.answerableName,
                      guideScript: property.guideScript,
                      postalCode: property.address.postalCode,
                      state: property.address.state,
                      city: property.address.city,
                      neighborhood: property.address.neighborhood,
                      street: property.address.street,
                      houseNumber: property.address.houseNumber,
                      imageHeavy: property.imageHeavy,
                      imageLight: property.imageLight,
                      show: true,
                    })
                  }
                >
                  Editar
                </Button>
              </td>
              <td width="10%" className="text-center align-middle">
                <Button
                  disabled
                  variant="outline-danger"
                  size="sm"
                  onClick={() =>
                    setShowDeletePropertyModal({
                      id: property.id,
                      name: property.name,
                      show: true,
                    })
                  }
                >
                  Apagar
                </Button>
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
}

export default TableProperty;
