import consultCepAPI from "../configurations/cepConfig";

async function consultCep(CEP) {
  try {
    const response = await consultCepAPI.get(`ws/${CEP}/json/`);
    return response.data;
  } catch (error) {
    return [];
  }
}

function consultProvince(UF) {
  var province_id = "";

  if (UF === "AC") province_id = "01";
  if (UF === "AL") province_id = "02";
  if (UF === "AP") province_id = "03";
  if (UF === "AM") province_id = "04";
  if (UF === "BA") province_id = "05";
  if (UF === "CE") province_id = "06";
  if (UF === "DF") province_id = "07";
  if (UF === "ES") province_id = "08";
  if (UF === "GO") province_id = "09";
  if (UF === "MA") province_id = "10";

  if (UF === "MT") province_id = "11";
  if (UF === "MS") province_id = "12";
  if (UF === "MG") province_id = "13";
  if (UF === "PA") province_id = "14";
  if (UF === "PB") province_id = "15";
  if (UF === "PR") province_id = "16";
  if (UF === "PE") province_id = "17";
  if (UF === "PI") province_id = "18";
  if (UF === "RJ") province_id = "19";
  if (UF === "RN") province_id = "20";

  if (UF === "RS") province_id = "21";
  if (UF === "RO") province_id = "22";
  if (UF === "RR") province_id = "23";
  if (UF === "SC") province_id = "24";
  if (UF === "SP") province_id = "25";
  if (UF === "SE") province_id = "26";
  if (UF === "TO") province_id = "27";

  return province_id;
}

export { consultCep, consultProvince };
