import React, { useState, useEffect } from "react";

import { Container, Modal, Button, Table, Form } from "react-bootstrap";

import { FaSearch } from "react-icons/fa";

import { getClients } from "../../../controllers/ControllerClient";

function SearchClient(props) {
  const {
    showSearchClientModal,
    setShowSearchClientModal,
    handleClient,
    clearClient,
    client_id,
    code3c_id,
  } = props;

  const [clientList, setClientList] = useState([]);
  const [inputSearch, setInputSearch] = useState("");

  function handleClose() {
    setShowSearchClientModal(!showSearchClientModal);
  }

  useEffect(() => {
    async function get3cClients() {
      const response = await getClients(code3c_id);
      if (response) {
        setClientList(response);
      }
    }
    get3cClients();
  }, [showSearchClientModal, code3c_id]);

  return (
    <Container className="ubuntuFont">
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        className="ubuntuFont"
        show={showSearchClientModal}
        onHide={handleClose}
        centered
      >
        <Modal.Header className="text-center" closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-center"
          >
            <FaSearch className="text-system" />
            &emsp;Selecione um Produtor
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            type="text"
            placeholder="Pesquisar Produtor"
            size="sm"
            className="mb-3 px-3"
            value={inputSearch}
            onChange={(e) => setInputSearch(e.target.value)}
            required
          />
          <Container className="table-max-height">
            <Table striped bordered hover size="sm" className="rounded">
              <thead>
                <tr>
                  <th width="2%">#</th>
                  <th width="88%">Produtor</th>
                  <th width="10%" className="text-center">
                    Selecionar
                  </th>
                </tr>
              </thead>
              <tbody>
                {client_id !== "" && (
                  <tr>
                    <td
                      width="100%"
                      colSpan="3"
                      className="align-middle text-center"
                    >
                      <Button
                        variant="outline-danger"
                        size="sm"
                        onClick={() => clearClient()}
                      >
                        Limpar Produtor
                      </Button>
                    </td>
                  </tr>
                )}
                {clientList
                  .sort(function (a, b) {
                    var nameA = a.name.toLowerCase(),
                      nameB = b.name.toLowerCase();
                    if (nameA < nameB) return -1;
                    if (nameA > nameB) return 1;
                    return 0;
                  })
                  .filter(
                    (item) =>
                      item.id.indexOf(inputSearch) !== -1 ||
                      item.name
                        .toUpperCase()
                        .indexOf(inputSearch.toUpperCase()) !== -1,
                  )
                  .map((client, index) => (
                    <tr key={index}>
                      <td width="2%" className="align-middle">
                        <b>{index + 1}</b>
                      </td>
                      <td width="63%" className="align-middle">
                        {client.name}
                      </td>
                      <td width="10%" className="text-center align-middle">
                        <Button
                          disabled={client_id === client.id ? true : false}
                          className="bg-system"
                          size="sm"
                          onClick={() => handleClient(client.id, client.name)}
                        >
                          Selecionar
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => handleClose()}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default SearchClient;
