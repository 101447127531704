import React from "react";
import { Tooltip } from "react-bootstrap";

function TooltipRecommendation(props) {
  return (
    <Tooltip id="button-tooltip" {...props}>
      Imagem enviada posteriormente a visita!
    </Tooltip>
  );
}

export default TooltipRecommendation;
