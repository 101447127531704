import React from "react";

import { Col, Row } from "react-bootstrap";

import { parsePesticideType } from "../../../functions/pesticide";

function TableRecommendationType(props) {
  const { pesticideList } = props;

  return (
    <>
      {pesticideList && pesticideList.length > 0 ? (
        pesticideList.map((pesticide, index) => (
          <div id="container-dont-break" key={index}>
            {index === 0 && <hr className="m-0" />}
            <Row className="text-size-minus-12 ">
              <Col md={5} className="border-system-right-alpha">
                <p className="font-weight-bold my-0">Defensivo:</p>
                <p className="text-left my-0 px-1">
                  {pesticide.pesticide_name}
                </p>
                <p className="font-weight-bold my-0">Tipo:</p>
                <p className="text-left my-0 px-1">
                  {parsePesticideType(pesticide.pesticide_typeOf)}
                </p>
              </Col>
              <Col md={7}>
                <p className="font-weight-bold my-0">Dose:</p>
                <p className="text-justify my-0 px-1">{pesticide.dose}</p>
              </Col>
            </Row>
            <hr className="m-0" />
          </div>
        ))
      ) : (
        <p className="text-center"> Sem recomendações.</p>
      )}
    </>
  );
}

export default TableRecommendationType;
