import React, { useState, useEffect } from "react";

import { Table, Button, Spinner, Row } from "react-bootstrap";

import { getProperties } from "../../../controllers/ControllerProperty";

function TableSearchProperty(props) {
  const {
    client_id,
    property_id,
    inputSearch,
    handleProperty,
    clearClient,
    showSearchPropertyModal,
  } = props;

  const [propertyList, setPropertyList] = useState([]);
  const [pending, setPending] = useState(false);

  useEffect(() => {
    async function get3cClients() {
      const response = await getProperties(client_id);
      if (response) {
        setPropertyList(response);
        setPending(true);
      }
    }
    get3cClients();
  }, [showSearchPropertyModal, client_id]);

  return propertyList.length === 0 && !pending ? (
    <Row>
      <Spinner className="mx-auto my-5 text-system" animation="border" />
    </Row>
  ) : (
    <Table striped bordered hover size="sm" className="rounded">
      {client_id !== "" && (
        <thead>
          <tr>
            <th width="100%" colSpan="3" className="align-middle text-center">
              <Button
                variant="outline-danger"
                size="sm"
                onClick={() => clearClient()}
              >
                Voltar
              </Button>
            </th>
          </tr>
        </thead>
      )}
      <thead>
        <tr>
          <th width="2%">#</th>
          <th width="88%">Proprietário</th>
          <th width="10%" className="text-center">
            Selecionar
          </th>
        </tr>
      </thead>
      <tbody>
        {propertyList.length === 0 && pending === true && (
          <tr>
            <td width="100%" colSpan="6" className="align-middle text-center">
              Nenhum Proprietário Encontrado
            </td>
          </tr>
        )}
        {propertyList
          .sort(function (a, b) {
            var nameA = a.name.toLowerCase(),
              nameB = b.name.toLowerCase();
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
          })
          .filter(
            (item) =>
              item.id.indexOf(inputSearch) !== -1 ||
              item.name.toUpperCase().indexOf(inputSearch.toUpperCase()) !== -1,
          )
          .map((property, index) => (
            <tr key={index}>
              <td width="2%" className="align-middle">
                <b>{index + 1}</b>
              </td>
              <td width="63%" className="align-middle">
                {property.name}
              </td>
              <td width="10%" className="text-center align-middle">
                <Button
                  disabled={property_id === property.id ? true : false}
                  className="bg-system"
                  size="sm"
                  onClick={() => handleProperty(property.id, property.name)}
                >
                  Selecionar
                </Button>
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
}

export default TableSearchProperty;
