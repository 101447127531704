import * as yup from "yup";
import { pt } from "yup-locale-pt";

yup.setLocale(pt);

function parsePesticideType(pesticideType) {
  switch (pesticideType) {
    case "herbicide":
      return "Herbicida";

    case "insecticide":
      return "Inseticida";

    case "fungicide":
      return "Fungicida";

    case "biological":
      return "Biológico";

    case "micro":
      return "Micro";

    case "others":
      return "Outros";

    default:
      return "";
  }
}

const dataSchema = yup.object().shape({
  name: yup.string().label("Nome").min(3).required(),
  code3c_id: yup.string().min(1).required(),
  typeOf: yup
    .string()
    .oneOf([
      "herbicide",
      "insecticide",
      "fungicide",
      "biological",
      "micro",
      "others",
    ])
    .required(),
});

export { dataSchema, parsePesticideType };
