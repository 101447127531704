import React, { useState } from "react";

import {
  Card,
  CardDeck,
  Container,
  Spinner,
  Button,
  Row,
} from "react-bootstrap";

import { getVisits } from "../../../controllers/ControllerVisit";
import { get3cRecommendations } from "../../../controllers/ControllerRecommendation";

import moment from "moment";
import "moment/locale/pt-br";

function PeriodResumeReport(props) {
  const { code3cData } = props;

  const [toggleSpinner, setToggleSpinner] = useState(false);
  const [toggleExport, setToggleExport] = useState(false);

  const [visitList, setVisitList] = useState([]);
  const [visitedList, setVisitedList] = useState([]);
  const [recommendationList, setRecommendationList] = useState([]);
  const [propertiesVisitedList, setPropertiesVisitedList] = useState([]);
  const [fieldsVisitedList, setFieldsVisitedList] = useState([]);

  const [dateNow, setDateNow] = useState("");

  async function getData() {
    const visits = await getVisits(code3cData.id);
    setVisitList(visits.filter((visit) => visit.visitDate && !visit.endVisit));
    setVisitedList(visits.filter((visit) => visit.endVisit));
    setRecommendationList(await get3cRecommendations(code3cData.id));
    setPropertiesVisitedList(visits.filter((visit) => visit.endVisit)); //Criar reduce
    setFieldsVisitedList(visits.filter((visit) => visit.endVisit)); //Criar reduce
    setDateNow("Atualizado em: " + moment().format("LLL"));
    if (visits) {
      setToggleSpinner(false);
      setToggleExport(true);
    }
  }

  return (
    <Container>
      <Row>
        <Button
          size="sm"
          className="bg-system mt-2 mr-2 ml-auto"
          onClick={() => {
            getData();
          }}
        >
          OBTER RESUMO
        </Button>
        <Button
          size="sm"
          disabled={!toggleExport}
          variant="outline-dark"
          className="mt-2 mr-2 mr-auto"
          onClick={() => {
            getData();
          }}
        >
          EXPORTAR RESUMO
        </Button>
      </Row>
      <CardDeck className="mt-3 text-center">
        <Card className="text-center text-system-alt">
          <Card.Body className="px-0">
            <h2>VISITAS ABERTAS</h2>
            <hr />
            {toggleSpinner ? (
              <Spinner className="mx-auto text-system" animation="border" />
            ) : (
              <>
                <h2>{visitList.length}</h2>
                <small>
                  <small>EM ABERTO</small>
                </small>
              </>
            )}
          </Card.Body>
          <Card.Footer>
            <small className="text-muted">{dateNow}</small>
          </Card.Footer>
        </Card>
        <Card className="text-center text-system-alt">
          <Card.Body className="px-0">
            <h2>VISITAS FINALIZADAS</h2>
            <hr />
            {toggleSpinner ? (
              <Spinner className="mx-auto text-system" animation="border" />
            ) : (
              <>
                <h2>{visitedList.length}</h2>
                <small>
                  <small>FINALIZADAS</small>
                </small>
              </>
            )}
          </Card.Body>
          <Card.Footer>
            <small className="text-muted">{dateNow}</small>
          </Card.Footer>
        </Card>
      </CardDeck>
      <CardDeck className="mt-3 text-center">
        <Card className="text-center text-system-alt">
          <Card.Body className="px-0">
            <h2>
              PROPRIEDADES
              <br /> VISITADAS
            </h2>
            <hr />
            {toggleSpinner ? (
              <Spinner className="mx-auto text-system" animation="border" />
            ) : (
              <>
                <h2>{propertiesVisitedList.length}</h2>
                <small>
                  <small>VISITADAS E FINALIZADAS</small>
                </small>
              </>
            )}
          </Card.Body>
          <Card.Footer>
            <small className="text-muted">{dateNow}</small>
          </Card.Footer>
        </Card>
        <Card className="text-center text-system-alt">
          <Card.Body className="px-0">
            <h2>
              TALHÕES
              <br /> VISITADOS
            </h2>
            <hr />
            {toggleSpinner ? (
              <Spinner className="mx-auto text-system" animation="border" />
            ) : (
              <>
                <h2>{fieldsVisitedList.length}</h2>
                <small>
                  <small>VISITADOS E FINALIZADOS</small>
                </small>
              </>
            )}
          </Card.Body>
          <Card.Footer>
            <small className="text-muted">{dateNow}</small>
          </Card.Footer>
        </Card>
        <Card className="text-center text-system-alt">
          <Card.Body className="px-0">
            <h2>
              RECOMENDAÇÕES
              <br /> REALIZADAS
            </h2>
            <hr />
            {toggleSpinner ? (
              <Spinner className="mx-auto text-system" animation="border" />
            ) : (
              <>
                <h2>{recommendationList.length}</h2>
                <small>
                  <small>GERADAS</small>
                </small>
              </>
            )}
          </Card.Body>
          <Card.Footer>
            <small className="text-muted">{dateNow}</small>
          </Card.Footer>
        </Card>
      </CardDeck>
    </Container>
  );
}

export default PeriodResumeReport;
