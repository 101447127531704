import React from "react";

import { Form, Button, Table, Col, InputGroup } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";

function FormAddHarvest(props) {
  const {
    clearCultivationVarieties,
    clearAll,
    client_name,
    property_name,
    cultivation_name,
    cultivationVarieties_name,
    harvestYearStart,
    setHarvestYearStart,
    note,
    setNote,
    fields,
    setShowFields,
    catchErrors,
    catchInvalid,
    validated,
  } = props;

  return (
    <Form noValidate validated={validated}>
      <Table striped bordered hover size="sm" className="rounded border-0">
        <thead>
          <tr>
            <th
              width="100%"
              colSpan="3"
              className="align-middle text-center border-0"
            >
              <Button
                variant="outline-danger"
                size="sm"
                className="mr-2"
                onClick={() => clearAll()}
              >
                Início
              </Button>
              <Button
                variant="outline-danger"
                size="sm"
                className="ml-2"
                onClick={() => clearCultivationVarieties()}
              >
                Voltar
              </Button>
            </th>
          </tr>
        </thead>
      </Table>
      <Form.Row>
        <Form.Group as={Col} md={6}>
          <Form.Label>Produtor</Form.Label>
          <Form.Control disabled type="text" value={client_name} />
        </Form.Group>
        <Form.Group as={Col} md={6}>
          <Form.Label>Propriedade</Form.Label>
          <Form.Control disabled type="text" value={property_name} />
        </Form.Group>
      </Form.Row>
      <Form.Group>
        <InputGroup>
          <Form.Control
            type="text"
            disabled
            aria-describedby="inputGroupPrepend"
            manufactory_name
            value={
              fields.length === 0
                ? "Selecionar Talhões"
                : fields.length + " Talhões Selecionados"
            }
            required
          />
          <InputGroup.Prepend>
            <InputGroup.Text
              className="rounded-right bg-system link"
              onClick={() => setShowFields(true)}
            >
              <FaPlus className="text-white" />
            </InputGroup.Text>
          </InputGroup.Prepend>
        </InputGroup>
      </Form.Group>
      <Form.Row>
        <Form.Group as={Col} md={6}>
          <Form.Label>Cultura</Form.Label>
          <Form.Control disabled type="text" value={cultivation_name} />
        </Form.Group>
        <Form.Group as={Col} md={6}>
          <Form.Label>Variação/Hibrido</Form.Label>
          <Form.Control
            disabled
            type="text"
            value={cultivationVarieties_name}
          />
        </Form.Group>
      </Form.Row>
      <Form.Group>
        <Form.Label>Data de Início da Safra</Form.Label>
        <Form.Control
          required
          isInvalid={catchInvalid("harvestYearStart")}
          type="date"
          value={harvestYearStart}
          onChange={(e) => setHarvestYearStart(e.target.value)}
        />
        {catchErrors("harvestYearStart")}
      </Form.Group>
      <Form.Group>
        <Form.Label>Anotações</Form.Label>
        <Form.Control
          placeholder="Anotações"
          as="textarea"
          rows="3"
          value={note}
          isInvalid={catchInvalid("note")}
          onChange={(e) => setNote(e.target.value)}
        />
        {catchErrors("note")}
      </Form.Group>
    </Form>
  );
}

export default FormAddHarvest;
