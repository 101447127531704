import * as yup from "yup";
import { pt } from "yup-locale-pt";

yup.setLocale(pt);

// yup.addMethod(yup.number, "getfieldNumberValidation", function (errorMessage) {
//   return this.test(
//     `fieldNumber`,
//     errorMessage,
//     function (value, fieldNumberList) {
//       const { path, createError } = this;
//       const validField = fieldNumberList.options.context.staticData;
//       const filterNumberFields = fieldNumberList.options.context.fieldNumberList.indexOf(
//         value,
//       );
//       console.log(fieldNumberList.options.context.staticData.fieldNumber);
//       if (validField) {
//         return value;
//       } else {
//         if (filterNumberFields === -1) {
//           return value;
//         }
//       }

//       return createError({ path, message: errorMessage });
//     },
//   );
// });

const dataSchema = yup.object().shape({
  code3c_id: yup.string().min(1).required(),
  client_id: yup.string().min(1).required(),
  client_name: yup.string().min(1).required(),
  property_id: yup.string().min(1).required(),
  property_name: yup.string().min(1).required(),
  name: yup.string().label("Nome do Talhão").required(),
  fieldNumber: yup.number().required(),
  // .getfieldNumberValidation("Existe outro Talhão com esse Número"),
  fieldArea: yup.number().min(0.1).label("Área do Talhão").required(),
});

export { dataSchema };
