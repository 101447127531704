import axios from "axios";

const api = axios.create({
  baseURL: `https://onesignal.com/api/v1/notifications`,

  headers: {
    Authorization: "Basic YzVkMmQxZWItZjAwYS00ZWIxLThiY2YtOTA4ZWNhYWIyNWY2",
    "Content-Type": "application/json",
  },

  timeout: 5 * 1000,
});

export async function agroVisitsPush(visit: IVisit, users: Array<string>) {
  //OBS: A DATA PRECISA TA FORMATADA "dd/MM/yyyy"
  try {
    const data = {
      app_id: "50c24b3c-b269-488f-b028-7006a99d9547",

      include_player_ids: users,
      data: visit,

      headings: { en: `Nova Visita em ${visit.property_name}` },

      contents: {
        en: `Data de agendamento ${visit.visitDate}`,
      },
    };
    await api.post("/", data);
    return {
      success: true,
    };
  } catch (error) {
    return {
      success: false,
    };
  }
}
export default api;
interface IVisit {
  id: string;

  code3c_id: string;

  client_id: string;
  client_name: string;

  property_id: string;
  property_name: string;

  user_id: string;
  user_name: string;

  property_imageLight: string;
  property_imageHeavy: string;

  visitDate: string;
}
