import React, { useEffect, useState, useContext } from "react";

import {
  Container,
  Row,
  Spinner,
  Navbar,
  Button,
  Form,
  FormControl,
} from "react-bootstrap";

import { FaPlusCircle } from "react-icons/fa";

import TableProperty from "../../components/property/pageComponents/tableProperty";

import AddProperty from "../../components/property/pageModals/addProperty";
import EditProperty from "../../components/property/pageModals/editProperty";
import DeleteProperty from "../../components/property/pageModals/deleteProperty";

import SearchClient from "../../components/property/pageModals/searchClient";

import { getProperties } from "../../controllers/ControllerProperty";

import { AuthContext } from "../../configurations/authorization";

function Property() {
  const { code3cData } = useContext(AuthContext);

  const [propertyList, setPropertyList] = useState([]);

  const [inputSearch, setInputSearch] = useState("");

  const [toggleSpinner, setToggleSpinner] = useState(false);

  const [client_id, setClient_id] = useState("");
  const [client_name, setClient_name] = useState("");

  const [showSearchClientModal, setShowSearchClientModal] = useState(false);

  const [showAddPropertyModal, setShowAddPropertyModal] = useState(false);
  const [showEditPropertyModal, setShowEditPropertyModal] = useState({
    show: false,
  });
  const [showDeletePropertyModal, setShowDeletePropertyModal] = useState({
    show: false,
  });

  async function getData() {
    if (client_id !== "") {
      const dataResponse = await getProperties(client_id);
      if (dataResponse) {
        setPropertyList(dataResponse);
        setToggleSpinner(true);
      }
    } else {
      setPropertyList([]);
      setToggleSpinner(true);
    }
  }

  async function handleClient(client_id, client_name) {
    setClient_id(client_id);
    setClient_name(client_name);
    setShowSearchClientModal(false);
  }

  function clearClient() {
    setClient_id("");
    setClient_name("");
    setShowSearchClientModal(false);
  }

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [client_id]);

  return (
    <Container
      className="rounded bg-white my-3 pb-2 px-0 ubuntuFont"
      style={{ height: " 475.4px" }}
    >
      <AddProperty
        showAddPropertyModal={showAddPropertyModal}
        setShowAddPropertyModal={setShowAddPropertyModal}
        propertyList={propertyList}
        setPropertyList={setPropertyList}
        client_id={client_id}
        client_name={client_name}
        code3c_id={code3cData.id}
      />
      <EditProperty
        showEditPropertyModal={showEditPropertyModal}
        setShowEditPropertyModal={setShowEditPropertyModal}
        client_id={client_id}
        client_name={client_name}
        getData={getData}
        code3c_id={code3cData.id}
      />
      <DeleteProperty
        showDeletePropertyModal={showDeletePropertyModal}
        setShowDeletePropertyModal={setShowDeletePropertyModal}
        getData={getData}
        code3c_id={code3cData.id}
      />
      <SearchClient
        showSearchClientModal={showSearchClientModal}
        setShowSearchClientModal={setShowSearchClientModal}
        handleClient={handleClient}
        clearClient={clearClient}
        client_id={client_id}
        code3c_id={code3cData.id}
      />

      <h2 className="text-center py-3">PROPRIEDADES</h2>
      <hr className="mt-0" />
      <Navbar
        bg="white"
        variant="white"
        className="mb-3 justify-content-between"
      >
        <Navbar.Brand className="link">
          <Button
            variant="outline-success"
            disabled={client_id === "" ? true : false}
            onClick={() => {
              setShowAddPropertyModal(!showAddPropertyModal);
            }}
          >
            <FaPlusCircle className="text-outline-success" />
            &nbsp; Nova Propriedade
          </Button>
        </Navbar.Brand>
        <Form inline className="justify-content-end">
          <FormControl
            type="text"
            placeholder="Pesquisar propriedade"
            className="mr-sm-2 justify-content-end"
            value={inputSearch}
            onChange={(e) => setInputSearch(e.target.value)}
          />
        </Form>
      </Navbar>
      <Container className="table-max-height">
        {propertyList === 0 && !toggleSpinner ? (
          <Row>
            <Spinner className="mx-auto my-5 text-system" animation="border" />
          </Row>
        ) : (
          <TableProperty
            propertyList={propertyList}
            inputSearch={inputSearch}
            client_name={client_name}
            setShowSearchClientModal={setShowSearchClientModal}
            setShowEditPropertyModal={setShowEditPropertyModal}
            setShowDeletePropertyModal={setShowDeletePropertyModal}
            toggleSpinner={toggleSpinner}
          />
        )}
      </Container>
    </Container>
  );
}

export default Property;
