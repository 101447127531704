import React, { useEffect, useState, useContext } from "react";

import {
  Container,
  Navbar,
  Form,
  FormControl,
  Button,
  Table,
  Spinner,
} from "react-bootstrap";

import {
  FaRegCircle,
  FaRegCheckCircle,
  FaSeedling,
  FaSearch,
} from "react-icons/fa";

import SearchCultivation from "../../components/cultivationVarieties/pageModals/searchCultivation";

import {
  addCultivation,
  getCultivationVarieties,
  search3cCultivationVarieties,
  removeCultivation,
} from "../../controllers/ControllerCultivationVarieties";

import { AuthContext } from "../../configurations/authorization";

function CultivationVarieties() {
  const { code3cData } = useContext(AuthContext);

  const [inputSearch, setInputSearch] = useState("");
  const [cultivationsStaticList, setCultivationsStaticList] = useState([]);

  const [cultivation_id, setCultivation_id] = useState("");
  const [cultivation_name, setCultivation_name] = useState("");

  const [showSearchCultivationModal, setShowSearchCultivationModal] = useState(
    false,
  );

  const [pending, setPending] = useState(false);

  async function handleAddCultivation(id, name) {
    await addCultivation(id, name, code3cData.id);
    getData();
  }

  async function handleRemoveCultivation(id, name) {
    await removeCultivation(id, name, code3cData.id);
    getData();
  }

  async function handleCultivation(cultivation_id, cultivation_name) {
    setCultivation_id(cultivation_id);
    setCultivation_name(cultivation_name);
    setShowSearchCultivationModal(false);
  }

  function clearCultivation() {
    setCultivation_id("");
    setCultivation_name("");
    setShowSearchCultivationModal(false);
  }

  async function getData() {
    if (cultivation_id !== "") {
      const allCultivationsFromDB = await getCultivationVarieties(
        cultivation_id,
      );
      const cultivationsFromDB = await search3cCultivationVarieties(
        code3cData.id,
      );
      if (allCultivationsFromDB) {
        setPending(true);
      }
      setCultivationsStaticList(
        allCultivationsFromDB.map((cultivations) =>
          findCultivation(cultivations, cultivationsFromDB),
        ),
      );
    } else {
      setCultivationsStaticList([]);
      setPending(true);
    }
  }

  function findCultivation(cultivations, cultivationsListFromDB) {
    const cultivationCheck = cultivationsListFromDB.find(
      (item) => item.name === cultivations.name && item.code3cEnabled === true,
    );
    if (cultivationCheck) {
      return {
        id: cultivationCheck.id,
        name: cultivationCheck.name,
        cultivation_id: cultivationCheck.cultivation_id,
        cultivation_name: cultivationCheck.cultivation_name,
        active: true,
      };
    } else {
      return {
        id: cultivations.id,
        name: cultivations.name,
        cultivation_id: cultivations.cultivation_id,
        cultivation_name: cultivations.cultivation_name,
        active: false,
      };
    }
  }

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [cultivation_id]);

  return (
    <Container className="rounded bg-white my-3 pb-2 px-0 ubuntuFont">
      <SearchCultivation
        showSearchCultivationModal={showSearchCultivationModal}
        setShowSearchCultivationModal={setShowSearchCultivationModal}
        handleCultivation={handleCultivation}
        clearCultivation={clearCultivation}
        cultivation_id={cultivation_id}
        code3c_id={code3cData.id}
      />

      <h2 className="text-center py-3">VARIAÇÕES E HÍBRIDOS</h2>
      <hr className="mt-0" />
      <Navbar
        bg="white"
        variant="white"
        className="mb-3 justify-content-between"
      >
        <Navbar.Brand className="link">
          <Button
            variant="outline-success"
            disabled={pending ? false : true}
            onClick={() => getData()}
          >
            <FaSeedling className="text-success" />
            &nbsp; Variações e Híbridos
          </Button>
        </Navbar.Brand>
        <Form inline className="justify-content-end">
          <FormControl
            type="text"
            placeholder="Pesquisar variação/híbrido"
            className="mr-sm-2 justify-content-end"
            value={inputSearch}
            onChange={(e) => setInputSearch(e.target.value)}
          />
        </Form>
      </Navbar>
      <Container className="table-max-height">
        <Table striped bordered hover size="sm" className="rounded">
          <thead>
            <tr>
              <th width="100%" colSpan="5" className="align-middle text-center">
                <Button
                  variant="outline-dark"
                  size="sm"
                  onClick={() => setShowSearchCultivationModal(true)}
                >
                  <FaSearch />
                  &nbsp;
                  {cultivation_name === ""
                    ? "Selecione uma Cultura"
                    : cultivation_name}
                </Button>
              </th>
            </tr>
            <tr>
              <th width="2%">#</th>
              <th width="88%">Cultura</th>
              <th width="10%" className="text-center">
                Habilitado
              </th>
            </tr>
          </thead>
          <tbody>
            {cultivationsStaticList.length === 0 &&
              cultivation_name &&
              !pending && (
                <tr>
                  <td
                    width="100%"
                    colSpan="6"
                    className="align-middle text-center"
                  >
                    Nenhuma Variação/Híbrido foi Encontrado
                  </td>
                </tr>
              )}
            {cultivationsStaticList
              .sort(function (a, b) {
                var nameA = a.name.toLowerCase(),
                  nameB = b.name.toLowerCase();
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return 0;
              })
              .filter(
                (item) =>
                  item.name.toUpperCase().indexOf(inputSearch.toUpperCase()) !==
                  -1,
              )
              .map((cultivations, index) => (
                <tr key={index}>
                  <td width="2%" className="align-middle">
                    <b>{index + 1}</b>
                  </td>
                  <td width="88%" className="align-middle">
                    {cultivations.name}
                  </td>
                  <td width="10%" className="text-center align-middle">
                    {!pending ? (
                      <Spinner
                        animation="border"
                        variant="secondary"
                        style={{ "max-width": "10px", "max-height": "10px" }}
                      />
                    ) : cultivations.active ? (
                      <FaRegCheckCircle
                        className="text-system link"
                        onClick={() =>
                          handleRemoveCultivation(
                            cultivations.id,
                            cultivations.name,
                          )
                        }
                      />
                    ) : (
                      <FaRegCircle
                        className="link"
                        onClick={() =>
                          handleAddCultivation(
                            cultivations.id,
                            cultivations.name,
                          )
                        }
                      />
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Container>
    </Container>
  );
}

export default CultivationVarieties;
