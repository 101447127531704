import { firebaseDB } from "../configurations/firebaseConfig";
import { toast } from "react-toastify";

import { getPropertyHarvests } from "./ControllerHarvest";

const response = firebaseDB.collection("field");

async function searchEditFieldNumber(code3c_id, fieldNumber, id) {
  try {
    const data = await response
      .where("code3c_id", "==", code3c_id)
      .where("fieldNumber", "==", fieldNumber)
      .get();
    const allData = [];
    data.docs.forEach((item) => {
      allData.push({ ...item.data(), id: item.id });
    });
    const [dataExists] = allData;
    if (allData.length > 0 && id !== dataExists.id) {
      return true;
    }
    return false;
  } catch (err) {
    console.log(err);
    toast.error("Não foi possível ler os dados.");
  }
}

async function addField(data) {
  try {
    const dataDB = await response.add(data);
    toast.success("Talhão " + data.name + " foi adicionado!", 3000);
    return dataDB.id;
  } catch (err) {
    console.log(err);
    toast.error("Parece que tem algo errado com seu cadastro!", 3000);
  }
}

async function getFields(property_id) {
  try {
    const data = await response.where("property_id", "==", property_id).get();
    const allData = [];
    data.docs.forEach((item) => {
      allData.push({ ...item.data(), id: item.id });
    });
    return allData;
  } catch (err) {
    console.log(err);
    toast.error("Não foi possível ler os dados.");
  }
}

async function getAllFields() {
  try {
    const data = await response.get();
    const allData = [];
    data.docs.forEach((item) => {
      allData.push({ ...item.data(), id: item.id });
    });
    return allData;
  } catch (err) {
    console.log(err);
    toast.error("Não foi possível ler os dados.");
  }
}

async function getHarvestFields(property_id) {
  try {
    const harvests = await getPropertyHarvests(property_id);
    const fields = harvests
      .map((harvest) => harvest.fields)
      .reduce((fieldData, previous) => {
        return previous.concat(fieldData);
      }, [])
      .map((field) => field.field_id);

    const data = await response.where("property_id", "==", property_id).get();
    const allData = [];
    data.docs.forEach((item) => {
      allData.push({ ...item.data(), id: item.id });
    });
    return allData.filter((field) => fields.indexOf(field.id) === -1);
  } catch (err) {
    console.log(err);
    toast.error("Não foi possível ler os dados.");
  }
}

async function searchField(id) {
  try {
    const data = await response.doc(id).get();
    return { ...data.data(), id: id };
  } catch (err) {
    console.log(err);
    toast.error("Não foi possível ler os dados.");
  }
}

async function editField(data, id) {
  try {
    const dataDBExists = await response.doc(id).get();
    if (!dataDBExists) {
      return [];
    } else {
      await response.doc(id).set(data);
      toast.success("Produtor " + data.name + " foi editado!", 3000);
      return true;
    }
  } catch (err) {
    console.log(err);
    toast.error("Parece que tem algo errado com sua edição!", 3000);
  }
}

async function deleteField(name, id) {
  try {
    await response.doc(id).delete();
    toast.success("Produtor " + name + " foi apagado!", 3000);
    return true;
  } catch (err) {
    console.log(err);
    toast.error("Parece que tem algo errado com sua edição!", 3000);
  }
}

export {
  addField,
  getFields,
  getAllFields,
  getHarvestFields,
  searchField,
  editField,
  deleteField,
  searchEditFieldNumber,
};
