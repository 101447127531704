import { firebaseDB } from "../configurations/firebaseConfig";
import { toast } from "react-toastify";

const response = firebaseDB.collection("client");

async function addClient(data) {
  try {
    const dataDB = await response.add(data);
    toast.success("Produtor " + data.name + " foi adicionado!", 3000);
    return dataDB.id;
  } catch (err) {
    console.log(err);
    toast.error("Parece que tem algo errado com seu cadastro!", 3000);
  }
}

async function getClients(id) {
  try {
    const data = await response.where("code3c_id", "==", id).get();
    const allData = [];
    data.docs.forEach((item) => {
      allData.push({ ...item.data(), id: item.id });
    });
    return allData;
  } catch (err) {
    console.log(err);
    toast.error("Não foi possível ler os dados.");
  }
}

async function getAllClients() {
  try {
    const data = await response.get();
    const allData = [];
    data.docs.forEach((item) => {
      allData.push({ ...item.data(), id: item.id });
    });
    return allData;
  } catch (err) {
    console.log(err);
    toast.error("Não foi possível ler os dados.");
  }
}

async function searchClient(id) {
  try {
    const data = await response.doc(id).get();
    return { ...data.data(), id: id };
  } catch (err) {
    console.log(err);
    toast.error("Não foi possível ler os dados.");
  }
}

async function searchSocialIDClient(code3c_id, socialID) {
  try {
    const data = await response
      .where("code3c_id", "==", code3c_id)
      .where("socialID", "==", socialID)
      .get();
    const allData = [];
    data.docs.forEach((item) => {
      allData.push({ ...item.data(), id: item.id });
    });
    console.log(JSON.stringify(allData));
    if (allData.length > 0) {
      return true;
    }
    return false;
  } catch (err) {
    console.log(err);
    toast.error("Não foi possível ler os dados.");
  }
}

async function searchEditSocialIDClient(code3c_id, socialID, id) {
  try {
    const data = await response
      .where("code3c_id", "==", code3c_id)
      .where("socialID", "==", socialID)
      .get();
    const allData = [];
    data.docs.forEach((item) => {
      allData.push({ ...item.data(), id: item.id });
    });
    const [dataExists] = allData;
    if (allData.length > 0 && id !== dataExists.id) {
      return true;
    }
    return false;
  } catch (err) {
    console.log(err);
    toast.error("Não foi possível ler os dados.");
  }
}

async function editClient(data, id) {
  try {
    const dataDBExists = await response.doc(id).get();
    if (!dataDBExists) {
      return [];
    } else {
      await response.doc(id).set(data);
      toast.success("Produtor " + data.name + " foi editado!", 3000);
      return true;
    }
  } catch (err) {
    console.log(err);
    toast.error("Parece que tem algo errado com sua edição!", 3000);
  }
}

async function deleteClient(name, id) {
  try {
    await response.doc(id).delete();
    toast.success("Produtor " + name + " foi apagado!", 3000);
    return true;
  } catch (err) {
    console.log(err);
    toast.error("Parece que tem algo errado com sua edição!", 3000);
  }
}

export {
  addClient,
  getClients,
  getAllClients,
  searchClient,
  searchSocialIDClient,
  searchEditSocialIDClient,
  editClient,
  deleteClient,
};
