import React, { useState } from "react";

import { Container, Modal, Button, Form } from "react-bootstrap";

import { toast } from "react-toastify";
import { FaPlusCircle } from "react-icons/fa";

import { dataSchema } from "../../../functions/pesticide";

import { addPesticide } from "../../../controllers/ControllerPesticide";

function AddPesticide(props) {
  const {
    showAddPesticideModal,
    setShowAddPesticideModal,
    pesticideList,
    setPesticideList,
    code3c_id,
  } = props;

  const [name, setName] = useState("");
  const [typeOf, setTypeOf] = useState("herbicide");

  const [validated, setValidated] = useState(false);
  const [formErr, setFormErr] = useState([]);

  const [pendingRequest, setPendingRequest] = useState(false);

  function clearFields() {
    setFormErr([]);
    setName("");
    setTypeOf("herbicide");
    setPendingRequest(false);
    setValidated(false);
    setShowAddPesticideModal(false);
  }

  function handleClose() {
    clearFields();
  }

  function handlePesticideTypes(types) {
    setTypeOf(types.target.value);
  }

  async function handleAddPesticide() {
    const data = {
      code3c_id,
      name,
      typeOf,
    };

    dataSchema
      .validate(data, { abortEarly: false })
      .then(async function () {
        setFormErr([]);
        setValidated(true);
        const response = await addPesticide(data);
        setPesticideList([...pesticideList, { id: response, ...data }]);
        clearFields();
      })
      .catch(function (err) {
        setPendingRequest(false);
        setFormErr(err.inner);
        toast.error("Existem campos com erros.");
      });
  }

  function catchErrors(errorField) {
    return (
      <Form.Control.Feedback type="invalid">
        {formErr.map((err) => {
          if (err.path === errorField) {
            return <p>{err.errors}</p>;
          }
          return null;
        })}
      </Form.Control.Feedback>
    );
  }

  function catchInvalid(errorField) {
    if (formErr.filter((err) => err.path === errorField).length >= 1) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <Container className="ubuntuFont">
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        className="ubuntuFont"
        show={showAddPesticideModal}
        onHide={handleClose}
        centered
      >
        <Modal.Header className="text-center" closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-center"
          >
            <FaPlusCircle className="text-success" />
            &emsp;Novo Defensivo
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated}>
            <Form.Group>
              <Form.Label>Nome do Defensivo</Form.Label>
              <Form.Control
                type="text"
                isInvalid={catchInvalid("name")}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {catchErrors("name")}
            </Form.Group>
            <Form.Group>
              <Form.Label>Tipo de Defensivo</Form.Label>
              <Form.Control
                isInvalid={catchInvalid("typeOf")}
                as="select"
                onClick={handlePesticideTypes.bind(this)}
              >
                <option value={"herbicide"}>Herbicida</option>
                <option value={"insecticide"}>Inseticida</option>
                <option value={"fungicide"}>Fungicida</option>
                <option value={"biological"}>Biológico</option>
                <option value={"micro"}>Micro</option>
                <option value={"others"}>Outros</option>
              </Form.Control>
              {catchErrors("typeOf")}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={pendingRequest ? true : false}
            variant="success"
            className="mr-2"
            onClick={() => handleAddPesticide() + setPendingRequest(true)}
          >
            Salvar
          </Button>
          <Button variant="danger" onClick={() => handleClose()}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default AddPesticide;
