import React, { useState, useEffect } from "react";

import {
  Table,
  Button,
  Spinner,
  Row,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import { getClients } from "../../../controllers/ControllerClient";

function TableSearchClient(props) {
  const {
    code3c_id,
    client_id,
    inputSearch,
    handleClient,
    showSearchPropertyModal,
    harvestList,
  } = props;

  function TooltipRecommendation(props) {
    return (
      <Tooltip id="button-tooltip" {...props}>
        Esse Usuario Nao Possui Produtor / Safra
      </Tooltip>
    );
  }

  const [clientList, setClientList] = useState([]);

  useEffect(() => {
    async function get3cClients() {
      const response = await getClients(code3c_id);
      if (response) {
        setClientList(response);
      }
    }
    get3cClients();
  }, [showSearchPropertyModal, code3c_id]);

  return clientList.length === 0 ? (
    <Row>
      <Spinner className="mx-auto my-5 text-system" animation="border" />
    </Row>
  ) : (
    <Table striped bordered hover size="sm" className="rounded">
      <thead>
        <tr>
          <th width="2%">#</th>
          <th width="88%">Produtor</th>
          <th width="10%" className="text-center">
            Selecionar
          </th>
        </tr>
      </thead>
      <tbody>
        {clientList
          .sort(function (a, b) {
            var nameA = a.name.toLowerCase(),
              nameB = b.name.toLowerCase();
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
          })
          .filter(
            (item) =>
              item.id.indexOf(inputSearch) !== -1 ||
              item.name.toUpperCase().indexOf(inputSearch.toUpperCase()) !== -1,
          )
          .map((client, index) => (
            <tr key={index}>
              <td width="2%" className="align-middle">
                <b>{index + 1}</b>
              </td>
              <td width="63%" className="align-middle">
                {client.name}
              </td>
              <td width="10%" className="text-center align-middle">
                {client.name && harvestList ? (
                  <>
                    {harvestList.filter((item) => {
                      return client.name === item.client_name;
                    }).length === 0 ? (
                      <>
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={TooltipRecommendation}
                        >
                          <Button variant="secondary" size="sm">
                            Selecionar
                          </Button>
                        </OverlayTrigger>
                      </>
                    ) : (
                      <Button
                        disabled={client_id === client.id ? true : false}
                        className="bg-system"
                        size="sm"
                        onClick={() => handleClient(client.id, client.name)}
                      >
                        Selecionar
                      </Button>
                    )}
                  </>
                ) : (
                  <Button
                    disabled={client_id === client.id ? true : false}
                    className="bg-system"
                    size="sm"
                    onClick={() => handleClient(client.id, client.name)}
                  >
                    Selecionar
                  </Button>
                )}
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
}

export default TableSearchClient;
