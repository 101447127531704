import * as yup from "yup";
import { pt } from "yup-locale-pt";

import { validateBr } from "js-brasil";

yup.setLocale(pt);

yup.addMethod(yup.string, "getSocialIDValidation", function (errorMessage) {
  return this.test(`socialID`, errorMessage, function (value) {
    const { path, createError } = this;
    if (value.length === 11) {
      return (
        validateBr.cpf(value) || createError({ path, message: errorMessage })
      );
    } else if (value.length === 14) {
      return (
        validateBr.cnpj(value) || createError({ path, message: errorMessage })
      );
    }
    return createError({ path, message: errorMessage });
  });
});

yup.addMethod(yup.string, "getPhoneValidation", function (errorMessage) {
  return this.test(`socialID`, errorMessage, function (value) {
    const { path, createError } = this;
    if (value.length === 10 || value.length === 11) {
      return (
        validateBr.telefone(value) ||
        createError({ path, message: errorMessage })
      );
    }
    return createError({ path, message: errorMessage });
  });
});

const dataSchema = yup.object().shape({
  typeOf: yup.string().required(),
  name: yup.string().label("Nome").min(3).required(),
  code3c_id: yup.string().min(1).required(),
  socialID: yup
    .string()
    .required()
    .when("$docType", (docType, dataSchema) =>
      docType
        ? dataSchema
            .label("CNPJ")
            .min(14)
            .getSocialIDValidation("O CNPJ precisa ser válido")
        : dataSchema
            .label("CPF")
            .min(11)
            .getSocialIDValidation("O CPF precisa ser válido"),
    ),

  stateSubscription: yup.string().label("Inscrição Estadual").required(),
  phone: yup
    .string()
    .label("Telefone")
    .required()
    .min(10)
    .max(11)
    .getPhoneValidation("O Telefone precisa ser válido"),
  email: yup.string().label("Email").email().required(),
  note: yup.string(),
  address: yup.object({
    postalCode: yup.string().label("CEP").min(7).max(10).required(),
    state: yup.string().label("Estado").required(),
    city: yup.string().label("Cidade").required(),
    neighborhood: yup.string().label("Bairro").required(),
    street: yup.string().label("Rua").required(),
    houseNumber: yup.string().label("Número").required(),
  }),
});

export { dataSchema };
