import React, { useState, useEffect } from "react";

import { Container, Navbar } from "react-bootstrap";

import { FaPlusSquare, FaMinusSquare } from "react-icons/fa";

import JumboFields from "./jumboFields";
import NewHarvestDoc from "../pageModals/newHarvestDoc";
// import TableRecommendationType from "./tableRecommendationType";
import HarvestDocument from "../pageDocuments/harvestDocument";

import { getHarvestsRecommendations } from "../../../controllers/ControllerRecommendation";

import moment from "moment";
import "moment/locale/pt-br";

function JumboHarvests(props) {
  const { visit, visit_id, harvest, code3cData, code3c_id } = props;

  const [toggleJumbo, setToggleJumbo] = useState(false);
  const [recommendationList, setRecommendationList] = useState(false);
  const [showNewHarvestDocModal, setShowNewHarvestDocModal] = useState({
    show: false,
  });

  async function getRecommendations() {
    setRecommendationList(
      await getHarvestsRecommendations(visit_id, harvest.id)
    );
  }

  useEffect(() => {
    getRecommendations();
    // eslint-disable-next-line
  }, []);

  return (
    <Container fluid className="alert-system rounded border-system px-0 mb-3">
      <NewHarvestDoc
        showNewHarvestDocModal={showNewHarvestDocModal}
        setShowNewHarvestDocModal={setShowNewHarvestDocModal}
        Document={HarvestDocument}
        code3cData={code3cData}
      />
      <Navbar className="justify-content-between">
        <h4 className="my-0">
          <b>
            {harvest.cultivation_name +
              " " +
              moment(harvest.harvestYearStart.toDate()).format("YY") +
              "-" +
              (parseInt(
                moment(harvest.harvestYearStart.toDate()).format("YY")
              ) +
                1)}
          </b>
        </h4>
        <h2>
          {!toggleJumbo ? (
            <FaPlusSquare
              className="link"
              onClick={() => setToggleJumbo(!toggleJumbo)}
            />
          ) : (
            <FaMinusSquare
              className="link"
              onClick={() => setToggleJumbo(!toggleJumbo)}
            />
          )}
        </h2>
      </Navbar>
      <hr className="my-0" />
      {!toggleJumbo ? (
        <Navbar className="justify-content-center">
          <h6>
            <b>
              {"Início da Safra: " +
                moment(harvest.harvestYearStart.toDate()).format("LL")}
            </b>
          </h6>
          <h6>
            <b>
              {harvest.harvestYearEnd &&
                "Fim da Safra: " +
                  moment(harvest.harvestYearEnd.toDate()).format("LL")}
            </b>
          </h6>
        </Navbar>
      ) : (
        <Container>
          <Navbar className="justify-content-between">
            <h6>
              <b>{"Cultura: " + harvest.cultivation_name}</b>
            </h6>
            <h6>
              <b>{"Variação/Híbrido: " + harvest.cultivationVarieties_name}</b>
            </h6>
          </Navbar>
          <Navbar className="justify-content-between">
            <h6>
              <b>
                {"Início da Safra: " +
                  moment(harvest.harvestYearStart.toDate()).format("LL")}
              </b>
            </h6>
            <h6>
              <b>
                <b>
                  {harvest.harvestYearEnd
                    ? "Fim da Safra: " +
                      moment(harvest.harvestYearEnd.toDate()).format("LL")
                    : "Fim da Safra: Em andamento"}
                </b>
              </b>
            </h6>
          </Navbar>
          {harvest.fields.length > 0 && (
            <>
              <h4 className="text-center">TALHÕES</h4>
              {harvest.fields.map((field, index) => (
                <JumboFields
                  visit={visit}
                  visit_id={visit_id}
                  field={field}
                  harvest={harvest}
                  recommendationList={recommendationList.filter(
                    (rec) => rec.harvest_id === harvest.id
                  )}
                  code3cData={code3cData}
                  code3c_id={code3c_id}
                  key={index}
                />
              ))}
              {/* <Row className="my-3">
                <Button
                  size="sm"
                  variant="outline-dark"
                  className="mx-auto"
                  onClick={() => {
                    setShowNewHarvestDocModal({
                      visit,
                      visit_id: visit.id,
                      harvest,
                      recommendationList,
                      TableRecommendationType,
                      show: true,
                    });
                  }}
                >
                  Exportar Recomendações da Safra
                </Button>
              </Row> */}
            </>
          )}
        </Container>
      )}
    </Container>
  );
}

export default JumboHarvests;
