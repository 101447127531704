import { firebaseDB } from "../configurations/firebaseConfig";
import { toast } from "react-toastify";

const response = firebaseDB.collection("agroRecommendation");

async function addRecommendation(data) {
  try {
    const dataDB = await response.add(data);
    toast.success("Propriedade " + data.name + " foi adicionado!", 3000);
    return dataDB.id;
  } catch (err) {
    console.log(err);
    toast.error("Parece que tem algo errado com seu cadastro!", 3000);
  }
}

async function getRecommendations(client_id) {
  try {
    const data = await response.where("client_id", "==", client_id).get();
    const allData = [];
    data.docs.forEach((item) => {
      allData.push({ ...item.data(), id: item.id });
    });
    return allData;
  } catch (err) {
    console.log(err);
    toast.error("Não foi possível ler os dados.");
  }
}

async function get3cRecommendations(id) {
  try {
    const data = await response.where("code3c_id", "==", id).get();
    const allData = [];
    data.docs.forEach((item) => {
      allData.push({ ...item.data(), id: item.id });
    });
    return allData;
  } catch (err) {
    console.log(err);
    toast.error("Não foi possível ler os dados.");
  }
}

async function getFieldsRecommendations(visit_id, field_id) {
  try {
    const data = await response
      .where("agroVisits_id", "==", visit_id)
      .where("field_id", "==", field_id)
      .get();
    const allData = [];
    data.docs.forEach((item) => {
      allData.push({ ...item.data(), id: item.id });
    });
    return allData;
  } catch (err) {
    console.log(err);
    toast.error("Não foi possível ler os dados.");
  }
}

async function getHarvestsRecommendations(visit_id, harvest_id) {
  try {
    const data = await response
      .where("agroVisits_id", "==", visit_id)
      .where("harvest_id", "==", harvest_id)
      .get();
    const allData = [];
    data.docs.forEach((item) => {
      allData.push({ ...item.data(), id: item.id });
    });
    return allData;
  } catch (err) {
    console.log(err);
    toast.error("Não foi possível ler os dados.");
  }
}

async function getVisitsRecommendations(visit_id) {
  try {
    const data = await response.where("agroVisits_id", "==", visit_id).get();
    const allData = [];
    data.docs.forEach((item) => {
      allData.push({ ...item.data(), id: item.id });
    });
    return allData;
  } catch (err) {
    console.log(err);
    toast.error("Não foi possível ler os dados.");
  }
}

async function searchRecommendation(id) {
  try {
    const data = await response.doc(id).get();
    return { ...data.data(), id: id };
  } catch (err) {
    console.log(err);
    toast.error("Não foi possível ler os dados.");
  }
}

async function editRecommendation(data, id) {
  try {
    const dataDBExists = await response.doc(id).get();
    if (!dataDBExists) {
      return [];
    } else {
      await response.doc(id).update(data);
      toast.success("A recomendação foi editada!", 3000);
      return true;
    }
  } catch (err) {
    console.log(err);
    toast.error("Parece que tem algo errado com sua edição!", 3000);
  }
}

async function deleteRecommendation(name, id) {
  try {
    await response.doc(id).delete();
    toast.success("A recomendação foi apagada!", 3000);
    return true;
  } catch (err) {
    console.log(err);
    toast.error("Parece que tem algo errado com sua edição!", 3000);
  }
}

export {
  addRecommendation,
  getRecommendations,
  get3cRecommendations,
  getFieldsRecommendations,
  getHarvestsRecommendations,
  getVisitsRecommendations,
  searchRecommendation,
  editRecommendation,
  deleteRecommendation,
};
