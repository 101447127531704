import { firebaseDB } from "../configurations/firebaseConfig";
import { toast } from "react-toastify";

const response = firebaseDB.collection("cultivationVarieties");

async function addCultivation(id, name, code3c_id) {
  try {
    const dataDBExists = await response.doc(id).get();
    if (!dataDBExists) {
      return [];
    } else {
      const dataDB = dataDBExists.data();
      const data = {
        name: dataDB.name,
        cultivation_id: dataDB.cultivation_id,
        cultivation_name: dataDB.cultivation_name,
        code3cEnabled: [...dataDB.code3cEnabled, code3c_id],
      };
      await response.doc(id).set(data);
      toast.success("Cultura " + name + " foi habilitada!", 3000);
      return true;
    }
  } catch (err) {
    console.log(err);
    toast.error("Parece que tem algo errado com sua edição!", 3000);
  }
}

async function getCultivationVarieties(id) {
  try {
    const data = await response.where("cultivation_id", "==", id).get();
    const allData = [];
    data.docs.forEach((item) => {
      allData.push({ ...item.data(), id: item.id });
    });
    return allData;
  } catch (err) {
    console.log(err);
    toast.error("Não foi possível ler os dados.");
  }
}

async function search3cCultivationVarieties(id) {
  try {
    const data = await response
      .where("code3cEnabled", "array-contains", id)
      .get();
    const allData = [];
    data.docs.forEach((item) => {
      allData.push({ ...item.data(), id: item.id, code3cEnabled: true });
    });
    return allData;
  } catch (err) {
    console.log(err);
    toast.error("Não foi possível ler os dados.");
  }
}

async function searchCultivation_CultivationVarieties(id, cultivation_id) {
  try {
    const data = await response
      .where("code3cEnabled", "array-contains", id)
      .where("cultivation_id", "==", cultivation_id)
      .get();
    const allData = [];
    data.docs.forEach((item) => {
      allData.push({ ...item.data(), id: item.id, code3cEnabled: true });
    });
    return allData;
  } catch (err) {
    console.log(err);
    toast.error("Não foi possível ler os dados.");
  }
}

async function searchCultivation(id) {
  try {
    const data = await response.doc(id).get();
    return { ...data.data(), id: id };
  } catch (err) {
    console.log(err);
    toast.error("Não foi possível ler os dados.");
  }
}

async function removeCultivation(id, name, code3c_id) {
  try {
    const dataDBExists = await response.doc(id).get();
    if (!dataDBExists) {
      return [];
    } else {
      const dataDB = dataDBExists.data();
      const data = {
        name: dataDB.name,
        cultivation_id: dataDB.cultivation_id,
        cultivation_name: dataDB.cultivation_name,
        code3cEnabled: dataDB.code3cEnabled.filter(
          (enabled) => code3c_id !== enabled,
        ),
      };
      await response.doc(id).set(data);
      toast.warn("Cultura " + name + " foi desabilitada!", 3000);
      return true;
    }
  } catch (err) {
    console.log(err);
    toast.error("Parece que tem algo errado com sua edição!", 3000);
  }
}

export {
  addCultivation,
  getCultivationVarieties,
  search3cCultivationVarieties,
  searchCultivation_CultivationVarieties,
  searchCultivation,
  removeCultivation,
};
