import React, { useState, useEffect } from "react";

import { Container, Modal, Button, Form } from "react-bootstrap";

import { toast } from "react-toastify";
import { FaPlusCircle } from "react-icons/fa";

import { Gallery, getURLImage } from "../../images";
import { editRecommendation } from "../../../controllers/ControllerRecommendation";

export default function AddImagesModal(props) {
  const {
    showAddImagesModal,
    setShowAddImagesModal,
    code3c_id,
    recommendation,
    recommendationList,
    setRecommendationList,
    gallery,
  } = props;

  const [imageHeavy, setImageHeavy] = useState("");
  const [imageLight, setImageLight] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [note, setNote] = useState("");

  function handleClose() {
    setShowAddImagesModal(!showAddImagesModal);
    setNote("");
    setImageHeavy("");
    setImageLight("");
    setLatitude("");
    setLongitude("");
  }

  async function getLocation() {
    if (navigator.geolocation) {
      await navigator.geolocation.getCurrentPosition(await showPosition);
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }
  async function showPosition(position) {
    setLatitude(position.coords.latitude);
    setLongitude(position.coords.longitude);
  }

  async function handleAddImage() {
    if (imageHeavy !== "") {
      const urlImage = await getURLImage(
        imageHeavy,
        imageLight,
        code3c_id,
        "agroRecommendation",
        false,
      );
      const data = {
        imageHeavy: urlImage,
        imageLight: urlImage,
        latitude,
        longitude,
        note,
        synced: true,
        afterUp: true,
      };
      const newGallery = [...gallery, data];
      const newRecomendation = { ...recommendation, gallery: newGallery };
      const response = editRecommendation(newRecomendation, recommendation.id);
      if (response) {
        setRecommendationList(
          recommendationList.map((rec) => {
            if (rec.id === newRecomendation.id) {
              return {
                ...rec,
                gallery: newGallery,
              };
            }
            return rec;
          }),
        );
      }
      handleClose();
    } else {
      toast.error("Insira uma imagem!");
    }
  }

  useEffect(() => {
    if (latitude === "" && showAddImagesModal) getLocation();
  });

  return (
    <Container className="ubuntuFont">
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        className="ubuntuFont"
        show={showAddImagesModal}
        onHide={handleClose}
        centered
      >
        <Modal.Header className="text-center" closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-center"
          >
            <FaPlusCircle className="text-system" />
            &emsp;Adicionar Imagem
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Gallery
              imageHeavy={imageHeavy}
              setImageHeavy={setImageHeavy}
              setImageLight={setImageLight}
            />
            <Form.Group className="my-2">
              <Form.Control
                placeholder="Descrição"
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
            </Form.Group>
            {latitude === "" && longitude === "" && (
              <Form.Text className="text-danger text-center">
                Por favor habilite a localização!
              </Form.Text>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            disabled={latitude === "" && longitude === "" ? true : false}
            onClick={() => handleAddImage()}
          >
            Inserir
          </Button>
          <Button variant="danger" onClick={() => handleClose()}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}
