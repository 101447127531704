import React, { useContext } from "react";

import { Nav, Navbar, NavDropdown } from "react-bootstrap";

import firebaseConfig from "../../configurations/firebaseConfig";
import { AuthContext } from "../../configurations/authorization";

import logoCansho from "../../assets/logos/breadcrumb-cansho.png";

import { Link } from "react-router-dom";

import { BillingWarning } from "./popoversComponents/BillingWarning";

import moment from "moment";
import "moment/locale/pt-br";

function Header() {
  const { currentUser, clearProvider, system3cData } = useContext(AuthContext);

  async function handleSignOut() {
    clearProvider();
    firebaseConfig.auth().signOut();
  }

  return (
    <Navbar expand="lg" bg="light" variant="light" className="ubuntuFont">
      <Navbar.Brand>
        <img
          alt=""
          src={logoCansho}
          width="25"
          height="30"
          className="d-inline-block align-top"
        />{" "}
        Cansho
      </Navbar.Brand>
      {!!currentUser && (
        <>
          <Nav className="mr-auto">
            <Nav.Link href="/">Home</Nav.Link>
            <NavDropdown
              title="Safra"
              id="collasible-nav-dropdown"
              className="header-link"
            >
              <NavDropdown.Item className="header-link">
                <Link to={"/produtores"}>Produtores</Link>
              </NavDropdown.Item>
              <NavDropdown.Item className="header-link">
                <Link to={"/propriedades"}>Propriedades</Link>
              </NavDropdown.Item>
              <NavDropdown.Item className="header-link">
                <Link to={"/talhoes"}>Talhões</Link>
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item className="header-link">
                <Link to={"/safras"}>Safras</Link>
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown
              title="Cultura"
              id="collasible-nav-dropdown"
              className="header-link"
            >
              <NavDropdown.Item className="header-link">
                <Link to={"/culturas"}>Culturas</Link>
              </NavDropdown.Item>
              <NavDropdown.Item className="header-link">
                <Link to={"/variacoes-e-hibridos"}>Variações/Hibridos</Link>
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link className="header-link">
              <Link to="/defensivos">Defensivos</Link>
            </Nav.Link>
            <NavDropdown
              title="Visita"
              id="collasible-nav-dropdown"
              className="header-link"
            >
              <NavDropdown.Item className="header-link">
                <Link to={"/visitas"}>Visitas</Link>
              </NavDropdown.Item>
              <NavDropdown.Item className="header-link">
                <Link to="/recomendacoes">Recomendações</Link>
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item className="header-link">
                <Link to={"/relatorios"}>Relatórios</Link>
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link className="header-link">
              <Link to="/usuarios">Usuários</Link>
            </Nav.Link>
            <Nav.Link className="header-link">Suporte</Nav.Link>
            {system3cData.systemBilling.systemDateBill &&
              moment().isAfter(
                moment(system3cData.systemBilling.systemDateBill.toDate()),
              ) && (
                <Nav.Link className="header-link">
                  <BillingWarning
                    systemDateBill={moment(
                      system3cData.systemBilling.systemDateBill.toDate(),
                    )}
                  />
                </Nav.Link>
              )}
          </Nav>
          <Nav>
            <Nav.Link className="text-danger" onClick={() => handleSignOut()}>
              Sair
            </Nav.Link>
          </Nav>
        </>
      )}
    </Navbar>
  );
}

export default Header;
