import React from "react";

import { Table, Button } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";

import moment from "moment";
import "moment/locale/pt-br";

function TableHarvest(props) {
  const {
    harvestList,
    inputSearch,
    client_name,
    property_name,
    cultivation_name,
    cultivationVarieties_name,
    setShowSearchCultivationVarietiesModal,
    setShowEditHarvestModal,
    setShowDeleteHarvestModal,
  } = props;

  return (
    <Table striped bordered hover size="sm" className="rounded">
      <thead>
        <tr>
          <th width="100%" colSpan="6" className="align-middle text-center">
            <Button
              variant="outline-dark"
              size="sm"
              onClick={() => setShowSearchCultivationVarietiesModal(true)}
            >
              {
                //Corrigir posteriormente (Inserir o Filtro)
              }
              <FaSearch />
              &nbsp;
              {client_name === ""
                ? "Selecione um Produtor."
                : property_name === ""
                ? client_name
                : cultivation_name === ""
                ? property_name
                : cultivationVarieties_name === ""
                ? cultivation_name
                : cultivationVarieties_name}
            </Button>
          </th>
        </tr>
        <tr>
          <th width="2%">#</th>
          <th width="38%">Safra</th>
          <th width="20%" className="text-center">
            Início
          </th>
          <th width="20%" className="text-center">
            Final
          </th>
          <th width="10%" className="text-center">
            Ver
          </th>
          <th width="10%" className="text-center">
            Apagar
          </th>
        </tr>
      </thead>
      <tbody>
        {harvestList.length === 0 && (
          <tr>
            <td width="100%" colSpan="6" className="align-middle text-center">
              Nenhuma Safra Encontrada
            </td>
          </tr>
        )}
        {harvestList
          .sort(function (a, b) {
            var nameA = a.cultivation_name.toLowerCase(),
              nameB = b.cultivation_name.toLowerCase();
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
          })
          .filter(
            (item) =>
              item.client_name
                .toUpperCase()
                .indexOf(client_name.toUpperCase()) !== -1,
          )
          .filter(
            (item) =>
              item.property_name
                .toUpperCase()
                .indexOf(property_name.toUpperCase()) !== -1,
          )
          .filter(
            (item) =>
              item.cultivation_name
                .toUpperCase()
                .indexOf(cultivation_name.toUpperCase()) !== -1,
          )
          .filter(
            (item) =>
              item.cultivationVarieties_name
                .toUpperCase()
                .indexOf(cultivationVarieties_name.toUpperCase()) !== -1,
          )
          .filter(
            (item) =>
              item.id.indexOf(inputSearch) !== -1 ||
              item.cultivation_name
                .toUpperCase()
                .indexOf(inputSearch.toUpperCase()) !== -1,
          )
          .map((harvest, index) => (
            <tr key={index}>
              <td width="2%" className="align-middle">
                <b>{index + 1}</b>
              </td>
              <td width="43%" className="align-middle">
                {harvest.cultivation_name +
                  " " +
                  moment(harvest.harvestYearStart.toDate()).format("YY") +
                  "-" +
                  (parseInt(
                    moment(harvest.harvestYearStart.toDate()).format("YY"),
                  ) +
                    1)}
              </td>
              <td width="10%" className="align-middle text-center">
                {moment(harvest.harvestYearStart.toDate()).format("L")}
              </td>
              <td width="10%" className="align-middle text-center">
                {harvest.harvestYearEnd
                  ? moment(harvest.harvestYearEnd.toDate()).format("L")
                  : "Em Andamento"}
              </td>
              <td width="10%" className="text-center align-middle">
                <Button
                  className="bg-system"
                  size="sm"
                  onClick={() =>
                    setShowEditHarvestModal({
                      id: harvest.id,
                      harvestYearStart: harvest.harvestYearStart.toDate(),
                      harvestYearEnd: harvest.harvestYearEnd
                        ? harvest.harvestYearEnd.toDate()
                        : "",
                      fields: harvest.fields,
                      note: harvest.note,
                      client_id: harvest.client_id,
                      client_name: harvest.client_name,
                      property_id: harvest.property_id,
                      property_name: harvest.property_name,
                      cultivation_id: harvest.cultivation_id,
                      cultivation_name: harvest.cultivation_name,
                      cultivationVarieties_id: harvest.cultivationVarieties_id,
                      cultivationVarieties_name:
                        harvest.cultivationVarieties_name,
                      show: true,
                    })
                  }
                >
                  Ver
                </Button>
              </td>
              <td width="10%" className="text-center align-middle">
                <Button
                  disabled
                  variant="outline-danger"
                  size="sm"
                  onClick={() =>
                    setShowDeleteHarvestModal({
                      id: harvest._key,
                      name: harvest.name,
                      show: true,
                    })
                  }
                >
                  Apagar
                </Button>
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
}

export default TableHarvest;
