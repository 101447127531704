import React, { useState, useEffect } from "react";

import { Table, Button, Spinner, Row } from "react-bootstrap";

import { FaRegCircle, FaRegCheckCircle } from "react-icons/fa";

import { getHarvestFields } from "../../../controllers/ControllerField";

function TableSearchField(props) {
  const {
    property_id,
    inputSearch,
    insertField,
    removeField,
    fields,
    staticFields,
    setShowFields,
  } = props;

  const [fieldList, setFieldList] = useState([]);
  const [pending, setPending] = useState(false);

  function handleInsertField(field) {
    setFieldList(
      fieldList.map((item) => {
        if (item.id === field.id) {
          return { ...item, selected: true };
        }
        return item;
      }),
    );
    insertField({
      fieldNumber: field.fieldNumber,
      field_id: field.id,
      field_name: field.name,
    });
  }

  function handleRemoveField(field) {
    setFieldList(
      fieldList.map((item) => {
        if (item.id === field.id) {
          return { ...item, selected: false };
        }
        return item;
      }),
    );
    removeField(field.id);
  }

  async function get3cFields() {
    const response = await getHarvestFields(property_id);
    const mergeResponse = response.concat(
      staticFields.map((field) => {
        return {
          fieldNumber: field.fieldNumber,
          name: field.field_name,
          id: field.field_id,
        };
      }),
    );
    if (mergeResponse) {
      setFieldList(mergeResponse.map((item) => findField(item, fields)));
      setPending(true);
    }

    function findField(fields, fieldListFromDB) {
      const fieldCheck = fieldListFromDB.find(
        (item) => item.field_id === fields.id,
      );
      if (fieldCheck) {
        return { ...fields, selected: true };
      } else {
        return { ...fields, selected: false };
      }
    }
  }

  useEffect(() => {
    get3cFields();
    // eslint-disable-next-line
  }, [property_id]);

  return fieldList.length === 0 && !pending ? (
    <Row>
      <Spinner className="mx-auto my-5 text-system" animation="border" />
    </Row>
  ) : (
    <Table striped bordered hover size="sm" className="rounded">
      <thead>
        <tr>
          <th width="100%" colSpan="3" className="align-middle text-center">
            <Button
              variant="outline-danger"
              size="sm"
              onClick={() => setShowFields(false)}
            >
              Voltar
            </Button>
          </th>
        </tr>
      </thead>
      <thead>
        <tr>
          <th width="2%">#</th>
          <th width="88%">Produtor</th>
          <th width="10%" className="text-center">
            Selecionar
          </th>
        </tr>
      </thead>
      <tbody>
        {fieldList
          .sort(function (a, b) {
            var nameA = a.name.toLowerCase(),
              nameB = b.name.toLowerCase();
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
          })
          .filter(
            (item) =>
              item.id.indexOf(inputSearch) !== -1 ||
              item.name.toUpperCase().indexOf(inputSearch.toUpperCase()) !== -1,
          )
          .map((field, index) => (
            <tr key={index}>
              <td width="2%" className="align-middle">
                <b>{index + 1}</b>
              </td>
              <td width="63%" className="align-middle">
                {field.name}
              </td>
              <td width="10%" className="text-center align-middle">
                {field.selected ? (
                  <FaRegCheckCircle
                    className="text-system link"
                    onClick={() => handleRemoveField(field)}
                  />
                ) : (
                  <FaRegCircle
                    className="link"
                    onClick={() => handleInsertField(field)}
                  />
                )}
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
}

export default TableSearchField;
