import axios from "axios";

function getApi(token) {
  const api = axios.create({
    baseURL: "https://us-central1-erp-cansho.cloudfunctions.net",
    timeout: 9000,
  });

  api.interceptors.request.use(
    async (config) => {
      config.headers["Authorization"] = "Bearer " + token;
      return config;
    },
    function (error) {
      return Promise.reject(error);
    },
  );

  return api;
}

export { getApi };
