import React, { useState, useContext } from "react";

import { Container, Nav } from "react-bootstrap";

import { AuthContext } from "../../configurations/authorization";

import ResumeReport from "../../components/report/pageComponents/resumeReport";
import PeriodResumeReport from "../../components/report/pageComponents/periodResumeReport";

function Report() {
  const { code3cData } = useContext(AuthContext);
  const [eventKey, setEventKey] = useState(0);

  return (
    <Container
      className="rounded bg-white mt-3 mb-5 pb-2 px-0 ubuntuFont"
      style={{ height: " 475.4px" }}
    >
      <h2 className="text-center py-3">RELATÓRIOS</h2>
      <hr className="mt-0" />
      <Nav fill variant="tabs" defaultActiveKey="0">
        <Nav.Item>
          <Nav.Link eventKey="0" onClick={() => setEventKey(0)}>
            Resumo
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="1" onClick={() => setEventKey(1)}>
            Periodos
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="2" onClick={() => setEventKey(2)}>
            Novo*
          </Nav.Link>
        </Nav.Item>
        {/* <Nav.Item>
          <Nav.Link eventKey="2">Link</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="3">Disabled</Nav.Link>
        </Nav.Item> */}
      </Nav>
      <Container className="table-max-height">
        {eventKey === 0 && <ResumeReport code3cData={code3cData} />}
        {eventKey === 1 && <PeriodResumeReport code3cData={code3cData} />}
      </Container>
    </Container>
  );
}

export default Report;
