import { firebaseDB } from "../configurations/firebaseConfig";
import { toast } from "react-toastify";
import { getApi } from "../configurations/firebaseFunction";

const response = firebaseDB.collection("All-user");

async function addUser(data, token) {
  try {
    const api = getApi(token);
    const response = await api.post(`/user/create`, data);
    if (response.data.id) {
      return response.data.id;
    } else {
      return false;
    }
  } catch (err) {
    console.log(err);
    toast.error("Parece que tem algo errado com seu cadastro!", 3000);
  }
}

async function getUsers(id) {
  try {
    const data = await response.where("code3c_id", "==", id).get();
    const allData = [];
    data.docs.forEach((item) => {
      allData.push({ ...item.data(), id: item.id });
    });
    const dataFAGROUser = allData.map((FAGROUser) => {
      const [system] = FAGROUser.systems.filter(
        (system) => system.systemId === "FAGRO"
      );
      if (system) {
        return FAGROUser;
      }
      return "";
    });
    return dataFAGROUser.filter((visitor) => visitor !== "");
  } catch (err) {
    console.log(err);
    toast.error("Não foi possível ler os dados.");
  }
}

async function getAccess(id) {
  try {
    const data = await response.doc(id).get();
    const user = { ...data.data(), id: id };
    const system = user.systems.filter((system) => system.systemId === "FAGRO");
    if (system.length > 0) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    console.log(err);
    toast.error("Não foi possível ler os dados.");
  }
}

async function getVisitor(id) {
  try {
    const data = await response.where("code3c_id", "==", id).get();
    const allData = [];
    data.docs.forEach((item) => {
      allData.push({ ...item.data(), id: item.id });
    });
    const dataVisitor = allData.map((visitor) => {
      const [system] = visitor.systems.filter(
        (system) => system.systemId === "FAGRO" && system.function === "visitor"
      );
      if (system) {
        return {
          id: visitor.id,
          name: visitor.name,
          playerIDs: visitor.playerIDs ? visitor.playerIDs : [],
          systems: system,
        };
      }
      return "";
    });
    return dataVisitor.filter((visitor) => visitor !== "");
  } catch (err) {
    console.log(err);
    toast.error("Não foi possível ler os dados.");
  }
}

async function searchUser(id) {
  try {
    const data = await response.doc(id).get();
    return { ...data.data(), id: id };
  } catch (err) {
    console.log(err);
    toast.error("Não foi possível ler os dados.");
  }
}

async function editUser(data, id, token) {
  try {
    const dataDBExists = await response.doc(id).get();
    if (!dataDBExists) {
      return [];
    } else {
      const api = getApi(token);
      const response = await api.patch(`user/edit/${id}`, data);
      console.log(JSON.stringify(response));
      toast.success("Usuário " + data.name + " foi editado!", 3000);
      return true;
    }
  } catch (err) {
    console.log(err);
    toast.error("Parece que tem algo errado com sua edição!", 3000);
  }
}

async function deleteUser(name, id, token) {
  try {
    const api = getApi(token);
    const response = await api.delete(`/user/delete/${id}`);
    if (response.data.success) {
      toast.success("Usuário " + name + " foi apagado!", 3000);
      return true;
    } else {
      return false;
    }
  } catch (err) {
    console.log(err);
    toast.error("Não foi possível apagar o Dado!", 3000);
  }
}

export {
  addUser,
  getUsers,
  getAccess,
  getVisitor,
  searchUser,
  editUser,
  deleteUser,
};
