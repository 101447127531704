import React, { useContext } from "react";

import { Navbar } from "react-bootstrap";

import { AuthContext } from "../../configurations/authorization";

function Footer() {
  const { currentUser } = useContext(AuthContext);
  return (
    <>
      {!!currentUser && (
        <Navbar
          expand="md"
          bg="light"
          variant="white"
          className="rounded-top fixed-bottom ubuntuFont"
        >
          <Navbar.Collapse></Navbar.Collapse>
          <p>
            <small>
              @Copyright 2020 - Todos os direitos reservados. - Criado
              Orgulhosamente por&nbsp;
              {
                //eslint-disable-next-line
                <a
                  href="https://canshogroup.com.br"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Cansho Group&reg;
                </a>
              }
            </small>
          </p>
        </Navbar>
      )}
    </>
  );
}

export default Footer;
