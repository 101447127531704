import React from "react";

import { Container, Row, Col, Button } from "react-bootstrap";
import { FaRunning } from "react-icons/fa";

import paymentAlert from "../../assets/imgs/no-payment.svg";

import firebaseConfig from "../../configurations/firebaseConfig";

function NoPayment() {
  function handleSignOut() {
    firebaseConfig.auth().signOut();
    document.location.reload(true);
  }

  return (
    <Container
      fluid
      className="bg-white pb-2 px-0 ubuntuFont"
      style={{ height: "100vh" }}
    >
      <h2 className="text-center py-3">SISTEMA SUSPENSO</h2>
      <hr className="mt-0" />
      <Container className="mx-auto px-5">
        <Row>
          <Col>
            <h1 className="text-center">
              <Container className="border-0">
                <img
                  alt="Alerta de Pagamento Suspenso"
                  className="mx-auto"
                  src={paymentAlert}
                  style={{ width: "128px" }}
                />
              </Container>
            </h1>
          </Col>
        </Row>
        <Row className="my-3">
          <Col>
            <h5 className="text-center">
              O seu acesso ao <b className="text-system">Força Agro</b> foi
              suspenso por falta de pagamento.
              <br />
              Para mais informações entre em contato conosco.
            </h5>
          </Col>
        </Row>
        <Row className="my-4">
          <Col className="mx-auto">
            <h1 className="text-center">
              <a
                href="https://tawk.to/chat/5c04c5b340105007f37aaeee/1ctpap7pm"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button variant="success">FALE CONOSCO</Button>
              </a>
            </h1>
            <p className="text-muted text-center">
              Você será redirecionado para outra página fora do sistema.
            </p>
          </Col>
        </Row>
        <Row className="mx-auto">
          <Col>
            <h1 className="text-center">
              <Button variant="danger" onClick={() => handleSignOut()}>
                <FaRunning />
                &nbsp; SAIR
              </Button>
            </h1>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default NoPayment;
