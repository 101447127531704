import React, { useState, useEffect, useContext } from "react";
import firebase from "firebase";

import {
  Container,
  Modal,
  Button,
  Form,
  Col,
  Table,
  Navbar,
} from "react-bootstrap";

import { FaEdit } from "react-icons/fa";

import { AuthContext } from "../../../configurations/authorization";

import { getPropertyHarvests } from "../../../controllers/ControllerHarvest";

import JumboHarvests from "../modalComponents/jumboHarvests";

import NewVisitDoc from "../pageModals/newVisitDoc";
import VisitDocument from "../pageDocuments/visitDocument";
// eslint-disable-next-line
// import TableRecommendationType from "../modalComponents/tableRecommendationType";

import moment from "moment";
import "moment/locale/pt-br";

import {
  editNoteVisit,
  searchVisit,
} from "../../../controllers/ControllerVisit";
import { getVisitsRecommendations } from "../../../controllers/ControllerRecommendation";

function EditVisit(props) {
  const { showEditVisitModal, setShowEditVisitModal, code3c_id, getData } =
    props;

  const { code3cData } = useContext(AuthContext);

  const [visitDate, setVisitDate] = useState("");
  const [endVisit, setEndVisit] = useState("");
  const [visit, setVisit] = useState("");

  const [client_name, setClient_name] = useState("");
  const [property_name, setProperty_name] = useState("");
  const [user_name, setUser_name] = useState("");
  const [id, setId] = useState("");

  const [editRecNote, setEditRecNote] = useState(false);
  const [recommendationNote, setRecNote] = useState("");

  const [harvestList, setHarvestList] = useState([]);
  // eslint-disable-next-line
  const [recommendationList, setRecommendationList] = useState([]);

  const [showNewVisitDocModal, setShowNewVisitDocModal] = useState({
    show: false,
  });

  function clearFields() {
    setShowEditVisitModal({ show: false });
  }

  function handleClose() {
    clearFields();
    setEditRecNote(false);
  }

  async function getHarvests(property_id) {
    setHarvestList(await getPropertyHarvests(property_id));
  }

  async function getVisits(id) {
    const response = await searchVisit(id);
    setVisit(response);
    setRecommendationList(await getVisitsRecommendations(response.id));
  }

  useEffect(() => {
    if (showEditVisitModal.visitDate) {
      getVisits(showEditVisitModal.id);
      setId(showEditVisitModal.id);
      setVisitDate(moment(showEditVisitModal.visitDate).format("YYYY-MM-DD"));
      setEndVisit(
        showEditVisitModal.endVisit === ""
          ? ""
          : moment(showEditVisitModal.endVisit).format("YYYY-MM-DD")
      );
      setClient_name(showEditVisitModal.client_name);
      setProperty_name(showEditVisitModal.property_name);
      setUser_name(showEditVisitModal.user_name);
      getHarvests(showEditVisitModal.property_id);
      setRecNote(showEditVisitModal.recommendationNote || "");
    }
  }, [showEditVisitModal]);

  const handleEditRecNote = async () => {
    await editNoteVisit(
      {
        recommendationNote: recommendationNote,
        updatedRecNote: new Date(),
      },
      showEditVisitModal.id
    );
    console.log(firebase.firestore.Timestamp.now());
    setShowEditVisitModal({
      ...showEditVisitModal,
      recommendationNote,
      updatedRecNote: firebase.firestore.Timestamp.now(),
    });
    setEditRecNote(false);
    getData();
  };

  const cancelEdit = () => {
    setEditRecNote(false);
    setRecNote(showEditVisitModal.recommendationNote);
  };

  const printVisit = () => {
    const url = `https://us-central1-erp-cansho.cloudfunctions.net/pdfGenerator/pdfVisit/${showEditVisitModal.id}`;
    window.open(url, "_blank");
  };

  return (
    <Container className="ubuntuFont">
      <NewVisitDoc
        showNewVisitDocModal={showNewVisitDocModal}
        setShowNewVisitDocModal={setShowNewVisitDocModal}
        Document={VisitDocument}
        code3cData={code3cData}
      />
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className="ubuntuFont"
        show={showEditVisitModal.show}
        onHide={handleClose}
        centered
      >
        <Modal.Header className="text-center" closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-center"
          >
            <FaEdit className="text-primary" />
            &emsp;Ver Visita
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Table
              striped
              bordered
              hover
              size="sm"
              className="rounded border-0"
            ></Table>
            <Form.Row>
              <Form.Group as={Col} md={6}>
                <Form.Label>Produtor</Form.Label>
                <Form.Control disabled type="text" value={client_name} />
              </Form.Group>
              <Form.Group as={Col} md={6}>
                <Form.Label>Propriedade</Form.Label>
                <Form.Control disabled type="text" value={property_name} />
              </Form.Group>
            </Form.Row>
            <Form.Group>
              <Form.Label>Visitante</Form.Label>
              <Form.Control disabled type="text" value={user_name} />
            </Form.Group>
            <Form.Row>
              <Form.Group as={Col} md={6}>
                <Form.Label>Data da Visita</Form.Label>
                <Form.Control
                  required
                  disabled
                  type="date"
                  value={visitDate}
                  onChange={(e) => setVisitDate(e.target.value)}
                />
              </Form.Group>
              <Form.Group as={Col} md={6}>
                <Form.Label>Final da Visita</Form.Label>
                <Form.Control
                  required
                  disabled
                  type="date"
                  value={endVisit}
                  onChange={(e) => setVisitDate(e.target.value)}
                />
                {endVisit === "" && (
                  <Form.Text className="text-danger">
                    Visita não Finalizada
                  </Form.Text>
                )}
              </Form.Group>
            </Form.Row>

            <Form.Group className="my-2">
              <Navbar className="justify-content-between px-0">
                {showEditVisitModal.updatedRecNote ? (
                  <small>
                    Modificado em{" "}
                    {moment(showEditVisitModal.updatedRecNote.toDate()).format(
                      "LLL"
                    )}
                  </small>
                ) : (
                  <p>{""}</p>
                )}
                {editRecNote ? (
                  <div>
                    <Button
                      className="btn-success mr-1"
                      size="sm"
                      onClick={() => {
                        handleEditRecNote();
                      }}
                    >
                      Salvar
                    </Button>
                    <Button
                      className="btn-danger"
                      size="sm"
                      onClick={() => {
                        cancelEdit();
                      }}
                    >
                      Cancelar
                    </Button>
                  </div>
                ) : (
                  <Button
                    className="bg-system"
                    size="sm"
                    onClick={() => {
                      setEditRecNote(true);
                    }}
                  >
                    Editar Campo
                  </Button>
                )}
              </Navbar>

              <Form.Control
                as="textarea"
                rows="3"
                disabled={!editRecNote}
                placeholder="Descrição"
                value={recommendationNote}
                onChange={(e) => setRecNote(e.target.value)}
              />
            </Form.Group>

            {harvestList.length > 0 && (
              <Form.Group>
                <Form.Label>Safras</Form.Label>
                {harvestList.map((harvest, index) => (
                  <JumboHarvests
                    visit={visit}
                    visit_id={id}
                    visitDate={visitDate}
                    code3cData={code3cData}
                    code3c_id={code3c_id}
                    harvest={harvest}
                    key={index}
                  />
                ))}
              </Form.Group>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={editRecNote}
            variant="success"
            className="mr-2"
            // onClick={() => {
            //   setShowNewVisitDocModal({
            //     visit,
            //     harvestList,
            //     recommendationList,
            //     TableRecommendationType,
            //     show: true,
            //   });
            // }}
            onClick={() => {
              printVisit();
            }}
          >
            Exportar em PDF
          </Button>
          <Button variant="danger" onClick={() => handleClose()}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default EditVisit;
