import React, { useEffect, useState } from "react";
import firebaseAuth from "./firebaseConfig";

import { Row, Spinner } from "react-bootstrap";

import { toast } from "react-toastify";

import { searchUser } from "../controllers/ControllerUser";
import { searchCode3c } from "../controllers/ControllerCode3c";

import NoPayment from "../pages/error/noPayment";

import moment from "moment";
import "moment/locale/pt-br";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [code3cData, setCode3cData] = useState({
    id: "",
  });
  const [userData, setUserData] = useState({
    name: "",
    systems: [{}],
  });

  const [systemData, setSystemData] = useState({
    function: "",
  });
  const [system3cData, setSystem3cData] = useState({
    systemBilling: "",
  });
  const [systemNoPayment, setSystemNoPayment] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const [pending, setPending] = useState(true);

  function clearProvider() {
    setCurrentUser(null);
    setCode3cData({
      id: "",
    });
    setUserData({
      name: "",
      systems: [{}],
    });
    setSystemData({
      function: "",
    });
    setSystem3cData({
      systemBilling: "",
    });
    setSystemNoPayment(false);
    setAccessToken("");
  }

  useEffect(() => {
    firebaseAuth.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const userDataDB = await searchUser(user.uid);
        const code3cDataDB = await searchCode3c(userDataDB.code3c_id);

        const [systemDataDB] = userDataDB.systems.filter(
          (stm) => stm.systemId === "FAGRO"
        );

        const [system3cDataDB] = code3cDataDB.systems.filter(
          (stm) => stm.systemId === "FAGRO"
        );

        if (!!systemDataDB) {
          if (systemDataDB.function === "administrator") {
            const userToken = await user.getIdTokenResult(false);

            setAccessToken(userToken.token);

            setUserData(userDataDB);
            setCode3cData(code3cDataDB);

            setCurrentUser(user);

            setSystemData(systemDataDB);
            setSystem3cData(system3cDataDB);

            if (system3cDataDB.systemBilling.systemDateBill) {
              const maxDate = moment(
                system3cDataDB.systemBilling.systemDateBill.toDate()
              ).add(15, "d");
              if (moment().isAfter(moment(maxDate))) {
                setSystemNoPayment(true);
              }
            }
          } else {
            clearProvider();
            firebaseAuth.auth().signOut();
            toast.warn("Esse usuário não pode acessar esse módulo.");
          }
        } else {
          clearProvider();
          firebaseAuth.auth().signOut();
          toast.warn("Esse usuário não possui esse módulo.");
        }
      }
      setPending(false);
    });
  }, []);

  if (systemNoPayment) {
    return <NoPayment />;
  }

  if (pending) {
    return (
      <Row className="my-auto">
        <Spinner className="mx-auto my-5 text-system" animation="border" />
      </Row>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        accessToken,
        code3cData,
        userData,
        systemData,
        system3cData,
        clearProvider,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
