import React, { useState, useEffect } from "react";

import { Container, Modal, Button, Form } from "react-bootstrap";

import { toast } from "react-toastify";
import { FaEdit } from "react-icons/fa";

import { dataSchema } from "../../../functions/harvest";

import { editHarvest } from "../../../controllers/ControllerHarvest";

import TableSearchField from "../modalComponents/tableSearchField";

import FormEditHarvest from "../modalComponents/formEditHarvest";

import moment from "moment";
import "moment/locale/pt-br";

function EditHarvest(props) {
  const {
    showEditHarvestModal,
    setShowEditHarvestModal,
    getData,
    code3c_id,
  } = props;

  const [harvestYearStart, setHarvestYearStart] = useState();
  const [harvestYearEnd, setHarvestYearEnd] = useState();

  const [note, setNote] = useState("");
  const [fields, setFields] = useState([]);
  const [staticFields, setStaticFields] = useState([]);
  const [showFields, setShowFields] = useState(false);

  const [validated, setValidated] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editHarvestYear, setEditHarvestYear] = useState(false);
  const [formErr, setFormErr] = useState([]);

  const [pendingRequest, setPendingRequest] = useState(false);

  const [inputSearch, setInputSearch] = useState("");

  function insertField(field) {
    setFields([...fields, field]);
  }

  function removeField(field_id) {
    setFields(fields.filter((item) => field_id !== item.field_id));
  }

  const [client_id, setClient_id] = useState("");
  const [client_name, setClient_name] = useState("");
  const [property_id, setProperty_id] = useState("");
  const [property_name, setProperty_name] = useState("");
  const [cultivation_id, setCultivation_id] = useState("");
  const [cultivation_name, setCultivation_name] = useState("");
  const [cultivationVarieties_id, setCultivationVarieties_id] = useState("");
  const [cultivationVarieties_name, setCultivationVarieties_name] = useState(
    "",
  );

  function clearHarvests() {
    setPendingRequest(false);
    setFormErr([]);
    setEdit(false);
    setEditHarvestYear(false);
    setValidated(false);
    setShowEditHarvestModal({ show: false });
  }

  function handleClose() {
    clearHarvests();
  }

  async function handleEditHarvest() {
    var data;
    if (editHarvestYear) {
      data = {
        code3c_id,
        client_id,
        client_name,
        property_id,
        property_name,
        cultivation_id,
        cultivation_name,
        cultivationVarieties_id,
        cultivationVarieties_name,
        fields,
        harvestYearStart:
          harvestYearStart === "" ? "" : new Date(moment(harvestYearStart)),
        harvestYearEnd:
          harvestYearEnd === "" ? "" : new Date(moment(harvestYearEnd)),
        note,
      };
    } else {
      data = {
        code3c_id,
        client_id,
        client_name,
        property_id,
        property_name,
        cultivation_id,
        cultivation_name,
        cultivationVarieties_id,
        cultivationVarieties_name,
        fields,
        harvestYearStart:
          harvestYearStart === "" ? "" : new Date(moment(harvestYearStart)),
        note,
      };
    }

    dataSchema
      .validate(data, { abortEarly: false })
      .then(async function () {
        setValidated(true);
        const response = await editHarvest(data, showEditHarvestModal.id);
        if (response) {
          getData(code3c_id);
          setEdit(false);
          setEditHarvestYear(false);
          clearHarvests();
          setFormErr([]);
        }
      })
      .catch(function (err) {
        setPendingRequest(false);
        setFormErr(err.inner);
        toast.error("Existem campos com erros.");
      });
  }

  function catchErrors(errorHarvest) {
    return (
      <Form.Control.Feedback type="invalid">
        {formErr.map((err) => {
          if (err.path === errorHarvest) {
            return <p>{err.errors}</p>;
          }
          return null;
        })}
      </Form.Control.Feedback>
    );
  }

  function catchInvalid(errorHarvest) {
    if (formErr.filter((err) => err.path === errorHarvest).length >= 1) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    if (showEditHarvestModal.cultivation_name) {
      setHarvestYearStart(
        moment(showEditHarvestModal.harvestYearStart).format("YYYY-MM-DD"),
      );
      setHarvestYearEnd(
        showEditHarvestModal.harvestYearEnd === ""
          ? ""
          : moment(showEditHarvestModal.harvestYearEnd).format("YYYY-MM-DD"),
      );
      setNote(showEditHarvestModal.note);
      setClient_id(showEditHarvestModal.client_id);
      setClient_name(showEditHarvestModal.client_name);
      setProperty_id(showEditHarvestModal.property_id);
      setProperty_name(showEditHarvestModal.property_name);
      setCultivation_id(showEditHarvestModal.cultivation_id);
      setCultivation_name(showEditHarvestModal.cultivation_name);
      setCultivationVarieties_id(showEditHarvestModal.cultivationVarieties_id);
      setCultivationVarieties_name(
        showEditHarvestModal.cultivationVarieties_name,
      );
      setFields(showEditHarvestModal.fields);
      setStaticFields(showEditHarvestModal.fields);
    }
  }, [showEditHarvestModal]);

  return (
    <Container className="ubuntuFont">
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        className="ubuntuFont"
        show={showEditHarvestModal.show}
        onHide={handleClose}
        centered
      >
        <Modal.Header className="text-center" closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-center"
          >
            <FaEdit className="text-primary" />
            &emsp;Editar Safra:{" "}
            {showEditHarvestModal.cultivation_name +
              " " +
              moment(showEditHarvestModal.harvestYearStart).format("YY") +
              "-" +
              (parseInt(
                moment(showEditHarvestModal.harvestYearStart).format("YY"),
              ) +
                1)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showFields ? (
            <>
              <Form.Control
                type="text"
                placeholder="Pesquisar talhões"
                size="sm"
                className="mb-3 px-3"
                value={inputSearch}
                onChange={(e) => setInputSearch(e.target.value)}
                required
              />
              <TableSearchField
                property_id={property_id}
                inputSearch={inputSearch}
                insertField={insertField}
                removeField={removeField}
                fields={fields}
                staticFields={staticFields}
                setShowFields={setShowFields}
              />
            </>
          ) : (
            <FormEditHarvest
              client_name={client_name}
              property_name={property_name}
              cultivation_name={cultivation_name}
              cultivationVarieties_name={cultivationVarieties_name}
              harvestYearStart={harvestYearStart}
              setHarvestYearStart={setHarvestYearStart}
              harvestYearEnd={harvestYearEnd}
              setHarvestYearEnd={setHarvestYearEnd}
              note={note}
              setNote={setNote}
              fields={fields}
              setShowFields={setShowFields}
              catchErrors={catchErrors}
              catchInvalid={catchInvalid}
              validated={validated}
              edit={edit}
              editHarvestYear={editHarvestYear}
              setEditHarvestYear={setEditHarvestYear}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          {!showFields && (
            <>
              {edit ? (
                <>
                  <Button
                    disabled={pendingRequest ? true : false}
                    variant="outline-success"
                    className="mr-2"
                    onClick={() =>
                      handleEditHarvest() + setPendingRequest(true)
                    }
                  >
                    Salvar
                  </Button>
                  <Button
                    variant="outline-dark"
                    className="mr-2"
                    onClick={() => setEdit(false)}
                  >
                    Cancelar
                  </Button>
                </>
              ) : (
                <Button
                  variant="success"
                  className="mr-2"
                  onClick={() => setEdit(true)}
                >
                  Editar
                </Button>
              )}
            </>
          )}
          <Button variant="danger" onClick={() => handleClose()}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default EditHarvest;
