import React, { useState, useEffect } from "react";

import { Container, Modal, Button, Form, InputGroup } from "react-bootstrap";

import { toast } from "react-toastify";
import { FaPlusCircle, FaEye, FaEyeSlash } from "react-icons/fa";

import { dataSchema } from "../../../functions/user";

import { addUser } from "../../../controllers/ControllerUser";

function AddUser(props) {
  const { showAddUserModal, setShowAddUserModal, code3c_id, token, getData } =
    props;

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordValidation, setPasswordValidation] = useState("");
  const [userFunction, setUserFunction] = useState("administrator");
  const [seePass, setSeePass] = useState(false);

  const [validated, setValidated] = useState(false);
  const [formErr, setFormErr] = useState([]);

  const [pendingRequest, setPendingRequest] = useState(false);

  function handleUserFunction(item) {
    setUserFunction(item.target.value);
  }

  function clearFields() {
    setFormErr([]);
    setName("");
    setEmail("");
    setPassword("");
    setPasswordValidation("");
    setUserFunction("");
    setPendingRequest(false);
    setValidated(false);
    setShowAddUserModal(false);
  }

  function handleClose() {
    clearFields();
  }

  async function handleAddUser() {
    const dataValidation = {
      code3c_id,
      name,
      email,
      password,
      passwordValidation,
      systems: [
        {
          systemActive: true,
          systemId: "FAGRO",
          systemName: "Cansho Força Agro",
          function: userFunction,
        },
      ],
    };

    dataSchema
      .validate(dataValidation, { abortEarly: false })
      .then(async function () {
        setFormErr([]);
        setValidated(true);
        const data = {
          code3c_id: dataValidation.code3c_id,
          name: dataValidation.name,
          email: dataValidation.email,
          password: dataValidation.password,
          systems: dataValidation.systems,
        };
        const response = await addUser(data, token);
        if (response) {
          getData();
          clearFields();
        }
      })
      .catch(function (err) {
        if (err.inner) {
          console.log(err.inner);
          setFormErr(err.inner);
          setPendingRequest(false);
        } else {
          console.log(err);
        }
        setPendingRequest(false);
        toast.error("Existem campos com erros.");
      });
  }

  function catchErrors(errorField) {
    return (
      <Form.Control.Feedback type="invalid">
        {formErr.map((err) => {
          if (err.path === errorField) {
            return <p>{err.errors}</p>;
          }
          return null;
        })}
      </Form.Control.Feedback>
    );
  }

  function catchInvalid(errorField) {
    if (formErr.filter((err) => err.path === errorField).length >= 1) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    setUserFunction("administrator");
    // eslint-disable-next-line
  }, []);

  return (
    <Container className="ubuntuFont">
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        className="ubuntuFont"
        show={showAddUserModal}
        onHide={handleClose}
        centered
      >
        <Modal.Header className="text-center" closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-center"
          >
            <FaPlusCircle className="text-success" />
            &emsp;Novo Usuário
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated}>
            <Form.Group>
              <Form.Label>Nome do Usuário</Form.Label>
              <Form.Control
                required
                isInvalid={catchInvalid("name")}
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {catchErrors("name")}
            </Form.Group>
            <Form.Text className="text-muted text-center mb-3">
              Informações de Login.
            </Form.Text>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                isInvalid={catchInvalid("email")}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {catchErrors("email")}
            </Form.Group>
            <Form.Group>
              <Form.Label>Senha do Usuário</Form.Label>
              <InputGroup>
                <Form.Control
                  isInvalid={catchInvalid("password")}
                  type={seePass ? "text" : "password"}
                  placeholder="Sua senha"
                  className="shadow-none"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />
                <InputGroup.Prepend>
                  <InputGroup.Text
                    id="inputGroupPrepend"
                    className="rounded-right link"
                    onClick={() => setSeePass(!seePass)}
                  >
                    {seePass ? <FaEyeSlash /> : <FaEye />}
                  </InputGroup.Text>
                </InputGroup.Prepend>
                {catchErrors("password")}
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <Form.Label>Confirme a Senha do Usuário</Form.Label>
              <InputGroup>
                <Form.Control
                  isInvalid={catchInvalid("passwordValidation")}
                  type={seePass ? "text" : "password"}
                  placeholder="Sua senha"
                  className="shadow-none"
                  onChange={(e) => setPasswordValidation(e.target.value)}
                  value={passwordValidation}
                />
                <InputGroup.Prepend>
                  <InputGroup.Text
                    id="inputGroupPrepend"
                    className="rounded-right link"
                    onClick={() => setSeePass(!seePass)}
                  >
                    {seePass ? <FaEyeSlash /> : <FaEye />}
                  </InputGroup.Text>
                </InputGroup.Prepend>
                {catchErrors("passwordValidation")}
              </InputGroup>
              <Form.Text className="text-muted">
                As senhas precisam ser iguais e ter mais que 8 caracteres.
              </Form.Text>
            </Form.Group>
            <Form.Text className="text-muted text-center mb-3">
              Informações de Usuário.
            </Form.Text>
            <Form.Group>
              <Form.Label>Tipo de usuário</Form.Label>
              <Form.Control as="select" onClick={handleUserFunction.bind(this)}>
                <option value={"administrator"}>Administrador</option>
                <option value={"visitor"}>Visitante</option>
              </Form.Control>
              <Form.Text className="text-muted">
                Administrador ou Visitante.
              </Form.Text>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={pendingRequest ? true : false}
            variant="success"
            className="mr-2"
            onClick={() => handleAddUser() + setPendingRequest(true)}
          >
            Salvar
          </Button>
          <Button variant="danger" onClick={() => handleClose()}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default AddUser;
