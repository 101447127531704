import React, { useEffect, useState, useContext } from "react";

import {
  Container,
  Row,
  Spinner,
  Navbar,
  Button,
  Form,
  FormControl,
} from "react-bootstrap";

import { FaPlusCircle, FaFilter } from "react-icons/fa";

import TablePesticide from "../../components/pesticide/pageComponents/tablePesticide";

import AddPesticide from "../../components/pesticide/pageModals/addPesticide";
import EditPesticide from "../../components/pesticide/pageModals/editPesticide";
import DeletePesticide from "../../components/pesticide/pageModals/deletePesticide";

import FilterPesticideTypes from "../../components/pesticide/pageModals/filterPesticideTypes";

import { getPesticides } from "../../controllers/ControllerPesticide";

import { AuthContext } from "../../configurations/authorization";

function Pesticide() {
  const { code3cData } = useContext(AuthContext);

  const [pesticideList, setPesticideList] = useState([]);

  const [inputSearch, setInputSearch] = useState("");
  const [inputFilter, setInputFilter] = useState("");

  const [toggleSpinner, setToggleSpinner] = useState(false);

  const [showAddPesticideModal, setShowAddPesticideModal] = useState(false);
  const [showEditPesticideModal, setShowEditPesticideModal] = useState({
    show: false,
  });
  const [showDeletePesticideModal, setShowDeletePesticideModal] = useState({
    show: false,
  });

  const [
    showFilterPesticideTypesModal,
    setShowFilterPesticideTypesModal,
  ] = useState(false);

  function handlePesticideTypes(types) {
    setInputFilter(types);
    setShowFilterPesticideTypesModal(false);
  }
  function clearPesticideTypes(types) {
    setInputFilter("");
    setShowFilterPesticideTypesModal(false);
  }

  async function getData() {
    const dataResponse = await getPesticides(code3cData.id);
    if (dataResponse) {
      setPesticideList(dataResponse);
      setToggleSpinner(true);
    }
  }

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  return (
    <Container
      className="rounded bg-white my-3 pb-2 px-0 ubuntuFont"
      style={{ height: " 475.4px" }}
    >
      <AddPesticide
        showAddPesticideModal={showAddPesticideModal}
        setShowAddPesticideModal={setShowAddPesticideModal}
        pesticideList={pesticideList}
        setPesticideList={setPesticideList}
        code3c_id={code3cData.id}
      />
      <EditPesticide
        showEditPesticideModal={showEditPesticideModal}
        setShowEditPesticideModal={setShowEditPesticideModal}
        getData={getData}
        code3c_id={code3cData.id}
      />
      <DeletePesticide
        showDeletePesticideModal={showDeletePesticideModal}
        setShowDeletePesticideModal={setShowDeletePesticideModal}
        getData={getData}
        code3c_id={code3cData.id}
      />

      <FilterPesticideTypes
        showFilterPesticideTypesModal={showFilterPesticideTypesModal}
        setShowFilterPesticideTypesModal={setShowFilterPesticideTypesModal}
        handlePesticideTypes={handlePesticideTypes}
        clearPesticideTypes={clearPesticideTypes}
      />

      <h2 className="text-center py-3">DEFENSIVOS</h2>
      <hr className="mt-0" />
      <Navbar
        bg="white"
        variant="white"
        className="mb-3 justify-content-between"
      >
        <Navbar.Brand className="link">
          <Button
            variant="outline-success"
            onClick={() => {
              setShowAddPesticideModal(!showAddPesticideModal);
            }}
          >
            <FaPlusCircle className="text-outline-success" />
            &nbsp; Novo Defensivo
          </Button>
          <Button
            variant="outline-primary"
            className=" mx-1"
            onClick={() => setShowFilterPesticideTypesModal(true)}
          >
            <FaFilter className="text-outline-primary" />
            &nbsp; {inputFilter === "" ? "Filtrar Categoria" : inputFilter}
          </Button>
        </Navbar.Brand>
        <Form inline className="justify-content-end">
          <FormControl
            type="text"
            placeholder="Pesquisar defensivo"
            className="mr-sm-2 justify-content-end"
            value={inputSearch}
            onChange={(e) => setInputSearch(e.target.value)}
          />
        </Form>
      </Navbar>
      <Container className="table-max-height">
        {pesticideList === 0 && !toggleSpinner ? (
          <Row>
            <Spinner className="mx-auto my-5 text-system" animation="border" />
          </Row>
        ) : (
          <TablePesticide
            pesticideList={pesticideList}
            inputSearch={inputSearch}
            inputFilter={inputFilter}
            setShowEditPesticideModal={setShowEditPesticideModal}
            setShowDeletePesticideModal={setShowDeletePesticideModal}
            toggleSpinner={toggleSpinner}
          />
        )}
      </Container>
    </Container>
  );
}

export default Pesticide;
