import { firebaseDB } from "../configurations/firebaseConfig";
import { toast } from "react-toastify";

const response = firebaseDB.collection("All-code3c");

async function getCode3c() {
  try {
    const data = await response.get();
    const allData = [];
    data.docs.forEach((item) => {
      allData.push({ ...item.data(), id: item.id });
    });
    return allData;
  } catch (err) {
    console.log(err);
    toast.error("Não foi possível ler os dados.");
  }
}

async function searchCode3c(id) {
  try {
    const data = await response.doc(id).get();
    return { ...data.data(), id: id };
  } catch (err) {
    console.log(err);
    toast.error("Não foi possível ler os dados.");
  }
}

export { getCode3c, searchCode3c };
