import React, { useEffect, useState, useContext } from "react";

import {
  Container,
  Row,
  Spinner,
  Navbar,
  Button,
  Form,
  FormControl,
} from "react-bootstrap";

import { FaPlusCircle } from "react-icons/fa";

import TableHarvest from "../../components/harvest/pageComponents/tableHarvest";

import AddHarvest from "../../components/harvest/pageModals/addHarvest";
import EditHarvest from "../../components/harvest/pageModals/editHarvest";
import DeleteHarvest from "../../components/harvest/pageModals/deleteHarvest";

import SearchCultivationVarieties from "../../components/harvest/pageModals/searchCultivationVarieties";

import { getHarvests } from "../../controllers/ControllerHarvest";

import { AuthContext } from "../../configurations/authorization";

function Harvest() {
  const { code3cData } = useContext(AuthContext);

  const [harvestList, setHarvestList] = useState([]);

  const [inputSearch, setInputSearch] = useState("");

  const [toggleSpinner, setToggleSpinner] = useState(false);

  const [client_id, setClient_id] = useState("");
  const [client_name, setClient_name] = useState("");
  const [property_id, setProperty_id] = useState("");
  const [property_name, setProperty_name] = useState("");
  const [cultivation_id, setCultivation_id] = useState("");
  const [cultivation_name, setCultivation_name] = useState("");
  const [cultivationVarieties_id, setCultivationVarieties_id] = useState("");
  const [cultivationVarieties_name, setCultivationVarieties_name] = useState(
    "",
  );

  const [
    showSearchCultivationVarietiesModal,
    setShowSearchCultivationVarietiesModal,
  ] = useState(false);

  const [showAddHarvestModal, setShowAddHarvestModal] = useState(false);
  const [showEditHarvestModal, setShowEditHarvestModal] = useState({
    show: false,
  });
  const [showDeleteHarvestModal, setShowDeleteHarvestModal] = useState({
    show: false,
  });

  async function getData() {
    const dataResponse = await getHarvests(code3cData.id);
    if (dataResponse) {
      setHarvestList(dataResponse);
      setToggleSpinner(true);
    }
  }

  async function handleClient(client_id, client_name) {
    setClient_id(client_id);
    setClient_name(client_name);
  }

  function clearClient() {
    setClient_id("");
    setClient_name("");
    setProperty_id("");
    setProperty_name("");
  }

  async function handleProperty(property_id, property_name) {
    setProperty_id(property_id);
    setProperty_name(property_name);
  }

  function clearProperty() {
    setCultivation_id("");
    setCultivation_name("");
    setProperty_id("");
    setProperty_name("");
  }

  async function handleCultivation(cultivation_id, cultivation_name) {
    setCultivation_id(cultivation_id);
    setCultivation_name(cultivation_name);
  }

  function clearCultivation() {
    setCultivationVarieties_id("");
    setCultivationVarieties_name("");
    setCultivation_id("");
    setCultivation_name("");
  }

  async function handleCultivationVarieties(
    cultivationVarieties_id,
    cultivationVarieties_name,
  ) {
    setCultivationVarieties_id(cultivationVarieties_id);
    setCultivationVarieties_name(cultivationVarieties_name);
    setShowAddHarvestModal(false);
  }

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [property_id]);

  return (
    <Container
      className="rounded bg-white my-3 pb-2 px-0 ubuntuFont"
      style={{ height: " 475.4px" }}
    >
      <AddHarvest
        showAddHarvestModal={showAddHarvestModal}
        setShowAddHarvestModal={setShowAddHarvestModal}
        getData={getData}
        code3c_id={code3cData.id}
      />
      <EditHarvest
        showEditHarvestModal={showEditHarvestModal}
        setShowEditHarvestModal={setShowEditHarvestModal}
        getData={getData}
        code3c_id={code3cData.id}
        client_id={client_id}
        client_name={client_name}
        property_id={property_id}
        property_name={property_name}
      />
      <DeleteHarvest
        showDeleteHarvestModal={showDeleteHarvestModal}
        setShowDeleteHarvestModal={setShowDeleteHarvestModal}
        getData={getData}
        code3c_id={code3cData.id}
      />
      <SearchCultivationVarieties
        showSearchCultivationVarietiesModal={
          showSearchCultivationVarietiesModal
        }
        setShowSearchCultivationVarietiesModal={
          setShowSearchCultivationVarietiesModal
        }
        handleClient={handleClient}
        clearClient={clearClient}
        handleProperty={handleProperty}
        clearProperty={clearProperty}
        handleCultivation={handleCultivation}
        clearCultivation={clearCultivation}
        handleCultivationVarieties={handleCultivationVarieties}
        code3c_id={code3cData.id}
        client_id={client_id}
        property_id={property_id}
        cultivation_id={cultivation_id}
        cultivationVarieties_id={cultivationVarieties_id}
        setClient_id={setClient_id}
        setProperty_id={setProperty_id}
        setCultivation_id={setCultivation_id}
        setCultivationVarieties_id={setCultivationVarieties_id}
        setClient_name={setClient_name}
        setProperty_name={setProperty_name}
        setCultivation_name={setCultivation_name}
        setCultivationVarieties_name={setCultivationVarieties_name}
        harvestList={harvestList}
        toggleSpinner={toggleSpinner}
      />

      <h2 className="text-center py-3">SAFRAS</h2>
      <hr className="mt-0" />
      <Navbar
        bg="white"
        variant="white"
        className="mb-3 justify-content-between"
      >
        <Navbar.Brand className="link">
          <Button
            variant="outline-success"
            onClick={() => {
              setShowAddHarvestModal(!showAddHarvestModal);
            }}
          >
            <FaPlusCircle className="text-outline-success" />
            &nbsp; Nova Safra
          </Button>
        </Navbar.Brand>
        <Form inline className="justify-content-end">
          <FormControl
            type="text"
            placeholder="Pesquisar safra por cultura"
            className="mr-sm-2 justify-content-end"
            value={inputSearch}
            onChange={(e) => setInputSearch(e.target.value)}
          />
        </Form>
      </Navbar>
      <Container className="table-max-height">
        {harvestList === 0 && !toggleSpinner ? (
          <Row>
            <Spinner className="mx-auto my-5 text-system" animation="border" />
          </Row>
        ) : (
          <TableHarvest
            harvestList={harvestList}
            inputSearch={inputSearch}
            client_name={client_name}
            property_name={property_name}
            cultivation_name={cultivation_name}
            cultivationVarieties_name={cultivationVarieties_name}
            setShowSearchCultivationVarietiesModal={
              setShowSearchCultivationVarietiesModal
            }
            setShowEditHarvestModal={setShowEditHarvestModal}
            setShowDeleteHarvestModal={setShowDeleteHarvestModal}
          />
        )}
      </Container>
    </Container>
  );
}

export default Harvest;
