import React, { useState } from "react";

import { Container, Modal, Button, Form, Col } from "react-bootstrap";

import { toast } from "react-toastify";
import { FaPlusCircle, FaToggleOff, FaToggleOn } from "react-icons/fa";

import { dataSchema } from "../../../functions/property";
import { validateBr, maskBr } from "js-brasil";

import { Images, getURLImage } from "../../images";

import { addProperty } from "../../../controllers/ControllerProperty";
import { consultCep } from "../../../controllers/ControllerCep";

function AddProperty(props) {
  const {
    showAddPropertyModal,
    setShowAddPropertyModal,
    propertyList,
    setPropertyList,
    client_id,
    client_name,
    code3c_id,
  } = props;

  const [name, setName] = useState("");
  const [socialID, setSocialID] = useState("");
  const [answerableName, setAnswerableName] = useState("");
  const [answerablePhone, setAnswerablePhone] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [neighborhood, setNeighborhood] = useState("");
  const [street, setStreet] = useState("");
  const [houseNumber, setHouseNumber] = useState("");
  const [guideScript, setGuideScript] = useState("");

  const [imageLight, setImageLight] = useState("");
  const [imageHeavy, setImageHeavy] = useState("");

  const [docType, setDocType] = useState(true);

  const [validated, setValidated] = useState(false);
  const [formErr, setFormErr] = useState([]);

  const [pendingRequest, setPendingRequest] = useState(false);

  function clearFields() {
    setFormErr([]);
    setName("");
    setSocialID("");
    setAnswerablePhone(" ");
    setAnswerableName("");
    setGuideScript("");
    setPostalCode("");
    setState("");
    setCity("");
    setNeighborhood("");
    setStreet("");
    setHouseNumber("");
    setImageLight("");
    setImageHeavy("");
    setPendingRequest(false);
    setValidated(false);
    setShowAddPropertyModal(false);
  }

  function handleClose() {
    clearFields();
  }

  async function getAddress(CEP) {
    setPostalCode(CEP);
    if (CEP.replace("-", "").length === 8) {
      const AddressInfo = await consultCep(CEP);
      setPostalCode(AddressInfo.cep);
      setState(AddressInfo.uf);
      setCity(AddressInfo.localidade);
      setNeighborhood(AddressInfo.bairro);
      setStreet(AddressInfo.logradouro);
    }
  }

  function formatPhone(Phone) {
    setAnswerablePhone(Phone);
    if (validateBr.telefone(Phone) && Phone.length >= 10) {
      setAnswerablePhone(maskBr.telefone(Phone));
    } else if (!validateBr.telefone(Phone)) {
      setAnswerablePhone(Phone.replace(/[&\\#,+()$~%.'":*?<>{}-\s/]/g, ""));
    }
  }

  function changeDocType() {
    setSocialID("");
    setDocType(!docType);
  }

  function formatSocialID(CNPJ_CPF) {
    if (docType) {
      setSocialID(CNPJ_CPF);
      if (validateBr.cnpj(CNPJ_CPF) && CNPJ_CPF.length === 14) {
        setSocialID(maskBr.cnpj(CNPJ_CPF));
      }
    } else {
      setSocialID(CNPJ_CPF);
      if (validateBr.cpf(CNPJ_CPF) && CNPJ_CPF.length === 11) {
        setSocialID(maskBr.cpf(CNPJ_CPF));
      }
    }
  }

  async function handleAddProperty() {
    if (imageHeavy !== "") {
      const urlImage = await getURLImage(
        imageHeavy,
        imageLight,
        code3c_id,
        "Property",
        false,
      );
      const data = {
        code3c_id,
        client_id,
        client_name,
        name,
        socialID: socialID.replace(/[&\\#,+()$~%.'":*?<>{}-\s/]/g, ""),
        answerableName,
        answerablePhone: answerablePhone.replace(
          /[&\\#,+()$~%.'":*?<>{}-\s/]/g,
          "",
        ),
        address: {
          postalCode,
          state,
          city,
          neighborhood,
          street,
          houseNumber,
        },
        guideScript,
        imageHeavy: urlImage,
        imageLight: urlImage,
      };

      dataSchema
        .validate(data, { abortEarly: false, context: { docType: docType } })
        .then(async function () {
          setFormErr([]);
          setValidated(true);
          const response = await addProperty(data);
          setPropertyList([...propertyList, { id: response, ...data }]);
          clearFields();
        })
        .catch(function (err) {
          setPendingRequest(false);
          setFormErr(err.inner);
          toast.error("Existem campos com erros.");
        });
    } else {
      setPendingRequest(false);
      toast.error("Insira uma imagem!");
    }
  }

  function catchErrors(errorField) {
    return (
      <Form.Control.Feedback type="invalid">
        {formErr.map((err) => {
          if (err.path === errorField) {
            return <p>{err.errors}</p>;
          }
          return null;
        })}
      </Form.Control.Feedback>
    );
  }

  function catchInvalid(errorField) {
    if (formErr.filter((err) => err.path === errorField).length >= 1) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <Container className="ubuntuFont">
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        className="ubuntuFont"
        show={showAddPropertyModal}
        onHide={handleClose}
        centered
      >
        <Modal.Header className="text-center" closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-center"
          >
            <FaPlusCircle className="text-success" />
            &emsp;Nova Propriedade
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated}>
            <Form.Group>
              <Form.Label>Nome da Propriedade</Form.Label>
              <Form.Control
                required
                isInvalid={catchInvalid("name")}
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value.toUpperCase())}
              />
              {catchErrors("name")}
            </Form.Group>
            <Form.Group>
              <Form.Label>
                {docType ? (
                  <>
                    <h4>
                      <FaToggleOff
                        className="text-system link"
                        onClick={() => changeDocType()}
                      />
                      &nbsp;CNPJ
                    </h4>
                  </>
                ) : (
                  <>
                    <h4>
                      <FaToggleOn
                        className="text-system link"
                        onClick={() => changeDocType()}
                      />
                      &nbsp;CPF
                    </h4>
                  </>
                )}
              </Form.Label>
              <Form.Control
                type="text"
                value={socialID}
                isInvalid={catchInvalid("socialID")}
                maxLength={docType ? "18" : "11"}
                onChange={(e) => formatSocialID(e.target.value)}
              />
              {catchErrors("socialID")}
            </Form.Group>
            <Form.Row>
              <Form.Group as={Col} md={6}>
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  type="text"
                  isInvalid={catchInvalid("answerableName")}
                  value={answerableName}
                  onChange={(e) => setAnswerableName(e.target.value)}
                />
                {catchErrors("answerableName")}
              </Form.Group>
              <Form.Group as={Col} md="6">
                <Form.Label>Telefone do Resposável</Form.Label>
                <Form.Control
                  type="text"
                  isInvalid={catchInvalid("answerablePhone")}
                  value={answerablePhone}
                  onChange={(e) => formatPhone(e.target.value)}
                />
                {catchErrors("answerablePhone")}
              </Form.Group>
            </Form.Row>
            <Form.Text className="text-muted text-center mb-3">
              Informações de Contato.
            </Form.Text>
            <Form.Text className="text-muted text-center mb-3">
              Informações de Endereço da Propriedade.
            </Form.Text>
            <Form.Row>
              <Form.Group as={Col} md="6">
                <Form.Label>CEP</Form.Label>
                <Form.Control
                  type="text"
                  maxLength="9"
                  value={postalCode}
                  isInvalid={catchInvalid("address.postalCode")}
                  onChange={(e) => getAddress(e.target.value)}
                />
                {catchErrors("address.postalCode")}
              </Form.Group>
              <Form.Group as={Col} md="6">
                <Form.Label>Estado</Form.Label>
                <Form.Control
                  disabled
                  placeholder="Insira o CEP"
                  type="text"
                  value={state}
                  isInvalid={catchInvalid("address.state")}
                  onChange={(e) => setState(e.target.value)}
                />
                {catchErrors("address.state")}
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="6">
                <Form.Label>Cidade</Form.Label>
                <Form.Control
                  disabled
                  placeholder="Insira o CEP"
                  type="text"
                  value={city}
                  isInvalid={catchInvalid("address.city")}
                  onChange={(e) => setCity(e.target.value)}
                />
                {catchErrors("address.city")}
              </Form.Group>
              <Form.Group as={Col} md="6">
                <Form.Label>Bairro</Form.Label>
                <Form.Control
                  type="text"
                  value={neighborhood}
                  isInvalid={catchInvalid("address.neighborhood")}
                  onChange={(e) => setNeighborhood(e.target.value)}
                />
                {catchErrors("address.neighborhood")}
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="10">
                <Form.Label>Rua</Form.Label>
                <Form.Control
                  type="text"
                  value={street}
                  isInvalid={catchInvalid("address.street")}
                  onChange={(e) => setStreet(e.target.value)}
                />
                {catchErrors("address.street")}
              </Form.Group>
              <Form.Group as={Col} md="2">
                <Form.Label>Nº</Form.Label>
                <Form.Control
                  type="text"
                  value={houseNumber}
                  isInvalid={catchInvalid("address.houseNumber")}
                  onChange={(e) => setHouseNumber(e.target.value)}
                />
                {catchErrors("address.houseNumber")}
              </Form.Group>
            </Form.Row>
            <Form.Group>
              <Form.Label>Roteiro</Form.Label>
              <Form.Control
                placeholder="Insira o Roteiro"
                as="textarea"
                rows="3"
                value={guideScript}
                isInvalid={catchInvalid("guideScript")}
                onChange={(e) => setGuideScript(e.target.value)}
              />
              {catchErrors("guideScript")}
            </Form.Group>
            <Images
              imageHeavy={imageHeavy}
              setImageHeavy={setImageHeavy}
              setImageLight={setImageLight}
              catchInvalid={catchInvalid}
              catchErrors={catchErrors}
            />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={pendingRequest ? true : false}
            variant="success"
            className="mr-2"
            onClick={() => handleAddProperty() + setPendingRequest(true)}
          >
            Salvar
          </Button>
          <Button variant="danger" onClick={() => handleClose()}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default AddProperty;
