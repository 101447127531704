import React, { useState, useEffect } from "react";

import { Container, Modal, Button, Form, InputGroup } from "react-bootstrap";

import { toast } from "react-toastify";
import { FaEdit, FaEye, FaEyeSlash } from "react-icons/fa";

import {
  parseUserFunction,
  dataSchema,
  dataEditSchema,
} from "../../../functions/user";

import { searchUser, editUser } from "../../../controllers/ControllerUser";

function EditUser(props) {
  const { showEditUserModal, setShowEditUserModal, getData, code3c_id, token } =
    props;

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [userFunction, setUserFunction] = useState("administrator");
  const [password, setPassword] = useState("");
  const [passwordValidation, setPasswordValidation] = useState("");

  const [functionOption, setFunctionOption] = useState([
    "administrator",
    "visitor",
  ]);
  const [changePassword, setChangePassword] = useState(false);
  const [seePass, setSeePass] = useState(false);

  const [validated, setValidated] = useState(false);
  const [formErr, setFormErr] = useState([]);

  const [pendingRequest, setPendingRequest] = useState(false);

  function handleChangePassword() {
    setValidated(false);
    setFormErr([]);
    setChangePassword(!changePassword);
  }

  function handleUserFunction(item) {
    setUserFunction(item.target.value);
  }

  function clearFields() {
    setFormErr([]);
    setName("");
    setEmail("");
    setUserFunction("");
    setValidated(false);
    setPassword("");
    setPasswordValidation("");
    setChangePassword(false);
    setPendingRequest(false);
    setSeePass(false);
    setShowEditUserModal({ show: false });
  }

  function handleClose() {
    clearFields();
  }

  async function handleEditUser() {
    const systemOnDB = await searchUser(showEditUserModal.id);
    const filterSystem = systemOnDB.systems;
    if (changePassword) {
      const dataValidation = {
        code3c_id,
        name,
        email,
        password,
        passwordValidation,
        systems: filterSystem.map((system) => {
          if (system.systemId === "FAGRO") {
            return { ...system, function: userFunction };
          } else {
            return system;
          }
        }),
      };

      dataSchema
        .validate(dataValidation, { abortEarly: false })
        .then(async function () {
          setFormErr([]);
          setValidated(true);
          const data = {
            code3c_id: dataValidation.code3c_id,
            name: dataValidation.name,
            email: dataValidation.email,
            password: dataValidation.password,
            systems: dataValidation.systems,
          };
          const response = await editUser(data, showEditUserModal.id, token);
          if (response) {
            getData(code3c_id);
            clearFields();
            setFormErr([]);
          }
        })
        .catch(function (err) {
          setFormErr(err.inner);
          console.log(err);
          setPendingRequest(false);
          toast.error("Existem campos com erros.");
        });
    } else {
      const data = {
        code3c_id,
        name,
        email,
        systems: filterSystem.map((system) => {
          if (system.systemId === "FAGRO") {
            return { ...system, function: userFunction };
          } else {
            return system;
          }
        }),
      };

      dataEditSchema
        .validate(data, { abortEarly: false })
        .then(async function () {
          setValidated(true);
          const response = await editUser(data, showEditUserModal.id, token);
          if (response) {
            getData(code3c_id);
            clearFields();
            setFormErr([]);
          }
        })
        .catch(function (err) {
          setFormErr(err.inner);
          setPendingRequest(false);
          toast.error("Existem campos com erros.");
        });
    }
  }

  function catchErrors(errorField) {
    return (
      <Form.Control.Feedback type="invalid">
        {formErr.map((err) => {
          if (err.path === errorField) {
            return <p>{err.errors}</p>;
          }
          return null;
        })}
      </Form.Control.Feedback>
    );
  }

  function catchInvalid(errorField) {
    if (formErr.filter((err) => err.path === errorField).length >= 1) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    if (showEditUserModal.name) {
      setName(showEditUserModal.name);
      setEmail(showEditUserModal.email);
      const functionNameOnDB = showEditUserModal.systems.function;
      setFunctionOption(
        functionOption.sort((functionName) => {
          if (functionName === functionNameOnDB) {
            return -1;
          } else {
            return 1;
          }
        })
      );
      setUserFunction(showEditUserModal.systems.function);
    }
    // eslint-disable-next-line
  }, [showEditUserModal]);

  return (
    <Container className="ubuntuFont">
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        className="ubuntuFont"
        show={showEditUserModal.show}
        onHide={handleClose}
        centered
      >
        <Modal.Header className="text-center" closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-center"
          >
            <FaEdit className="text-primary" />
            &emsp;Editar Produtor : {showEditUserModal.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated}>
            <Form.Group>
              <Form.Label>Nome do Usuário</Form.Label>
              <Form.Control
                required
                isInvalid={catchInvalid("name")}
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {catchErrors("name")}
            </Form.Group>
            <Form.Text className="text-muted text-center mb-3">
              Informações de Login.
            </Form.Text>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                isInvalid={catchInvalid("email")}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {catchErrors("email")}
            </Form.Group>
            <Form.Text className="text-muted text-center mb-3">
              Informações de Usuário.
            </Form.Text>
            <Form.Check
              type="checkbox"
              label="Alterar senha"
              className="mt-2 mb-3"
              id="customChangePassword"
              onChange={() => handleChangePassword()}
              custom
            />
            <Form.Group>
              <Form.Label>Senha do Usuário</Form.Label>
              <InputGroup>
                <Form.Control
                  type={seePass ? "text" : "password"}
                  disabled={changePassword ? false : true}
                  placeholder="Sua senha"
                  className="shadow-none"
                  isInvalid={catchInvalid("password")}
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />
                <InputGroup.Prepend>
                  <InputGroup.Text
                    id="inputGroupPrepend"
                    className="rounded-right link"
                    onClick={() => setSeePass(!seePass)}
                  >
                    {seePass ? <FaEyeSlash /> : <FaEye />}
                  </InputGroup.Text>
                </InputGroup.Prepend>
                {catchErrors("password")}
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <Form.Label>Confirme a Senha do Usuário</Form.Label>
              <InputGroup>
                <Form.Control
                  type={seePass ? "text" : "password"}
                  disabled={changePassword ? false : true}
                  placeholder="Sua senha"
                  className="shadow-none"
                  isInvalid={catchInvalid("passwordValidation")}
                  onChange={(e) => setPasswordValidation(e.target.value)}
                  value={passwordValidation}
                />
                <InputGroup.Prepend>
                  <InputGroup.Text
                    id="inputGroupPrepend"
                    className="rounded-right link"
                    onClick={() => setSeePass(!seePass)}
                  >
                    {seePass ? <FaEyeSlash /> : <FaEye />}
                  </InputGroup.Text>
                </InputGroup.Prepend>
                {catchErrors("passwordValidation")}
              </InputGroup>
              <Form.Text className="text-muted">
                As senhas precisam ser iguais e ter mais que 8 caracteres.
              </Form.Text>
            </Form.Group>
            <Form.Group>
              <Form.Label>Tipo de usuário</Form.Label>
              <Form.Control as="select" onClick={handleUserFunction.bind(this)}>
                {functionOption.map((functionName, index) => (
                  <option value={functionName} key={index}>
                    {parseUserFunction(functionName)}
                  </option>
                ))}
              </Form.Control>
              <Form.Text className="text-muted">
                Administrador ou Visitante.
              </Form.Text>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={pendingRequest ? true : false}
            variant="success"
            className="mr-2"
            onClick={() => handleEditUser() + setPendingRequest(true)}
          >
            Salvar
          </Button>
          <Button variant="danger" onClick={() => handleClose()}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default EditUser;
