import React, { useState, useEffect } from "react";

import { Container, Modal, Button, Form } from "react-bootstrap";

import { toast } from "react-toastify";
import { FaEdit } from "react-icons/fa";

import { dataSchema } from "../../../functions/field";

import {
  editField,
  searchEditFieldNumber,
} from "../../../controllers/ControllerField";

function EditField(props) {
  const {
    showEditFieldModal,
    setShowEditFieldModal,
    getData,
    code3c_id,
    client_id,
    client_name,
    property_id,
    property_name,
    fieldNumberList,
  } = props;

  const [name, setName] = useState("");

  const [fieldNumber, setFieldNumber] = useState(0);
  const [fieldArea, setFieldArea] = useState(0);

  const [validated, setValidated] = useState(false);
  const [formErr, setFormErr] = useState([]);

  const [pendingRequest, setPendingRequest] = useState(false);

  const [staticData, setStaticData] = useState({});

  function clearFields() {
    setFormErr([]);
    setName("");
    setFieldNumber(0);
    setFieldArea("");
    setValidated(false);
    setPendingRequest(false);
    setShowEditFieldModal({ show: false });
  }

  function handleClose() {
    clearFields();
  }

  async function handleEditField() {
    const data = {
      code3c_id,
      client_id,
      client_name,
      property_id,
      property_name,
      name,
      fieldNumber: parseInt(fieldNumber),
      fieldArea: parseFloat(fieldArea),
    };

    dataSchema
      .validate(data, {
        abortEarly: false,
        context: {
          fieldNumberList: fieldNumberList,
          staticData: staticData,
          fieldNumber: fieldNumber,
        },
      })
      .then(async function () {
        if (JSON.stringify(staticData) !== JSON.stringify(data)) {
          const exists = await searchEditFieldNumber(
            code3c_id,
            data.fieldNumber,
            showEditFieldModal.id,
          );
          if (!exists) {
            const response = await editField(data, showEditFieldModal.id);
            if (response) {
              getData(code3c_id);
              clearFields();
              setFormErr([]);
              setValidated(true);
            }
          } else {
            setFormErr([]);
            setPendingRequest(false);
            toast.warn("Esse número de Talhão já possui cadastro!");
          }
        } else {
          clearFields();
        }
      })
      .catch(function (err) {
        setFormErr(err.inner);
        setPendingRequest(false);
        toast.error("Existem campos com erros.");
      });
  }

  function catchErrors(errorField) {
    return (
      <Form.Control.Feedback type="invalid">
        {formErr.map((err) => {
          if (err.path === errorField) {
            return <p>{err.errors}</p>;
          }
          return null;
        })}
      </Form.Control.Feedback>
    );
  }

  function catchInvalid(errorField) {
    if (formErr.filter((err) => err.path === errorField).length >= 1) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    if (showEditFieldModal.name) {
      setName(showEditFieldModal.name);
      setFieldNumber(showEditFieldModal.fieldNumber);
      setFieldArea(showEditFieldModal.fieldArea);
      setStaticData({
        code3c_id: code3c_id,
        client_id: client_id,
        client_name: client_name,
        property_id: property_id,
        property_name: property_name,
        name: showEditFieldModal.name,
        fieldNumber: showEditFieldModal.fieldNumber,
        fieldArea: showEditFieldModal.fieldArea,
      });
    }
    // eslint-disable-next-line
  }, [showEditFieldModal]);

  return (
    <Container className="ubuntuFont">
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        className="ubuntuFont"
        show={showEditFieldModal.show}
        onHide={handleClose}
        centered
      >
        <Modal.Header className="text-center" closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-center"
          >
            <FaEdit className="text-primary" />
            &emsp;Editar Propriedade : {showEditFieldModal.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated}>
            <Form.Group>
              <Form.Label>Nome do Talhão</Form.Label>
              <Form.Control
                required
                isInvalid={catchInvalid("name")}
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value.toUpperCase())}
              />
              {catchErrors("name")}
            </Form.Group>
            <Form.Group>
              <Form.Label>Número do Talhão</Form.Label>
              <Form.Control
                required
                isInvalid={catchInvalid("fieldNumber")}
                type="number"
                value={fieldNumber}
                onChange={(e) => setFieldNumber(e.target.value)}
              />
              {catchErrors("fieldNumber")}
            </Form.Group>
            <Form.Group>
              <Form.Label>Área do Talhão (ha)</Form.Label>
              <Form.Control
                required
                isInvalid={catchInvalid("fieldArea")}
                type="number"
                value={fieldArea}
                onChange={(e) => setFieldArea(e.target.value)}
              />
              <Form.Text className="text-muted">
                Insira a área do seu talhão em ha (hectare), precisa ser maior
                que 0 (zero).
              </Form.Text>
              {catchErrors("fieldArea")}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={pendingRequest ? true : false}
            variant="success"
            className="mr-2"
            onClick={() => handleEditField() + setPendingRequest(true)}
          >
            Salvar
          </Button>
          <Button variant="danger" onClick={() => handleClose()}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default EditField;
