import React from "react";

import { Table, Button } from "react-bootstrap";

import { parseUserFunction } from "../../../functions/user";

function TableUser(props) {
  const {
    userList,
    inputSearch,
    setShowEditUserModal,
    setShowDeleteUserModal,
    accountsNumber,
  } = props;
  return (
    <>
      <Table striped bordered hover size="sm" className="rounded">
        <thead>
          <tr>
            <th width="2%">#</th>
            <th width="23%">Usuário</th>
            <th width="35%" className="text-center">
              Email
            </th>
            <th width="23%">Tipo</th>
            <th width="10%" className="text-center">
              Editar
            </th>
            <th width="10%" className="text-center">
              Apagar
            </th>
          </tr>
        </thead>
        <tbody>
          {userList
            .sort(function (a, b) {
              var nameA = a.name.toLowerCase(),
                nameB = b.name.toLowerCase();
              if (nameA < nameB) return -1;
              if (nameA > nameB) return 1;
              return 0;
            })
            .filter(
              (item) =>
                item.id.indexOf(inputSearch) !== -1 ||
                item.name.toUpperCase().indexOf(inputSearch.toUpperCase()) !==
                  -1,
            )
            .map((user, index) => (
              <tr key={index}>
                <td width="2%" className="align-middle">
                  <b>{index + 1}</b>
                </td>
                <td width="23%" className="align-middle">
                  {user.name}
                </td>
                <td width="35%" className="text-center  align-middle">
                  {user.email}
                </td>
                <td width="20%" className="text-center  align-middle">
                  {parseUserFunction(user.systems.function)}
                </td>
                <td width="10%" className="text-center align-middle">
                  <Button
                    className="bg-system"
                    size="sm"
                    onClick={() =>
                      setShowEditUserModal({
                        id: user.id,
                        name: user.name,
                        email: user.email,
                        systems: user.systems,
                        show: true,
                      })
                    }
                  >
                    Editar
                  </Button>
                </td>
                <td width="10%" className="text-center align-middle">
                  <Button
                    variant="outline-danger"
                    size="sm"
                    onClick={() =>
                      setShowDeleteUserModal({
                        id: user.id,
                        name: user.name,
                        show: true,
                      })
                    }
                  >
                    Apagar
                  </Button>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      {accountsNumber <= userList.length && (
        <p className="text-system text-center">
          Você alcançou o número máximo de contas, para cadastrar mais contas
          entre em contato com{" "}
          <a
            href="https://canshogroup.com.br"
            target="_blank"
            rel="noopener noreferrer"
          >
            Cansho Group&reg;
          </a>
        </p>
      )}
    </>
  );
}

export default TableUser;
