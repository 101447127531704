import React, { useEffect, useState } from "react";

import { Container, Navbar, Form } from "react-bootstrap";

import { BsCircleFill } from "react-icons/bs";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import ptBrLocale from "@fullcalendar/core/locales/pt-br";

import "moment/locale/pt-br";

function CalendarVisit(props) {
  const [toggleFinished, setToggleFinished] = useState("");
  const { visitEvents, setShowAddVisitModal, setShowEditVisitModal } = props;
  useEffect(() => {}, []);

  function handleTypeFilter(item) {
    setToggleFinished(item.target.value);
  }

  return (
    <Container className="px-4" style={{ height: "350px", overflow: "auto" }}>
      <Navbar
        bg="white"
        variant="white"
        className="justify-content-between navcalendar"
      >
        <div className="my-1">
          <div style={{ float: "right" }}>
            <BsCircleFill style={{ color: "rgb(110, 110, 110)" }} />
            <div className="mx-2" style={{ float: "right" }}>
              Visita Não Finalizada
            </div>
          </div>

          <div style={{ float: "right" }}>
            <BsCircleFill style={{ color: "rgb(111, 145, 111)" }} />
            <div className="mx-2" style={{ float: "right" }}>
              Visita Finalizada
            </div>
          </div>
        </div>
        <Form inline className="justify-content-end my-1">
          <Form.Control as="select" onClick={handleTypeFilter.bind(this)}>
            <option value={""}>Todas as Visitas</option>
            <option value={"nFinished"}>Visitas Não Finalizadas</option>
          </Form.Control>
        </Form>
      </Navbar>

      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        dayMaxEvents={2}
        initialView="dayGridMonth"
        height="auto"
        locale={ptBrLocale}
        events={visitEvents.filter((item) => {
          if (!item.endVisit && toggleFinished === "nFinished") {
            return true;
          } else if (toggleFinished === "") {
            return true;
          } else {
            return null;
          }
        })}
        eventDisplay="block"
        dateClick={function (info) {
          setShowAddVisitModal({ visitDate: info.dateStr, show: true });
        }}
        eventClick={function (info) {
          setShowEditVisitModal({
            id: info.event.id,
            visitDate: info.event.extendedProps.visitDate.toDate(),
            client_id: info.event.extendedProps.client_id,
            client_name: info.event.extendedProps.client_name,
            property_id: info.event.extendedProps.property_id,
            property_name: info.event.extendedProps.property_name,
            property_imageLight: info.event.extendedProps.property_imageLight,
            property_imageHeavy: info.event.extendedProps.property_imageHeavy,
            user_id: info.event.extendedProps.user_id,
            user_name: info.event.extendedProps.user_name,
            show: true,
          });
        }}
      />
    </Container>
  );
}

export default CalendarVisit;
