import React, { useState, useEffect } from "react";

import { Table, Button, Spinner, Row } from "react-bootstrap";

import { getVisitor } from "../../../controllers/ControllerUser";

function TableSearchUser(props) {
  const {
    code3c_id,
    client_id,
    property_id,
    user_id,
    inputSearch,
    handleUser,
    clearUser,
    showSearchUserVarietiesModal,
  } = props;

  const [userList, setUserList] = useState([]);
  const [pending, setPending] = useState(false);

  useEffect(() => {
    async function get3cUsers() {
      const response = await getVisitor(code3c_id);
      if (response) {
        setUserList(response);
        setPending(true);
      }
    }
    get3cUsers();
    // eslint-disable-next-line
  }, [showSearchUserVarietiesModal, property_id]);

  return userList.length === 0 && !pending ? (
    <Row>
      <Spinner className="mx-auto my-5 text-system" animation="border" />
    </Row>
  ) : (
    <Table striped bordered hover size="sm" className="rounded">
      {client_id !== "" && (
        <thead>
          <tr>
            <th width="100%" colSpan="3" className="align-middle text-center">
              <Button
                variant="outline-danger"
                size="sm"
                onClick={() => clearUser()}
              >
                Voltar
              </Button>
            </th>
          </tr>
        </thead>
      )}
      <thead>
        <tr>
          <th width="2%">#</th>
          <th width="88%">Visitante</th>
          <th width="10%" className="text-center">
            Selecionar
          </th>
        </tr>
      </thead>
      <tbody>
        {userList
          .sort(function (a, b) {
            var nameA = a.name.toLowerCase(),
              nameB = b.name.toLowerCase();
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
          })
          .filter(
            (item) =>
              item.id.indexOf(inputSearch) !== -1 ||
              item.name.toUpperCase().indexOf(inputSearch.toUpperCase()) !== -1,
          )
          .map((user, index) => (
            <tr key={index}>
              <td width="2%" className="align-middle">
                <b>{index + 1}</b>
              </td>
              <td width="63%" className="align-middle">
                {user.name}
              </td>
              <td width="10%" className="text-center align-middle">
                <Button
                  disabled={user_id === user.id ? true : false}
                  className="bg-system"
                  size="sm"
                  onClick={() => handleUser(user.id, user.name, user.playerIDs)}
                >
                  Selecionar
                </Button>
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
}

export default TableSearchUser;
