import React, { useEffect, useState, useContext } from "react";

import {
  Container,
  Row,
  Spinner,
  Navbar,
  Button,
  Form,
  FormControl,
} from "react-bootstrap";

import { FaPlusCircle } from "react-icons/fa";

import TableField from "../../components/field/pageComponents/tableField";

import AddField from "../../components/field/pageModals/addField";
import EditField from "../../components/field/pageModals/editField";
import DeleteField from "../../components/field/pageModals/deleteField";

import SearchProperty from "../../components/field/pageModals/searchProperty";

import { getFields } from "../../controllers/ControllerField";

import { AuthContext } from "../../configurations/authorization";

function Field() {
  const { code3cData } = useContext(AuthContext);

  const [fieldList, setFieldList] = useState([]);
  const [fieldNumberList, setFieldNumberList] = useState([]);

  const [inputSearch, setInputSearch] = useState("");

  const [toggleSpinner, setToggleSpinner] = useState(false);

  const [client_id, setClient_id] = useState("");
  const [client_name, setClient_name] = useState("");
  const [property_id, setProperty_id] = useState("");
  const [property_name, setProperty_name] = useState("");

  const [showSearchPropertyModal, setShowSearchPropertyModal] = useState(false);

  const [showAddFieldModal, setShowAddFieldModal] = useState(false);
  const [showEditFieldModal, setShowEditFieldModal] = useState({ show: false });
  const [showDeleteFieldModal, setShowDeleteFieldModal] = useState({
    show: false,
  });

  async function getData() {
    if (property_id !== "") {
      const dataResponse = await getFields(property_id);
      if (dataResponse) {
        setFieldList(dataResponse);
        setToggleSpinner(true);
        setFieldNumberList([
          ...new Set(dataResponse.map((item) => item.fieldNumber)),
        ]);
      }
    } else {
      setFieldList([]);
      setToggleSpinner(true);
    }
  }

  async function handleClient(client_id, client_name) {
    setClient_id(client_id);
    setClient_name(client_name);
  }

  function clearClient() {
    setClient_id("");
    setClient_name("");
    setProperty_id("");
    setProperty_name("");
  }

  async function handleProperty(property_id, property_name) {
    setProperty_id(property_id);
    setProperty_name(property_name);
    setShowSearchPropertyModal(false);
  }

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [property_id]);

  return (
    <Container
      className="rounded bg-white my-3 pb-2 px-0 ubuntuFont"
      style={{ height: " 475.4px" }}
    >
      <AddField
        showAddFieldModal={showAddFieldModal}
        setShowAddFieldModal={setShowAddFieldModal}
        fieldList={fieldList}
        setFieldList={setFieldList}
        code3c_id={code3cData.id}
        client_id={client_id}
        client_name={client_name}
        property_id={property_id}
        property_name={property_name}
        fieldNumberList={fieldNumberList}
        getData={getData}
      />
      <EditField
        showEditFieldModal={showEditFieldModal}
        setShowEditFieldModal={setShowEditFieldModal}
        getData={getData}
        code3c_id={code3cData.id}
        client_id={client_id}
        client_name={client_name}
        property_id={property_id}
        property_name={property_name}
        fieldNumberList={fieldNumberList}
      />
      <DeleteField
        showDeleteFieldModal={showDeleteFieldModal}
        setShowDeleteFieldModal={setShowDeleteFieldModal}
        getData={getData}
        code3c_id={code3cData.id}
      />
      <SearchProperty
        showSearchPropertyModal={showSearchPropertyModal}
        setShowSearchPropertyModal={setShowSearchPropertyModal}
        handleClient={handleClient}
        clearClient={clearClient}
        handleProperty={handleProperty}
        client_id={client_id}
        property_id={property_id}
        code3c_id={code3cData.id}
      />

      <h2 className="text-center py-3">TALHÕES</h2>
      <hr className="mt-0" />
      <Navbar
        bg="white"
        variant="white"
        className="mb-3 justify-content-between"
      >
        <Navbar.Brand className="link">
          <Button
            variant="outline-success"
            disabled={property_id === "" ? true : false}
            onClick={() => {
              setShowAddFieldModal(!showAddFieldModal);
            }}
          >
            <FaPlusCircle className="text-outline-success" />
            &nbsp; Novo Talhão
          </Button>
        </Navbar.Brand>
        <Form inline className="justify-content-end">
          <FormControl
            type="text"
            placeholder="Pesquisar talhão"
            className="mr-sm-2 justify-content-end"
            value={inputSearch}
            onChange={(e) => setInputSearch(e.target.value)}
          />
        </Form>
      </Navbar>
      <Container className="table-max-height">
        {fieldList === 0 && !toggleSpinner ? (
          <Row>
            <Spinner className="mx-auto my-5 text-system" animation="border" />
          </Row>
        ) : (
          <TableField
            fieldList={fieldList}
            inputSearch={inputSearch}
            client_name={client_name}
            property_name={property_name}
            setShowSearchPropertyModal={setShowSearchPropertyModal}
            setShowEditFieldModal={setShowEditFieldModal}
            setShowDeleteFieldModal={setShowDeleteFieldModal}
            toggleSpinner={toggleSpinner}
          />
        )}
      </Container>
    </Container>
  );
}

export default Field;
