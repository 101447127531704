import React, { useEffect, useState, useContext } from "react";

import {
  Container,
  Row,
  Spinner,
  Navbar,
  Button,
  Form,
  FormControl,
} from "react-bootstrap";

import { FaPlusCircle } from "react-icons/fa";

import TableClient from "../../components/client/pageComponents/tableClient";

import AddClient from "../../components/client/pageModals/addClient";
import EditClient from "../../components/client/pageModals/editClient";
import DeleteClient from "../../components/client/pageModals/deleteClient";

import { getClients } from "../../controllers/ControllerClient";

import { AuthContext } from "../../configurations/authorization";

function Client() {
  const { code3cData } = useContext(AuthContext);

  const [clientList, setClientList] = useState([]);

  const [inputSearch, setInputSearch] = useState("");

  const [toggleSpinner, setToggleSpinner] = useState(false);

  const [showAddClientModal, setShowAddClientModal] = useState(false);
  const [showEditClientModal, setShowEditClientModal] = useState({
    show: false,
  });
  const [showDeleteClientModal, setShowDeleteClientModal] = useState({
    show: false,
  });

  async function getData() {
    const dataResponse = await getClients(code3cData.id);
    if (dataResponse) {
      setClientList(dataResponse);
      setToggleSpinner(true);
    }
  }

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  return (
    <Container
      className="rounded bg-white my-3 pb-2 px-0 ubuntuFont"
      style={{ height: " 475.4px" }}
    >
      <AddClient
        showAddClientModal={showAddClientModal}
        setShowAddClientModal={setShowAddClientModal}
        clientList={clientList}
        setClientList={setClientList}
        code3c_id={code3cData.id}
      />
      <EditClient
        showEditClientModal={showEditClientModal}
        setShowEditClientModal={setShowEditClientModal}
        getData={getData}
        code3c_id={code3cData.id}
      />
      <DeleteClient
        showDeleteClientModal={showDeleteClientModal}
        setShowDeleteClientModal={setShowDeleteClientModal}
        getData={getData}
        code3c_id={code3cData.id}
      />

      <h2 className="text-center py-3">PRODUTORES</h2>
      <hr className="mt-0" />
      <Navbar
        bg="white"
        variant="white"
        className="mb-3 justify-content-between"
      >
        <Navbar.Brand className="link">
          <Button
            variant="outline-success"
            onClick={() => {
              setShowAddClientModal(!showAddClientModal);
            }}
          >
            <FaPlusCircle className="text-outline-success" />
            &nbsp; Novo Produtor
          </Button>
        </Navbar.Brand>
        <Form inline className="justify-content-end">
          <FormControl
            type="text"
            placeholder="Pesquisar produtor"
            className="mr-sm-2 justify-content-end"
            value={inputSearch}
            onChange={(e) => setInputSearch(e.target.value)}
          />
        </Form>
      </Navbar>
      <Container className="table-max-height">
        {clientList === 0 && !toggleSpinner ? (
          <Row>
            <Spinner className="mx-auto my-5 text-system" animation="border" />
          </Row>
        ) : (
          <TableClient
            clientList={clientList}
            inputSearch={inputSearch}
            setShowEditClientModal={setShowEditClientModal}
            setShowDeleteClientModal={setShowDeleteClientModal}
            toggleSpinner={toggleSpinner}
          />
        )}
      </Container>
    </Container>
  );
}

export default Client;
