import React from "react";
import { FaExclamationCircle } from "react-icons/fa";
import { Popover, OverlayTrigger } from "react-bootstrap";

import moment from "moment";
import "moment/locale/pt-br";

export function BillingWarning(props) {
  const { systemDateBill } = props;

  const maxDate = moment(systemDateBill).add(15, "d").toDate();

  const popover = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">Aviso de suspensão!</Popover.Title>
      <Popover.Content>
        A manutenção está vencida! Seu acesso ao <b>FAGRO</b> será suspenso{" "}
        <b className="text-system">
          {moment(maxDate).fromNow().toUpperCase()}.
        </b>
      </Popover.Content>
    </Popover>
  );
  return (
    <OverlayTrigger trigger="click" placement="right" overlay={popover}>
      <FaExclamationCircle className="text-warning" />
    </OverlayTrigger>
  );
}
