import * as yup from "yup";
import { pt } from "yup-locale-pt";

import moment from "moment";
import "moment/locale/pt-br";

yup.setLocale(pt);

yup.addMethod(yup.date, "checkBefore", function (errorMessage) {
  return this.test(`visitDate`, errorMessage, function (value) {
    const { path, createError } = this;
    return (
      moment().isSameOrBefore(moment(value), "day") ||
      createError({ path, message: errorMessage })
    );
  });
});

const dataSchema = yup.object().shape({
  code3c_id: yup.string().min(1).required(),
  client_id: yup.string().min(1).required(),
  client_name: yup.string().min(1).required(),
  property_id: yup.string().min(1).required(),
  property_name: yup.string().min(1).required(),
  property_imageLight: yup.string().required(),
  property_imageHeavy: yup.string().required(),
  user_id: yup.string().min(1).required(),
  user_name: yup.string().min(1).required(),
  visitDate: yup
    .date()
    .checkBefore("A data não pode ser anterior a data de hoje.")
    .label("Data da Visita")
    .required(),
});

export { dataSchema };
