import React from "react";
import ReactDOM from "react-dom";

import "./styles/index.css";
import "./styles/systemColors.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";

import { ToastContainer } from "react-toastify";

import Routes from "./routes/routes";

ReactDOM.render(
  <>
    <Routes />
    <ToastContainer autoClose={3000} />
  </>,
  document.getElementById("root"),
);
