import React, { useState, useEffect } from "react";

import { Form } from "react-bootstrap";
import { toast } from "react-toastify";

import { firebaseStorage } from "../../configurations/firebaseConfig";

import Compress from "react-image-file-resizer";

import urlDefault from "../../assets/imgs/new-image.png";

function GeneratorUrl(photoName) {
  return `https://firebasestorage.googleapis.com/v0/b/erp-cansho.appspot.com/o/FAGRO%2F${photoName}?alt=media`;
}

async function getURLImage(
  imgBase64,
  imgOnStorage,
  code3c_id,
  collectionName,
  editImage
) {
  if (editImage) {
    if (imgBase64 !== imgOnStorage) {
      try {
        await firebaseStorage.ref(`FAGRO/${imgOnStorage}`).delete();
      } catch (err) {
        console.log(err);
      }
      const milsecDate = new Date();
      const name =
        collectionName + "-" + code3c_id + "-" + milsecDate.getTime() + ".jpg";
      const storageRef = firebaseStorage.ref(`FAGRO/${name}`);
      await storageRef.putString(imgBase64, "data_url");
      return name;
    } else {
      return imgOnStorage;
    }
  } else {
    const milsecDate = new Date();
    const name =
      collectionName + "-" + code3c_id + "-" + milsecDate.getTime() + ".jpg";
    const storageRef = firebaseStorage.ref(`FAGRO/${name}`);
    await storageRef.putString(imgBase64, "data_url");
    return name;
  }
}

function Images(props) {
  const { imageHeavy, setImageHeavy, catchInvalid, catchErrors } = props;

  const [preview, setPreview] = useState("");
  const [imageName, setImageName] = useState("Pesquise um arquivo");

  const _validFileExtensions = [".jpg", ".jpeg", ".bmp", ".gif", ".png"];

  useEffect(() => {
    if (imageHeavy !== "" && preview === "") {
      setPreview(GeneratorUrl(imageHeavy));
    }
  }, [imageHeavy, preview]);

  function ValidateSingleInput(oInput) {
    if (oInput.type === "file") {
      var sFileName = oInput.value;
      if (sFileName.length > 0) {
        var blnValid = false;
        for (var j = 0; j < _validFileExtensions.length; j++) {
          var sCurExtension = _validFileExtensions[j];
          if (
            sFileName
              .substr(
                sFileName.length - sCurExtension.length,
                sCurExtension.length
              )
              .toLowerCase() === sCurExtension.toLowerCase()
          ) {
            blnValid = true;
            break;
          }
        }
        if (!blnValid) {
          toast.error("O seu arquivo não é uma imagem válida!");
          oInput.value = "";
          return false;
        }
      }
    }
    return true;
  }

  function previewFile(file) {
    Compress.imageFileResizer(
      file, //The file to be resized.
      1000, // The Width of the new File
      1000, // The Heigth of the new File
      "JPEG", // The Compress Format: WEBP || JPEG || PNG
      80, // The Quality of the new File
      0, // The Rotation of the new File
      (uri) => {
        handleUpload(uri);
      },
      "base64" // The Returning Format: blob || base64
    );
  }

  async function handleChange(e) {
    try {
      if (ValidateSingleInput(e.target)) {
        await previewFile(e.target.files[0]);
        setImageName(e.target.files[0].name);
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  }

  function handleUpload(img) {
    setPreview(img);
    setImageHeavy(img);
  }

  return (
    <>
      <Form.Row className="mb-3">
        <img
          className="rounded mx-auto text-center"
          src={preview === "" ? urlDefault : preview}
          alt="Preview IMG"
          style={{ width: "100px", height: "100px" }}
        />
      </Form.Row>
      <Form.File custom>
        <Form.File.Input
          isValid={preview === "" ? false : true}
          isInvalid={catchInvalid("imageHeavy")}
          accept=".jpg,.jpeg,.bmp,.gif,.png"
          onChange={(e) => handleChange(e)}
        />
        <Form.File.Label data-browse="Selecionar Imagem">
          {imageName}
        </Form.File.Label>
        <Form.Control.Feedback type="valid">
          Sua imagem foi carregada!
        </Form.Control.Feedback>
        {catchErrors("imageHeavy")}
      </Form.File>
    </>
  );
}

function Gallery(props) {
  const { imageHeavy, setImageHeavy } = props;

  const [preview, setPreview] = useState("");
  const [imageName, setImageName] = useState("Selecione uma imagem");

  const _validFileExtensions = [".jpg", ".jpeg", ".bmp", ".gif", ".png"];

  useEffect(() => {
    if (imageHeavy !== "" && preview === "") {
      setPreview(GeneratorUrl(imageHeavy));
    }
  }, [imageHeavy, preview]);

  function ValidateSingleInput(oInput) {
    if (oInput.type === "file") {
      var sFileName = oInput.value;
      if (sFileName.length > 0) {
        var blnValid = false;
        for (var j = 0; j < _validFileExtensions.length; j++) {
          var sCurExtension = _validFileExtensions[j];
          if (
            sFileName
              .substr(
                sFileName.length - sCurExtension.length,
                sCurExtension.length
              )
              .toLowerCase() === sCurExtension.toLowerCase()
          ) {
            blnValid = true;
            break;
          }
        }
        if (!blnValid) {
          toast.error("O seu arquivo não é uma imagem válida!");
          oInput.value = "";
          return false;
        }
      }
    }
    return true;
  }

  function previewFile(file) {
    Compress.imageFileResizer(
      file, //The file to be resized.
      1000, // The Width of the new File
      1000, // The Heigth of the new File
      "JPEG", // The Compress Format: WEBP || JPEG || PNG
      80, // The Quality of the new File
      0, // The Rotation of the new File
      (uri) => {
        handleUpload(uri);
      },
      "base64" // The Returning Format: blob || base64
    );
  }

  async function handleChange(e) {
    try {
      if (ValidateSingleInput(e.target)) {
        await previewFile(e.target.files[0]);
        setImageName(e.target.files[0].name);
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  }

  function handleUpload(img) {
    setPreview(img);
    setImageHeavy(img);
  }

  return (
    <>
      <Form.Row className="mb-3">
        <img
          className="rounded mx-auto text-center"
          src={preview === "" ? urlDefault : preview}
          alt="Preview IMG"
          style={{ width: "100px", height: "100px" }}
        />
      </Form.Row>
      <Form.File custom>
        <Form.File.Input
          isValid={preview === "" ? false : true}
          accept=".jpg,.jpeg,.bmp,.gif,.png"
          onChange={(e) => handleChange(e)}
        />
        <Form.File.Label data-browse="Selecionar Imagem">
          {imageName}
        </Form.File.Label>
        <Form.Control.Feedback type="valid">
          Sua imagem foi carregada!
        </Form.Control.Feedback>
      </Form.File>
    </>
  );
}

export { Images, Gallery, getURLImage, GeneratorUrl };
