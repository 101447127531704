import React, { useState, useEffect } from "react";

import { Container, Modal, Button, Form } from "react-bootstrap";

import { toast } from "react-toastify";
import { FaPlusCircle } from "react-icons/fa";

import { dataSchema } from "../../../functions/visit";

import { agroVisitsPush } from "../../../services/notificationPush";

import { addVisit } from "../../../controllers/ControllerVisit";

import TableSearchClient from "../modalComponents/tableSearchClient";
import TableSearchProperty from "../modalComponents/tableSearchProperty";
import TableSearchUser from "../modalComponents/tableSearchUser";

import FormAddVisit from "../modalComponents/formAddVisit";

import moment from "moment";
import "moment/locale/pt-br";

function AddVisit(props) {
  const { showAddVisitModal, setShowAddVisitModal, code3c_id, getData } = props;

  const [visitDate, setVisitDate] = useState("");

  const [validated, setValidated] = useState(false);
  const [formErr, setFormErr] = useState([]);

  const [pendingRequest, setPendingRequest] = useState(false);

  const [inputSearch, setInputSearch] = useState("");

  const [client_id, setClient_id] = useState("");
  const [client_name, setClient_name] = useState("");
  const [property_id, setProperty_id] = useState("");
  const [property_name, setProperty_name] = useState("");
  const [property_imageLight, setProperty_imageLight] = useState("");
  const [property_imageHeavy, setProperty_imageHeavy] = useState("");
  const [user_id, setUser_id] = useState("");
  const [user_playerIDs, setUser_playerIDs] = useState([]);
  const [user_name, setUser_name] = useState("");

  async function handleClient(client_id, client_name) {
    setClient_id(client_id);
    setClient_name(client_name);
  }

  function clearClient() {
    setClient_id("");
    setClient_name("");
    setProperty_id("");
    setProperty_name("");
  }

  async function handleProperty(
    property_id,
    property_name,
    property_imageLight,
    property_imageHeavy,
  ) {
    setProperty_id(property_id);
    setProperty_name(property_name);
    setProperty_imageLight(property_imageLight);
    setProperty_imageHeavy(property_imageHeavy);
  }

  function clearProperty() {
    setProperty_id("");
    setProperty_name("");
    setProperty_imageLight("");
    setProperty_imageHeavy("");
    setUser_id("");
    setUser_name("");
  }

  async function handleUser(user_id, user_name, playerIDs) {
    setUser_id(user_id);
    setUser_name(user_name);
    setUser_playerIDs(playerIDs);
  }

  function clearUser() {
    setUser_id("");
    setUser_name("");
  }

  function clearFields() {
    setFormErr([]);
    setVisitDate("");
    setClient_id("");
    setClient_name("");
    setProperty_id("");
    setProperty_name("");
    setProperty_imageLight("");
    setProperty_imageHeavy("");
    setUser_id("");
    setUser_name("");
    setPendingRequest(false);
    setValidated(false);
    setShowAddVisitModal({ show: false });
  }

  function handleClose() {
    clearFields();
  }

  async function handleAddVisit() {
    const data = {
      code3c_id,
      client_id,
      client_name,
      property_id,
      property_name,
      property_imageLight,
      property_imageHeavy,
      user_id,
      user_name,
      visitDate: visitDate === "" ? "" : new Date(moment(visitDate)),
    };

    dataSchema
      .validate(data, { abortEarly: false })
      .then(async function () {
        setFormErr([]);
        setValidated(true);
        const response = await addVisit(data);
        if (response) {
          getData();
          await agroVisitsPush(
            {
              ...data,
              visitDate: moment(data.visitDate).format("DD/MM/YYYY"),
              id: response.id,
            },
            user_playerIDs,
          );
        }
        clearFields();
      })
      .catch(function (err) {
        console.log(err.inner);
        setPendingRequest(false);
        setFormErr(err.inner);
        toast.error("Existem campos com erros.");
      });
  }

  function catchErrors(errorVisit) {
    return (
      <Form.Control.Feedback type="invalid">
        {formErr.map((err) => {
          if (err.path === errorVisit) {
            return <p>{err.errors}</p>;
          }
          return null;
        })}
      </Form.Control.Feedback>
    );
  }

  function catchInvalid(errorVisit) {
    if (formErr.filter((err) => err.path === errorVisit).length >= 1) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    if (showAddVisitModal.visitDate) {
      setVisitDate(showAddVisitModal.visitDate);
    }
  }, [showAddVisitModal]);

  return (
    <Container className="ubuntuFont">
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        className="ubuntuFont"
        show={showAddVisitModal.show}
        onHide={handleClose}
        centered
      >
        <Modal.Header className="text-center" closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-center"
          >
            <FaPlusCircle className="text-success" />
            &emsp;Nova Visita
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {user_name === "" && (
            <Form.Control
              type="text"
              placeholder={
                client_id === ""
                  ? "Pesquisar Produtor"
                  : property_id === ""
                  ? "Pesquisar Proprietário"
                  : user_name === ""
                  ? "Pesquisar Visitante"
                  : ""
              }
              size="sm"
              className="mb-3 px-3"
              value={inputSearch}
              onChange={(e) => setInputSearch(e.target.value)}
              required
            />
          )}
          <Container className="table-max-height">
            {client_id === "" ? (
              <TableSearchClient
                code3c_id={code3c_id}
                client_id={client_id}
                inputSearch={inputSearch}
                handleClient={handleClient}
                clearClient={clearClient}
                showAddVisitModal={showAddVisitModal}
              />
            ) : property_id === "" ? (
              <TableSearchProperty
                client_id={client_id}
                property_id={property_id}
                inputSearch={inputSearch}
                handleProperty={handleProperty}
                clearClient={clearClient}
                showAddVisitModal={showAddVisitModal}
              />
            ) : user_id === "" ? (
              <TableSearchUser
                code3c_id={code3c_id}
                client_id={client_id}
                property_id={property_id}
                user_id={user_id}
                inputSearch={inputSearch}
                handleUser={handleUser}
                clearUser={clearProperty}
                showAddVisitModal={showAddVisitModal}
              />
            ) : (
              <FormAddVisit
                clearProperty={clearUser}
                client_name={client_name}
                property_name={property_name}
                user_name={user_name}
                visitDate={visitDate}
                setVisitDate={setVisitDate}
                catchErrors={catchErrors}
                catchInvalid={catchInvalid}
                validated={validated}
                handleAddVisit={handleAddVisit}
              />
            )}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          {client_id !== "" && property_id !== "" && user_id !== "" && (
            <Button
              disabled={pendingRequest ? true : false}
              variant="success"
              className="mr-2"
              onClick={() => handleAddVisit() + setPendingRequest(true)}
            >
              Salvar
            </Button>
          )}
          <Button variant="danger" onClick={() => handleClose()}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default AddVisit;
