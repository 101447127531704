import React, { useEffect, useState, useContext } from "react";

import {
  Container,
  Navbar,
  Form,
  FormControl,
  Button,
  Table,
  Spinner,
} from "react-bootstrap";

import { FaRegCircle, FaRegCheckCircle, FaSeedling } from "react-icons/fa";

import {
  addCultivation,
  getCultivations,
  search3cCultivations,
  removeCultivation,
} from "../../controllers/ControllerCultivation";

import { AuthContext } from "../../configurations/authorization";

function Cultivation() {
  const { code3cData } = useContext(AuthContext);

  const [inputSearch, setInputSearch] = useState("");
  const [cultivationsStaticList, setCultivationsStaticList] = useState([]);

  const [pending, setPending] = useState(false);

  async function handleAddCultivation(id, name) {
    await addCultivation(id, name, code3cData.id);
    getData();
  }

  async function handleRemoveCultivation(id, name) {
    await removeCultivation(id, name, code3cData.id);
    getData();
  }

  async function getData() {
    const allCultivationsFromDB = await getCultivations();
    const cultivationsFromDB = await search3cCultivations(code3cData.id);
    if (allCultivationsFromDB) {
      setPending(true);
    }
    setCultivationsStaticList(
      allCultivationsFromDB.map((cultivations) =>
        findCultivation(cultivations, cultivationsFromDB),
      ),
    );
  }

  function findCultivation(cultivations, cultivationsListFromDB) {
    const cultivationCheck = cultivationsListFromDB.find(
      (item) => item.name === cultivations.name && item.code3cEnabled === true,
    );
    if (cultivationCheck) {
      return {
        id: cultivationCheck.id,
        name: cultivationCheck.name,
        active: true,
      };
    } else {
      return {
        id: cultivations.id,
        name: cultivations.name,
        active: false,
      };
    }
  }

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  return (
    <Container className="rounded bg-white my-3 pb-2 px-0 ubuntuFont">
      <h2 className="text-center py-3">CULTURAS</h2>
      <hr className="mt-0" />
      <Navbar
        bg="white"
        variant="white"
        className="mb-3 justify-content-between"
      >
        <Navbar.Brand className="link">
          <Button
            variant="outline-success"
            disabled={pending ? false : true}
            onClick={() => getData()}
          >
            <FaSeedling className="text-success" />
            &nbsp; Culturas
          </Button>
        </Navbar.Brand>
        <Form inline className="justify-content-end">
          <FormControl
            type="text"
            placeholder="Pesquisar cultura"
            className="mr-sm-2 justify-content-end"
            value={inputSearch}
            onChange={(e) => setInputSearch(e.target.value)}
          />
        </Form>
      </Navbar>
      <Container className="table-max-height">
        <Table striped bordered hover size="sm" className="rounded">
          <thead>
            <tr>
              <th width="2%">#</th>
              <th width="88%">Cultura</th>
              <th width="10%" className="text-center">
                Habilitado
              </th>
            </tr>
          </thead>
          <tbody>
            {cultivationsStaticList
              .sort(function (a, b) {
                var nameA = a.name.toLowerCase(),
                  nameB = b.name.toLowerCase();
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return 0;
              })
              .filter(
                (item) =>
                  item.name.toUpperCase().indexOf(inputSearch.toUpperCase()) !==
                  -1,
              )
              .map((cultivations, index) => (
                <tr key={index}>
                  <td width="2%" className="align-middle">
                    <b>{index + 1}</b>
                  </td>
                  <td width="88%" className="align-middle">
                    {cultivations.name}
                  </td>
                  <td width="10%" className="text-center align-middle">
                    {!pending ? (
                      <Spinner
                        animation="border"
                        variant="secondary"
                        style={{ "max-width": "10px", "max-height": "10px" }}
                      />
                    ) : cultivations.active ? (
                      <FaRegCheckCircle
                        className="text-system link"
                        onClick={() =>
                          handleRemoveCultivation(
                            cultivations.id,
                            cultivations.name,
                          )
                        }
                      />
                    ) : (
                      <FaRegCircle
                        className="link"
                        onClick={() =>
                          handleAddCultivation(
                            cultivations.id,
                            cultivations.name,
                          )
                        }
                      />
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Container>
    </Container>
  );
}

export default Cultivation;
