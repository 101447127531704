import React, { useState } from "react";

import { Container, Modal, Button, Form } from "react-bootstrap";

import { FaSearch } from "react-icons/fa";

import TableSearchClient from "../modalComponents/tableSearchClient";
import TableSearchProperty from "../modalComponents/tableSearchProperty";
import TableSearchCultivation from "../modalComponents/tableSearchCultivation";
import TableSearchCultivationVarieties from "../modalComponents/tableSearchCultivationVarieties";

function SearchCultivationVarieties(props) {
  const {
    showSearchCultivationVarietiesModal,
    setShowSearchCultivationVarietiesModal,
    handleClient,
    clearClient,
    handleProperty,
    clearProperty,
    handleCultivation,
    clearCultivation,
    handleCultivationVarieties,
    code3c_id,
    client_id,
    property_id,
    cultivation_id,
    cultivationVarieties_id,
    setClient_id,
    setProperty_id,
    setCultivation_id,
    setCultivationVarieties_id,
    setClient_name,
    setProperty_name,
    setCultivation_name,
    setCultivationVarieties_name,
    harvestList,
    toggleSpinner,
  } = props;

  const [inputSearch, setInputSearch] = useState("");

  function handleClose() {
    setShowSearchCultivationVarietiesModal(
      !showSearchCultivationVarietiesModal,
    );
  }

  function handleCleanFilter() {
    setClient_name("");
    setClient_id("");
    setProperty_id("");
    setCultivation_id("");
    setCultivationVarieties_id("");
    setProperty_name("");
    setCultivation_name("");
    setCultivationVarieties_name("");
  }

  return (
    <Container className="ubuntuFont">
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        className="ubuntuFont"
        show={showSearchCultivationVarietiesModal}
        onHide={handleClose}
        centered
      >
        <Modal.Header className="text-center" closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-center"
          >
            <FaSearch className="text-system" />
            &emsp;
            {client_id === ""
              ? "Selecionar Produtor"
              : property_id === ""
              ? "Selecionar Proprietário"
              : cultivation_id === ""
              ? "Selecionar Cultura"
              : "Selecionar Variação/Híbrido"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            type="text"
            placeholder={
              client_id === ""
                ? "Pesquisar Produtor"
                : property_id === ""
                ? "Pesquisar Proprietário"
                : cultivation_id === ""
                ? "Pesquisar Cultura"
                : "Pesquisar Variação/Híbrido"
            }
            size="sm"
            className="mb-3 px-3"
            value={inputSearch}
            onChange={(e) => setInputSearch(e.target.value)}
            required
          />
          <Container className="table-max-height">
            {client_id === "" ? (
              <TableSearchClient
                code3c_id={code3c_id}
                client_id={client_id}
                inputSearch={inputSearch}
                handleClient={handleClient}
                clearClient={clearClient}
                showSearchCultivationVarietiesModal={
                  showSearchCultivationVarietiesModal
                }
                harvestList={harvestList}
              />
            ) : property_id === "" ? (
              <TableSearchProperty
                client_id={client_id}
                property_id={property_id}
                inputSearch={inputSearch}
                handleProperty={handleProperty}
                clearClient={clearClient}
                showSearchCultivationVarietiesModal={
                  showSearchCultivationVarietiesModal
                }
                toggleSpinner={toggleSpinner}
              />
            ) : cultivation_id === "" ? (
              <TableSearchCultivation
                code3c_id={code3c_id}
                client_id={client_id}
                property_id={property_id}
                cultivation_id={cultivation_id}
                inputSearch={inputSearch}
                handleCultivation={handleCultivation}
                clearProperty={clearProperty}
                showSearchCultivationVarietiesModal={
                  showSearchCultivationVarietiesModal
                }
              />
            ) : (
              <TableSearchCultivationVarieties
                code3c_id={code3c_id}
                client_id={client_id}
                property_id={property_id}
                cultivation_id={cultivation_id}
                cultivationVarieties_id={cultivationVarieties_id}
                inputSearch={inputSearch}
                handleCultivationVarieties={handleCultivationVarieties}
                clearCultivation={clearCultivation}
                showSearchCultivationVarietiesModal={
                  showSearchCultivationVarietiesModal
                }
              />
            )}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          {client_id && (
            <Button variant="dark" onClick={() => handleCleanFilter()}>
              Limpar Filtros
            </Button>
          )}

          <Button variant="danger" onClick={() => handleClose()}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default SearchCultivationVarieties;
