import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import PrivateRoute from "../routes/privateRoute";

import { AuthProvider } from "../configurations/authorization";

//Rotas no Sistema - Início

import Header from "../components/header";
import Footer from "../components/footer";

import Login from "../pages/login";

import Client from "../pages/client";
import Property from "../pages/property";
import Field from "../pages/field";
import Harvest from "../pages/harvest";

import Cultivation from "../pages/cultivation";
import CultivationVarieties from "../pages/cultivationVarieties";

import Pesticide from "../pages/pesticide";

import Visit from "../pages/visit";
import Recommendation from "../pages/recommendation";
import Report from "../pages/report";

import User from "../pages/user";

import Dashboard from "../pages/dashboard";

// Rotas no Sistema - Final

function Routes() {
  return (
    <AuthProvider>
      <Router>
        <Header />
        <PrivateRoute exact path="/" component={Dashboard} />
        <PrivateRoute exact path="/produtores" component={Client} />
        <PrivateRoute exact path="/propriedades" component={Property} />
        <PrivateRoute exact path="/talhoes" component={Field} />
        <PrivateRoute exact path="/safras" component={Harvest} />
        <PrivateRoute exact path="/culturas" component={Cultivation} />
        <PrivateRoute
          exact
          path="/variacoes-e-hibridos"
          component={CultivationVarieties}
        />
        <PrivateRoute exact path="/defensivos" component={Pesticide} />
        <PrivateRoute exact path="/visitas" component={Visit} />
        <PrivateRoute exact path="/recomendacoes" component={Recommendation} />
        <PrivateRoute exact path="/relatorios" component={Report} />
        <PrivateRoute exact path="/usuarios" component={User} />
        <Route exact path="/login" component={Login} />
        <Footer />
      </Router>
    </AuthProvider>
  );
}

export default Routes;
