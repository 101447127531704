import * as yup from "yup";
import { pt } from "yup-locale-pt";

yup.setLocale(pt);

function parseUserFunction(userFunction) {
  switch (userFunction) {
    case "administrator":
      return "Administrador";

    case "visitor":
      return "Visitante";

    case "agronomist":
      return "Agrônomo";

    case "seller":
      return "Vendedor";

    default:
      return "";
  }
}

const dataSchema = yup.object().shape({
  name: yup.string().label("Nome").min(3).required(),
  code3c_id: yup.string().min(1).required(),
  email: yup.string().label("Email").email().required(),
  password: yup.string().label("Senha").min(8).required(),
  passwordValidation: yup
    .string()
    .label("Senha")
    .oneOf([yup.ref("password"), null], "As senhas precisam ser iguais.")
    .required(),
  systems: yup.array(
    yup.object({
      systemActive: yup.boolean().required(),
      systemId: yup.string().label("Id do Sistema").min(1).required(),
      systemName: yup.string().label("Nome do Sistema").required(),
      function: yup.string().label("Função").required(),
    })
  ),
});

const dataEditSchema = yup.object().shape({
  name: yup.string().label("Nome").min(3).required(),
  code3c_id: yup.string().min(1).required(),
  email: yup.string().label("Email").email().required(),
  // // systems: yup.array(
  // //   yup.object({
  // //     systemActive: yup.boolean().required(),
  // //     systemId: yup.string().label("Id do Sistema").min(1).required(),
  // //     systemName: yup.string().label("Nome do Sistema").required(),
  // //     function: yup.string().label("Função").required(),
  // //   }),
  // ),
});

export { parseUserFunction, dataSchema, dataEditSchema };
