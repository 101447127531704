import React, { useState } from "react";

import {
  Card,
  CardDeck,
  Container,
  Spinner,
  Button,
  Row,
} from "react-bootstrap";

import { getAllClients } from "../../../controllers/ControllerClient";
import { getAllProperties } from "../../../controllers/ControllerProperty";
import { getAllFields } from "../../../controllers/ControllerField";
import { search3cCultivations } from "../../../controllers/ControllerCultivation";
import { search3cCultivationVarieties } from "../../../controllers/ControllerCultivationVarieties";
import { getVisits } from "../../../controllers/ControllerVisit";
import { get3cRecommendations } from "../../../controllers/ControllerRecommendation";

import NewReport from "../pageModals/newReport";

import ResumeDocument from "../pageDocuments/resumeDocument";

import moment from "moment";
import "moment/locale/pt-br";

function ResumeReport(props) {
  const { code3cData } = props;

  const [toggleSpinner, setToggleSpinner] = useState(false);
  const [toggleExport, setToggleExport] = useState(false);

  const [showNewReportModal, setShowNewReportModal] = useState(false);

  const [clientList, setClientList] = useState([]);
  const [propertyList, setProperyList] = useState([]);
  const [fieldList, setFieldList] = useState([]);
  const [cultivationList, setCultivationList] = useState([]);
  const [cultivationVarietiesList, setCultivationVarietiesList] = useState([]);
  const [visitList, setVisitList] = useState([]);
  const [visitedList, setVisitedList] = useState([]);
  const [recommendationList, setRecommendationList] = useState([]);
  const [propertiesVisitedList, setPropertiesVisitedList] = useState([]);
  const [fieldsVisitedList, setFieldsVisitedList] = useState([]);

  const [dateNow, setDateNow] = useState("");

  async function getData() {
    setToggleSpinner(true);
    const newClientList = await getAllClients();
    setClientList(newClientList.filter((item) => item.systemId === "FAGRO"));
    setProperyList(await getAllProperties(code3cData.id));
    setFieldList(await getAllFields());
    setCultivationList(await search3cCultivations(code3cData.id));
    setCultivationVarietiesList(
      await search3cCultivationVarieties(code3cData.id)
    );
    const visits = await getVisits(code3cData.id);
    setVisitList(visits.filter((visit) => visit.visitDate && !visit.endVisit));
    setVisitedList(visits.filter((visit) => visit.endVisit));
    setRecommendationList(await get3cRecommendations(code3cData.id));
    setPropertiesVisitedList(visits.filter((visit) => visit.endVisit)); //Criar reduce
    setFieldsVisitedList(visits.filter((visit) => visit.endVisit)); //Criar reduce
    setDateNow("Atualizado em: " + moment().format("LLL"));
    if (visits) {
      setToggleSpinner(false);
      setToggleExport(true);
    }
  }

  return (
    <Container>
      <NewReport
        showNewReportModal={showNewReportModal}
        setShowNewReportModal={setShowNewReportModal}
        Document={ResumeDocument}
        code3cData={code3cData}
        code3c_id={code3cData.id}
      />

      <Row>
        <Button
          size="sm"
          className="bg-system mt-2 mr-2 ml-auto"
          onClick={() => {
            getData();
          }}
        >
          OBTER RESUMO
        </Button>
        <Button
          size="sm"
          disabled={!toggleExport}
          variant="outline-dark"
          className="mt-2 mr-2 mr-auto"
          onClick={() => {
            setShowNewReportModal({
              toggleSpinner,
              clientList,
              dateNow,
              propertyList,
              fieldList,
              cultivationList,
              cultivationVarietiesList,
              visitList,
              visitedList,
              propertiesVisitedList,
              fieldsVisitedList,
              recommendationList,
              show: true,
            });
          }}
        >
          EXPORTAR RESUMO
        </Button>
      </Row>
      <CardDeck className="mt-3 text-center">
        <Card className="text-center text-system-alt">
          <Card.Body className="px-0">
            <h2>PRODUTORES</h2>
            <hr />
            {toggleSpinner ? (
              <Spinner className="mx-auto text-system" animation="border" />
            ) : (
              <>
                <h2>{clientList.length}</h2>
                <small>
                  <small>CADASTRADOS</small>
                </small>
              </>
            )}
          </Card.Body>
          <Card.Footer>
            <small className="text-muted">{dateNow}</small>
          </Card.Footer>
        </Card>
        <Card className="text-center text-system-alt">
          <Card.Body className="px-0">
            <h2>PROPRIEDADES</h2>
            <hr />
            {toggleSpinner ? (
              <Spinner className="mx-auto text-system" animation="border" />
            ) : (
              <>
                <h2>{propertyList.length}</h2>
                <small>
                  <small>CADASTRADOS</small>
                </small>
              </>
            )}
          </Card.Body>
          <Card.Footer>
            <small className="text-muted">{dateNow}</small>
          </Card.Footer>
        </Card>
        <Card className="text-center text-system-alt">
          <Card.Body className="px-0">
            <h2>TALHÕES</h2>
            <hr />
            {toggleSpinner ? (
              <Spinner className="mx-auto text-system" animation="border" />
            ) : (
              <>
                <h2>{fieldList.length}</h2>
                <small>
                  <small>CADASTRADO</small>
                </small>
              </>
            )}
          </Card.Body>
          <Card.Footer>
            <small className="text-muted">{dateNow}</small>
          </Card.Footer>
        </Card>
      </CardDeck>
      <CardDeck className="mt-3 text-center">
        <Card className="text-center text-system-alt">
          <Card.Body className="px-0">
            <h2>CULTURAS</h2>
            <hr />
            {toggleSpinner ? (
              <Spinner className="mx-auto text-system" animation="border" />
            ) : (
              <>
                <h2>{cultivationList.length}</h2>
                <small>
                  <small>HABILITADOS</small>
                </small>
              </>
            )}
          </Card.Body>
          <Card.Footer>
            <small className="text-muted">{dateNow}</small>
          </Card.Footer>
        </Card>
        <Card className="text-center text-system-alt">
          <Card.Body className="px-0">
            <h2>VARIANTES/HÍBRIDOS</h2>
            <hr />
            {toggleSpinner ? (
              <Spinner className="mx-auto text-system" animation="border" />
            ) : (
              <>
                <h2>{cultivationVarietiesList.length}</h2>
                <small>
                  <small>HABILITADOS</small>
                </small>
              </>
            )}
          </Card.Body>
          <Card.Footer>
            <small className="text-muted">{dateNow}</small>
          </Card.Footer>
        </Card>
      </CardDeck>
      <CardDeck className="mt-3 text-center">
        <Card className="text-center text-system-alt">
          <Card.Body className="px-0">
            <h2>VISITAS ABERTAS</h2>
            <hr />
            {toggleSpinner ? (
              <Spinner className="mx-auto text-system" animation="border" />
            ) : (
              <>
                <h2>{visitList.length}</h2>
                <small>
                  <small>EM ABERTO</small>
                </small>
              </>
            )}
          </Card.Body>
          <Card.Footer>
            <small className="text-muted">{dateNow}</small>
          </Card.Footer>
        </Card>
        <Card className="text-center text-system-alt">
          <Card.Body className="px-0">
            <h2>VISITAS FINALIZADAS</h2>
            <hr />
            {toggleSpinner ? (
              <Spinner className="mx-auto text-system" animation="border" />
            ) : (
              <>
                <h2>{visitedList.length}</h2>
                <small>
                  <small>FINALIZADAS</small>
                </small>
              </>
            )}
          </Card.Body>
          <Card.Footer>
            <small className="text-muted">{dateNow}</small>
          </Card.Footer>
        </Card>
      </CardDeck>
      <CardDeck className="mt-3 text-center">
        <Card className="text-center text-system-alt">
          <Card.Body className="px-0">
            <h2>
              PROPRIEDADES
              <br /> VISITADAS
            </h2>
            <hr />
            {toggleSpinner ? (
              <Spinner className="mx-auto text-system" animation="border" />
            ) : (
              <>
                <h2>{propertiesVisitedList.length}</h2>
                <small>
                  <small>VISITADAS E FINALIZADAS</small>
                </small>
              </>
            )}
          </Card.Body>
          <Card.Footer>
            <small className="text-muted">{dateNow}</small>
          </Card.Footer>
        </Card>
        <Card className="text-center text-system-alt">
          <Card.Body className="px-0">
            <h2>
              TALHÕES
              <br /> VISITADOS
            </h2>
            <hr />
            {toggleSpinner ? (
              <Spinner className="mx-auto text-system" animation="border" />
            ) : (
              <>
                <h2>{fieldsVisitedList.length}</h2>
                <small>
                  <small>VISITADOS E FINALIZADOS</small>
                </small>
              </>
            )}
          </Card.Body>
          <Card.Footer>
            <small className="text-muted">{dateNow}</small>
          </Card.Footer>
        </Card>
        <Card className="text-center text-system-alt">
          <Card.Body className="px-0">
            <h2>
              RECOMENDAÇÕES
              <br /> REALIZADAS
            </h2>
            <hr />
            {toggleSpinner ? (
              <Spinner className="mx-auto text-system" animation="border" />
            ) : (
              <>
                <h2>{recommendationList.length}</h2>
                <small>
                  <small>GERADAS</small>
                </small>
              </>
            )}
          </Card.Body>
          <Card.Footer>
            <small className="text-muted">{dateNow}</small>
          </Card.Footer>
        </Card>
      </CardDeck>
    </Container>
  );
}

export default ResumeReport;
