import React from "react";

import { Container, Modal, Button, Table } from "react-bootstrap";

import { FaFilter } from "react-icons/fa";

function FilterPesticideTypes(props) {
  const {
    showFilterPesticideTypesModal,
    setShowFilterPesticideTypesModal,
    handlePesticideTypes,
    clearPesticideTypes,
  } = props;

  function handleClose() {
    setShowFilterPesticideTypesModal(!showFilterPesticideTypesModal);
  }

  return (
    <Container className="ubuntuFont">
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        className="ubuntuFont"
        show={showFilterPesticideTypesModal}
        onHide={handleClose}
        centered
      >
        <Modal.Header className="text-center" closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-center"
          >
            <FaFilter className="text-primary" />
            &emsp;Filtrar Tipo
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container className="table-max-height">
            <Table striped bordered hover size="sm" className="rounded">
              <thead>
                <tr>
                  <th width="2%">#</th>
                  <th width="88%">Tipo</th>
                  <th width="10%" className="text-center">
                    Selecionar
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    width="100%"
                    colspan="3"
                    className="align-middle text-center"
                  >
                    <Button
                      variant="outline-danger"
                      size="sm"
                      onClick={() => clearPesticideTypes()}
                    >
                      Limpar
                    </Button>
                  </td>
                </tr>
                <tr>
                  <td width="2%" className="align-middle">
                    <b>{1}</b>
                  </td>
                  <td width="63%" className="align-middle">
                    Herbicida
                  </td>
                  <td width="10%" className="text-center align-middle">
                    <Button
                      className="bg-system"
                      size="sm"
                      onClick={() => handlePesticideTypes("Herbicida")}
                    >
                      Selecionar
                    </Button>
                  </td>
                </tr>
                <tr>
                  <td width="2%" className="align-middle">
                    <b>{2}</b>
                  </td>
                  <td width="63%" className="align-middle">
                    Inseticida
                  </td>
                  <td width="10%" className="text-center align-middle">
                    <Button
                      className="bg-system"
                      size="sm"
                      onClick={() => handlePesticideTypes("Inseticida")}
                    >
                      Selecionar
                    </Button>
                  </td>
                </tr>
                <tr>
                  <td width="2%" className="align-middle">
                    <b>{3}</b>
                  </td>
                  <td width="63%" className="align-middle">
                    Fungicida
                  </td>
                  <td width="10%" className="text-center align-middle">
                    <Button
                      className="bg-system"
                      size="sm"
                      onClick={() => handlePesticideTypes("Fungicida")}
                    >
                      Selecionar
                    </Button>
                  </td>
                </tr>
                <tr>
                  <td width="2%" className="align-middle">
                    <b>{4}</b>
                  </td>
                  <td width="63%" className="align-middle">
                    Biológico
                  </td>
                  <td width="10%" className="text-center align-middle">
                    <Button
                      className="bg-system"
                      size="sm"
                      onClick={() => handlePesticideTypes("Biológico")}
                    >
                      Selecionar
                    </Button>
                  </td>
                </tr>
                <tr>
                  <td width="2%" className="align-middle">
                    <b>{5}</b>
                  </td>
                  <td width="63%" className="align-middle">
                    Micro
                  </td>
                  <td width="10%" className="text-center align-middle">
                    <Button
                      className="bg-system"
                      size="sm"
                      onClick={() => handlePesticideTypes("Micro")}
                    >
                      Selecionar
                    </Button>
                  </td>
                </tr>
                <tr>
                  <td width="2%" className="align-middle">
                    <b>{6}</b>
                  </td>
                  <td width="63%" className="align-middle">
                    Outros
                  </td>
                  <td width="10%" className="text-center align-middle">
                    <Button
                      className="bg-system"
                      size="sm"
                      onClick={() => handlePesticideTypes("Outros")}
                    >
                      Selecionar
                    </Button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => handleClose()}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default FilterPesticideTypes;
