import firebase from 'firebase';
require("firebase/firestore");

const firebaseAuth = firebase.initializeApp({
    apiKey: "AIzaSyAirjDujL8dx47P-UvOpTBW-SNh8wuT3kE",
    authDomain: "erp-cansho.firebaseapp.com",
    databaseURL: "https://erp-cansho.firebaseio.com",
    projectId: "erp-cansho",
    storageBucket: "erp-cansho.appspot.com",
    messagingSenderId: "986823077969",
    appId: "1:986823077969:web:91075aeeb2615d4db84870",
    measurementId: "G-2M9RT4JH3S"

});

export const firebaseDB = firebase.firestore();
export const firebaseStorage = firebase.storage();

export const firebaseCreate = firebase.initializeApp({
    apiKey: "AIzaSyAirjDujL8dx47P-UvOpTBW-SNh8wuT3kE",
    authDomain: "erp-cansho.firebaseapp.com",
    databaseURL: "https://erp-cansho.firebaseio.com",
    projectId: "erp-cansho",
    storageBucket: "erp-cansho.appspot.com",
    messagingSenderId: "986823077969",
    appId: "1:986823077969:web:91075aeeb2615d4db84870",
    measurementId: "G-2M9RT4JH3S"

}, "Create User");

export default firebaseAuth;
