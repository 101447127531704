import React from "react";

import { FaPlusCircle } from "react-icons/fa";

import { Container, Modal, Button } from "react-bootstrap";

import Pdf from "react-to-pdf";
import moment from "moment";

const ref = React.createRef();

function NewReport(props) {
  const {
    showNewReportModal,
    setShowNewReportModal,
    Document,
    code3cData,
  } = props;

  function handleClose() {
    setShowNewReportModal({ ...showNewReportModal, show: false });
  }
  return (
    <Container className="ubuntuFont">
      <Modal
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        className="ubuntuFont"
        show={showNewReportModal.show}
        onHide={handleClose}
        centered
      >
        <Modal.Header className="text-center" closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-center"
          >
            <FaPlusCircle className="text-success" />
            &emsp;Exportar Relatório
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className="mx-auto border my-4"
            style={{
              width: "794px",
              height: "1123px",
              overflow: "hidden",
              backgroundColor: "white",
              boxShadow: "0 0 1em rgba(0,0,0,.21)",
            }}
          >
            <div
              ref={ref}
              className="p-5"
              style={{
                width: "794px",
                height: "1123px",
                overflow: "hidden",
                backgroundColor: "white",
              }}
            >
              <Document
                toggleSpinner={showNewReportModal.toggleSpinner}
                clientList={showNewReportModal.clientList}
                dateNow={showNewReportModal.dateNow}
                propertyList={showNewReportModal.propertyList}
                fieldList={showNewReportModal.fieldList}
                cultivationList={showNewReportModal.cultivationList}
                cultivationVarietiesList={
                  showNewReportModal.cultivationVarietiesList
                }
                visitList={showNewReportModal.visitList}
                visitedList={showNewReportModal.visitedList}
                propertiesVisitedList={showNewReportModal.propertiesVisitedList}
                fieldsVisitedList={showNewReportModal.fieldsVisitedList}
                recommendationList={showNewReportModal.recommendationList}
                code3cData={code3cData}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Pdf
            targetRef={ref}
            filename={
              code3cData.name.replace(" ", "").toLowerCase() +
              "-resumo-" +
              moment().format("L") +
              ".pdf"
            }
          >
            {({ toPdf }) => (
              <Button variant="success" className="mr-2" onClick={toPdf}>
                Exportar em PDF
              </Button>
            )}
          </Pdf>
          <Button variant="danger" onClick={() => handleClose()}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default NewReport;
