import React, { useState, useEffect } from "react";

import { Container, Modal, Button, Table, Form } from "react-bootstrap";

import { FaFilter } from "react-icons/fa";

import { getVisitor } from "../../../controllers/ControllerUser";

function FilterVisitor(props) {
  const {
    showFilterVisitorModal,
    setShowFilterVisitorModal,
    handleVisitor,
    clearVisitor,
    inputFilter,
    code3c_id,
  } = props;

  const [visitorList, setVisitorList] = useState([]);
  const [inputSearch, setInputSearch] = useState("");

  function handleClose() {
    setShowFilterVisitorModal(!showFilterVisitorModal);
  }

  useEffect(() => {
    async function get3cClients() {
      const response = await getVisitor(code3c_id);
      if (response) {
        setVisitorList(response);
      }
    }
    get3cClients();
  }, [setShowFilterVisitorModal, code3c_id]);

  return (
    <Container className="ubuntuFont">
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        className="ubuntuFont"
        show={showFilterVisitorModal}
        onHide={handleClose}
        centered
      >
        <Modal.Header className="text-center" closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-center"
          >
            <FaFilter className="text-primary" />
            &emsp;Filtrar Visitante
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            type="text"
            placeholder="Pesquisar Visitante"
            size="sm"
            className="mb-3 px-3"
            value={inputSearch}
            onChange={(e) => setInputSearch(e.target.value)}
            required
          />
          <Container className="table-max-height">
            <Table striped bordered hover size="sm" className="rounded">
              <thead>
                <tr>
                  <td
                    width="100%"
                    colspan="3"
                    className="align-middle text-center"
                  >
                    <Button
                      variant="outline-danger"
                      size="sm"
                      onClick={() => clearVisitor()}
                    >
                      Limpar
                    </Button>
                  </td>
                </tr>
                <tr>
                  <th width="2%">#</th>
                  <th width="88%">Visitante</th>
                  <th width="10%" className="text-center">
                    Selecionar
                  </th>
                </tr>
              </thead>
              <tbody>
                {visitorList
                  .sort(function (a, b) {
                    var nameA = a.name.toLowerCase(),
                      nameB = b.name.toLowerCase();
                    if (nameA < nameB) return -1;
                    if (nameA > nameB) return 1;
                    return 0;
                  })
                  .filter(
                    (item) =>
                      item.id.indexOf(inputSearch) !== -1 ||
                      item.name
                        .toUpperCase()
                        .indexOf(inputSearch.toUpperCase()) !== -1,
                  )
                  .map((visitor, index) => (
                    <tr key={index}>
                      <td width="2%" className="align-middle">
                        <b>{index + 1}</b>
                      </td>
                      <td width="63%" className="align-middle">
                        {visitor.name}
                      </td>
                      <td width="10%" className="text-center align-middle">
                        <Button
                          disabled={inputFilter === visitor.name ? true : false}
                          className="bg-system"
                          size="sm"
                          onClick={() => handleVisitor(visitor.name)}
                        >
                          Selecionar
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => handleClose()}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default FilterVisitor;
