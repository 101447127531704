import React from "react";

import { Table, Button } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";

function TableField(props) {
  const {
    fieldList,
    inputSearch,
    client_name,
    property_name,
    setShowSearchPropertyModal,
    setShowEditFieldModal,
    setShowDeleteFieldModal,
    toggleSpinner,
  } = props;

  return (
    <Table striped bordered hover size="sm" className="rounded">
      <thead>
        <tr>
          <th width="100%" colSpan="6" className="align-middle text-center">
            <Button
              variant="outline-dark"
              size="sm"
              onClick={() => setShowSearchPropertyModal(true)}
            >
              <FaSearch />
              &nbsp;
              {client_name === ""
                ? "Selecione um Produtor."
                : property_name === ""
                ? client_name
                : property_name}
            </Button>
          </th>
        </tr>
        <tr>
          <th width="2%">#</th>
          <th width="43%">Talhão</th>
          <th width="10%">
            Área&nbsp;<small>(ha)</small>
          </th>
          <th width="10%">Nº</th>
          <th width="10%" className="text-center">
            Editar
          </th>
          <th width="10%" className="text-center">
            Apagar
          </th>
        </tr>
      </thead>
      <tbody>
        {fieldList.length === 0 && client_name && toggleSpinner === true && (
          <tr>
            <td width="100%" colSpan="6" className="align-middle text-center">
              Nenhum Talhão foi Encontrado
            </td>
          </tr>
        )}
        {fieldList
          .sort(function (a, b) {
            var nameA = a.name.toLowerCase(),
              nameB = b.name.toLowerCase();
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
          })
          .filter(
            (item) =>
              item.id.indexOf(inputSearch) !== -1 ||
              item.name.toUpperCase().indexOf(inputSearch.toUpperCase()) !== -1,
          )
          .map((field, index) => (
            <tr key={index}>
              <td width="2%" className="align-middle">
                <b>{index + 1}</b>
              </td>
              <td width="43%" className="align-middle">
                {field.name}
              </td>
              <td width="10%" className="align-middle">
                {field.fieldArea}
              </td>
              <td width="10%" className="align-middle">
                {field.fieldNumber}
              </td>
              <td width="10%" className="text-center align-middle">
                <Button
                  className="bg-system"
                  size="sm"
                  onClick={() =>
                    setShowEditFieldModal({
                      id: field.id,
                      name: field.name,
                      fieldNumber: field.fieldNumber,
                      fieldArea: field.fieldArea,
                      show: true,
                    })
                  }
                >
                  Editar
                </Button>
              </td>
              <td width="10%" className="text-center align-middle">
                <Button
                  disabled
                  variant="outline-danger"
                  size="sm"
                  onClick={() =>
                    setShowDeleteFieldModal({
                      id: field._key,
                      name: field.name,
                      show: true,
                    })
                  }
                >
                  Apagar
                </Button>
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
}

export default TableField;
