import React, { useState, useEffect } from "react";

import { Container, Navbar, Row } from "react-bootstrap";

import { FaPlusSquare, FaMinusSquare } from "react-icons/fa";

// import TableRecommendationType from "./tableRecommendationType";
import JumboRecommendations from "./jumboRecommendations";
import NewFieldDoc from "../pageModals/newFieldDoc";
import FieldDocument from "../pageDocuments/fieldDocument";

import { getFieldsRecommendations } from "../../../controllers/ControllerRecommendation";

import moment from "moment";
import "moment/locale/pt-br";

function JumboFields(props) {
  const { visit_id, field, harvest, code3cData, code3c_id } = props;

  const [toggleJumbo, setToggleJumbo] = useState(false);
  const [recommendationList, setRecommendationList] = useState(false);

  const [showNewFieldDocModal, setShowNewFieldDocModal] = useState({
    show: false,
  });

  async function getRecommendations() {
    setRecommendationList(
      await getFieldsRecommendations(visit_id, field.field_id)
    );
  }

  useEffect(() => {
    getRecommendations();
    // eslint-disable-next-line
  }, []);

  return (
    <Container fluid className="rounded bg-light border-system px-0 mb-3">
      <NewFieldDoc
        showNewFieldDocModal={showNewFieldDocModal}
        setShowNewFieldDocModal={setShowNewFieldDocModal}
        Document={FieldDocument}
        code3cData={code3cData}
      />
      <Navbar className="justify-content-between">
        <h4 className="my-0">
          <b>{field.field_name}</b>
        </h4>
        <h2>
          {!toggleJumbo ? (
            <FaPlusSquare
              className="link"
              onClick={() => setToggleJumbo(!toggleJumbo)}
            />
          ) : (
            <FaMinusSquare
              className="link"
              onClick={() => setToggleJumbo(!toggleJumbo)}
            />
          )}
        </h2>
      </Navbar>
      {toggleJumbo && (
        <>
          <hr className="my-0" />
          <Container>
            {field.plantingDate || field.emergencyDate ? (
              <Navbar className="justify-content-between">
                <h6>
                  <b>
                    {field.plantingDate &&
                      field.basicFertilization &&
                      "Data de Plantio: " +
                        moment(field.plantingDate.toDate()).format("LL")}
                  </b>
                </h6>
                <h6>
                  <b>
                    <b>
                      {field.emergencyDate &&
                        "Data de Emergência: " +
                          moment(field.emergencyDate.toDate()).format("LL")}
                    </b>
                  </b>
                </h6>
              </Navbar>
            ) : (
              <p className="text-center mt-2">Sem informações do Talhão</p>
            )}
            {(field.basicFertilization || field.coverFertilization) && (
              <Navbar className="justify-content-between">
                <h6>
                  <b>
                    {field.basicFertilization &&
                      "Fertilização Base: " + field.basicFertilization}
                  </b>
                </h6>
                <h6>
                  <b>
                    <b>
                      {field.coverFertilization &&
                        "Fertilização de Cobertura: " +
                          field.coverFertilization}
                    </b>
                  </b>
                </h6>
              </Navbar>
            )}
            {recommendationList.length > 0 ? (
              <>
                <h4 className="text-center">RECOMENDAÇÕES</h4>
                {recommendationList.map((recommendation, index) => (
                  <JumboRecommendations
                    recommendation={recommendation}
                    recommendationList={recommendationList}
                    setRecommendationList={setRecommendationList}
                    field={field}
                    harvest={harvest}
                    code3cData={code3cData}
                    code3c_id={code3c_id}
                    key={index}
                    getNewRecommendations={getRecommendations}
                  />
                ))}
              </>
            ) : (
              <p className="text-center">Sem Recomendações</p>
            )}
          </Container>
        </>
      )}
      <Row className="my-3">
        {/* <Button
          size="sm"
          variant="outline-dark"
          className="mx-auto"
          onClick={() => {
            setShowNewFieldDocModal({
              visit,
              field,
              harvest,
              recommendationList,
              TableRecommendationType,
              show: true,
            });
          }}
        >
          Exportar Recomendações do Talhão
        </Button> */}
      </Row>
    </Container>
  );
}

export default JumboFields;
