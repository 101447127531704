import React from "react";

import { Table, Button } from "react-bootstrap";

import moment from "moment";
import "moment/locale/pt-br";

function TableVisit(props) {
  const {
    visitList,
    inputSearch,
    inputFilter,
    setShowEditVisitModal,
    setShowDeleteVisitModal,
  } = props;
  return (
    <Table striped bordered hover size="sm" className="rounded">
      <thead>
        <tr>
          <th width="2%">#</th>
          <th width="18%" className="text-center">
            Data da Visita
          </th>
          <th width="10%" className="text-center">
            Finalizada
          </th>
          <th width="25%" className="text-center">
            Visitante
          </th>
          <th width="25%" className="text-center">
            Propriedade
          </th>
          <th width="10%" className="text-center">
            Ver
          </th>
          <th width="10%" className="text-center">
            Apagar
          </th>
        </tr>
      </thead>
      <tbody>
        {visitList
          //Corrigir posteriormente Filtro por data.
          .filter(
            (visit) =>
              visit.user_name
                .toUpperCase()
                .indexOf(inputFilter.toUpperCase()) !== -1
          )
          .filter(
            (item) =>
              item.id.indexOf(inputSearch) !== -1 ||
              item.name.toUpperCase().indexOf(inputSearch.toUpperCase()) !== -1
          )
          .map((visit, index) => (
            <tr key={index}>
              <td width="2%" className="align-middle">
                <b>{index + 1}</b>
              </td>
              <td width="18%" className="text-center align-middle">
                {moment(visit.visitDate.toDate()).format("L")}
              </td>
              <td width="10%" className="text-center align-middle">
                {visit.endVisit ? "Sim" : "Não"}
              </td>
              <td width="25%" className="text-center align-middle">
                {visit.user_name}
              </td>
              <td width="25%" className="text-center align-middle">
                {visit.property_name}
              </td>
              <td width="10%" className="text-center align-middle">
                <Button
                  className="bg-system"
                  size="sm"
                  onClick={() =>
                    setShowEditVisitModal({
                      id: visit.id,
                      visitDate: visit.visitDate.toDate(),
                      endVisit: visit.endVisit ? visit.endVisit.toDate() : "",
                      client_id: visit.client_id,
                      client_name: visit.client_name,
                      property_id: visit.property_id,
                      property_name: visit.property_name,
                      property_imageLight: visit.property_imageLight,
                      property_imageHeavy: visit.property_imageHeavy,
                      recommendationNote: visit.recommendationNote,
                      updatedRecNote: visit.updatedRecNote,
                      user_id: visit.user_id,
                      user_name: visit.user_name,
                      show: true,
                    })
                  }
                >
                  Ver
                </Button>
              </td>
              <td width="10%" className="text-center align-middle">
                <Button
                  variant="outline-danger"
                  size="sm"
                  onClick={() =>
                    setShowDeleteVisitModal({
                      id: visit.id,
                      name: visit.name,
                      show: true,
                    })
                  }
                >
                  Apagar
                </Button>
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
}

export default TableVisit;
