import React from "react";

import { Form, Button, Col, InputGroup } from "react-bootstrap";
import { FaCalendarPlus, FaCalendarMinus, FaPlus } from "react-icons/fa";

function FormEditHarvest(props) {
  const {
    client_name,
    property_name,
    cultivation_name,
    cultivationVarieties_name,
    harvestYearStart,
    setHarvestYearStart,
    harvestYearEnd,
    setHarvestYearEnd,
    note,
    setNote,
    fields,
    setShowFields,
    catchErrors,
    catchInvalid,
    validated,
    edit,
    editHarvestYear,
    setEditHarvestYear,
  } = props;

  return (
    <Form noValidate validated={validated}>
      <Form.Row>
        <Form.Group as={Col} md={6}>
          <Form.Label>Produtor</Form.Label>
          <Form.Control disabled type="text" value={client_name} />
        </Form.Group>
        <Form.Group as={Col} md={6}>
          <Form.Label>Propriedade</Form.Label>
          <Form.Control disabled type="text" value={property_name} />
        </Form.Group>
      </Form.Row>
      {(edit || fields.length > 0) && (
        <Form.Group>
          <InputGroup>
            <Form.Control
              type="text"
              disabled
              aria-describedby="inputGroupPrepend"
              manufactory_name
              value={
                fields.length === 0
                  ? "Selecionar Talhões"
                  : fields.length + " Talhões Selecionados"
              }
              required
            />
            {edit && (
              <InputGroup.Prepend>
                <InputGroup.Text
                  className="rounded-right bg-system link"
                  onClick={() => setShowFields(true)}
                >
                  <FaPlus className="text-white" />
                </InputGroup.Text>
              </InputGroup.Prepend>
            )}
          </InputGroup>
        </Form.Group>
      )}
      <Form.Row>
        <Form.Group as={Col} md={6}>
          <Form.Label>Cultura</Form.Label>
          <Form.Control disabled type="text" value={cultivation_name} />
        </Form.Group>
        <Form.Group as={Col} md={6}>
          <Form.Label>Variação/Hibrido</Form.Label>
          <Form.Control
            disabled
            type="text"
            value={cultivationVarieties_name}
          />
        </Form.Group>
      </Form.Row>
      <Form.Group>
        <Form.Label>Data de Início da Safra</Form.Label>
        <Form.Control
          required
          disabled={!edit}
          isInvalid={catchInvalid("harvestYearStart")}
          type="date"
          value={harvestYearStart}
          onChange={(e) => setHarvestYearStart(e.target.value)}
        />
        {catchErrors("harvestYearStart")}
      </Form.Group>
      {harvestYearEnd !== "" && (
        <Form.Group>
          <Form.Label>Data Final da Safra</Form.Label>
          <Form.Control
            required
            disabled={!edit}
            isInvalid={catchInvalid("harvestYearEnd")}
            type="date"
            value={harvestYearEnd}
            onChange={(e) => setHarvestYearEnd(e.target.value)}
          />
          {catchErrors("harvestYearEnd")}
        </Form.Group>
      )}
      {harvestYearEnd === "" &&
        edit &&
        (editHarvestYear ? (
          <Form.Group>
            <Form.Label className="text-center">
              <Button
                variant="danger"
                onClick={() => {
                  setEditHarvestYear(false);
                }}
              >
                <FaCalendarMinus />
              </Button>
              &emsp;Data Final da Safra
            </Form.Label>
            <Form.Control
              required
              disabled={!edit}
              isInvalid={catchInvalid("harvestYearEnd")}
              type="date"
              value={harvestYearEnd}
              onChange={(e) => setHarvestYearEnd(e.target.value)}
            />
            {catchErrors("harvestYearEnd")}
          </Form.Group>
        ) : (
          <Form.Label className="text-center">
            <Button
              variant="success"
              onClick={() => {
                setEditHarvestYear(true);
              }}
            >
              <FaCalendarPlus />
            </Button>
            &emsp;Inserir Final de Safra
          </Form.Label>
        ))}
      <Form.Group>
        <Form.Label>Anotações</Form.Label>
        <Form.Control
          disabled={!edit}
          placeholder="Anotações"
          as="textarea"
          rows="3"
          value={note}
          isInvalid={catchInvalid("note")}
          onChange={(e) => setNote(e.target.value)}
        />
        {catchErrors("note")}
      </Form.Group>
    </Form>
  );
}

export default FormEditHarvest;
