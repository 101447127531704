import React from "react";

import { Container, Modal, Button } from "react-bootstrap";

import { FaTrashAlt } from "react-icons/fa";
import { deleteVisit } from "../../../controllers/ControllerVisit";

export default function DeleteVisitModal(props) {
  const {
    showDeleteVisitModal,
    setShowDeleteVisitModal,
    getData,
    showCalendar,
    showEditVisitModal,
    setShowEditVisitModal,
    code3c_id,
  } = props;

  function clearFields() {
    setShowDeleteVisitModal(false);
  }

  function handleClose() {
    setShowDeleteVisitModal(!showDeleteVisitModal);
  }

  async function handleDeleteVisit() {
    showCalendar && setShowEditVisitModal({ show: false });
    const response = await deleteVisit(
      showDeleteVisitModal.name,
      showDeleteVisitModal.id,
    );
    if (response) {
      getData(code3c_id);
      clearFields();
    }
  }

  return (
    <Container className="ubuntuFont">
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        className="ubuntuFont"
        show={showDeleteVisitModal.show}
        onHide={handleClose}
        centered
      >
        <Modal.Header className="text-center" closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-center"
          >
            <FaTrashAlt className="text-danger" />
            &emsp;Apagar Produtor?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          Tem certeza que quer apagar o visita&nbsp;
          {showDeleteVisitModal.name}?
          <Container className="my-3">
            <Button
              variant="danger"
              className="mr-2"
              onClick={() => handleDeleteVisit()}
              style={{ width: "120px" }}
            >
              Apagar
            </Button>
            <Button
              variant="dark"
              onClick={() =>
                showCalendar
                  ? setShowEditVisitModal({
                      ...showEditVisitModal,
                      show: true,
                    }) + handleClose()
                  : handleClose()
              }
              style={{ width: "120px" }}
            >
              Cancelar
            </Button>
          </Container>
        </Modal.Body>
      </Modal>
    </Container>
  );
}
