import React from "react";

import { Table, Button } from "react-bootstrap";

import { maskBr } from "js-brasil";

function TableClient(props) {
  const {
    clientList,
    inputSearch,
    setShowEditClientModal,
    setShowDeleteClientModal,
    toggleSpinner,
  } = props;
  return (
    <Table striped bordered hover size="sm" className="rounded">
      <thead>
        <tr>
          <th width="2%">#</th>
          <th width="53%">Produtor</th>
          <th width="25%" className="text-center">
            CNPJ/CPF
          </th>
          <th width="10%" className="text-center">
            Editar
          </th>
          <th width="10%" className="text-center">
            Apagar
          </th>
        </tr>
      </thead>
      <tbody>
        {clientList.length === 0 && toggleSpinner === true && (
          <tr>
            <td width="100%" colSpan="5" className="align-middle text-center">
              Nenhum Produtor foi Encontrado
            </td>
          </tr>
        )}
        {clientList
          .sort(function (a, b) {
            var nameA = a.name.toLowerCase(),
              nameB = b.name.toLowerCase();
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
          })
          .filter(
            (item) =>
              item.id.indexOf(inputSearch) !== -1 ||
              item.name.toUpperCase().indexOf(inputSearch.toUpperCase()) !== -1,
          )
          .map((client, index) => (
            <tr key={index}>
              <td width="2%" className="align-middle">
                <b>{index + 1}</b>
              </td>
              <td width="53%" className="align-middle">
                {client.name}
              </td>
              <td width="25%" className="text-center  align-middle">
                {client.socialID.length === 14
                  ? maskBr.cnpj(client.socialID)
                  : maskBr.cpf(client.socialID)}
              </td>
              <td width="10%" className="text-center align-middle">
                <Button
                  className="bg-system"
                  size="sm"
                  onClick={() =>
                    setShowEditClientModal({
                      id: client.id,
                      name: client.name,
                      socialID: client.socialID,
                      stateSubscription: client.stateSubscription,
                      phone: client.phone,
                      email: client.email,
                      postalCode: client.address.postalCode,
                      state: client.address.state,
                      city: client.address.city,
                      neighborhood: client.address.neighborhood,
                      street: client.address.street,
                      houseNumber: client.address.houseNumber,
                      show: true,
                    })
                  }
                >
                  Editar
                </Button>
              </td>
              <td width="10%" className="text-center align-middle">
                <Button
                  variant="outline-danger"
                  size="sm"
                  onClick={() =>
                    setShowDeleteClientModal({
                      id: client.id,
                      name: client.name,
                      show: true,
                    })
                  }
                >
                  Apagar
                </Button>
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
}

export default TableClient;
