import React, { useState, useEffect } from "react";

import { Container, Modal, Button, Form } from "react-bootstrap";

import { toast } from "react-toastify";
import { FaEdit } from "react-icons/fa";

import { dataSchema, parsePesticideType } from "../../../functions/pesticide";

import { editPesticide } from "../../../controllers/ControllerPesticide";

function EditPesticide(props) {
  const {
    showEditPesticideModal,
    setShowEditPesticideModal,
    getData,
    code3c_id,
  } = props;

  const [name, setName] = useState("");
  const [typeOf, setTypeOf] = useState("herbicide");

  const [validated, setValidated] = useState(false);
  const [formErr, setFormErr] = useState([]);

  const [pendingRequest, setPendingRequest] = useState(false);

  const [pesticideTypes, setPesticideTypes] = useState([
    "herbicide",
    "insecticide",
    "fungicide",
    "biological",
    "micro",
    "others",
  ]);

  function clearFields() {
    setFormErr([]);
    setName("");
    setTypeOf("");
    setPendingRequest(false);
    setValidated(false);
    setShowEditPesticideModal({ show: false });
  }

  function handleClose() {
    clearFields();
  }

  function handlePesticideTypes(types) {
    setTypeOf(types.target.value);
  }

  async function handleEditPesticide() {
    const data = {
      code3c_id,
      name,
      typeOf,
    };

    dataSchema
      .validate(data, { abortEarly: false })
      .then(async function () {
        setValidated(true);
        const response = await editPesticide(data, showEditPesticideModal.id);
        if (response) {
          getData(code3c_id);
          clearFields();
          setFormErr([]);
        }
      })
      .catch(function (err) {
        setPendingRequest(false);
        setFormErr(err.inner);
        toast.error("Existem campos com erros.");
      });
  }

  function catchErrors(errorField) {
    return (
      <Form.Control.Feedback type="invalid">
        {formErr.map((err) => {
          if (err.path === errorField) {
            return <p>{err.errors}</p>;
          }
          return null;
        })}
      </Form.Control.Feedback>
    );
  }

  function catchInvalid(errorField) {
    if (formErr.filter((err) => err.path === errorField).length >= 1) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    if (showEditPesticideModal.name) {
      setName(showEditPesticideModal.name);
      setTypeOf(showEditPesticideModal.typeOf);
      setPesticideTypes(
        pesticideTypes.sort(function (types) {
          var typesSelector = types.toLowerCase();
          if (typesSelector === showEditPesticideModal.typeOf) return -1;
          if (typesSelector !== showEditPesticideModal.typeOf) return 1;
          return 0;
        }),
      );
    }
    // eslint-disable-next-line
  }, [showEditPesticideModal]);

  return (
    <Container className="ubuntuFont">
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        className="ubuntuFont"
        show={showEditPesticideModal.show}
        onHide={handleClose}
        centered
      >
        <Modal.Header className="text-center" closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-center"
          >
            <FaEdit className="text-primary" />
            &emsp;Editar Produtor : {showEditPesticideModal.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated}>
            <Form.Group>
              <Form.Label>Nome do Defensivo</Form.Label>
              <Form.Control
                type="text"
                isInvalid={catchInvalid("name")}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {catchErrors("name")}
            </Form.Group>
            <Form.Group>
              <Form.Label>Tipo de Defensivo</Form.Label>
              <Form.Control
                isInvalid={catchInvalid("typeOf")}
                as="select"
                onClick={handlePesticideTypes.bind(this)}
              >
                {pesticideTypes.map((item, index) => (
                  <option key={index} value={item}>
                    {parsePesticideType(item)}
                  </option>
                ))}
              </Form.Control>
              {catchErrors("typeOf")}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={pendingRequest ? true : false}
            variant="success"
            className="mr-2"
            onClick={() => handleEditPesticide() + setPendingRequest(true)}
          >
            Salvar
          </Button>
          <Button variant="danger" onClick={() => handleClose()}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default EditPesticide;
