import React, { useState, useEffect } from "react";

import { Container, Modal, Button, Form, Col, Table } from "react-bootstrap";

import { toast } from "react-toastify";
import { FaEdit } from "react-icons/fa";

import { dataSchema } from "../../../functions/visit";

import { editVisit } from "../../../controllers/ControllerVisit";

import moment from "moment";
import "moment/locale/pt-br";

function EditVisit(props) {
  const {
    showEditVisitModal,
    setShowEditVisitModal,
    getData,
    showCalendar,
    setShowDeleteVisitModal,
    code3c_id,
  } = props;

  const [visitDate, setVisitDate] = useState("");

  const [validated, setValidated] = useState(false);
  const [formErr, setFormErr] = useState([]);

  const [pendingRequest, setPendingRequest] = useState(false);

  const [client_id, setClient_id] = useState("");
  const [client_name, setClient_name] = useState("");
  const [property_id, setProperty_id] = useState("");
  const [property_name, setProperty_name] = useState("");
  const [property_imageLight, setProperty_imageLight] = useState("");
  const [property_imageHeavy, setProperty_imageHeavy] = useState("");
  const [user_id, setUser_id] = useState("");
  const [user_name, setUser_name] = useState("");

  function clearFields() {
    setFormErr([]);
    setVisitDate("");
    setClient_id("");
    setClient_name("");
    setProperty_id("");
    setProperty_name("");
    setProperty_imageLight("");
    setProperty_imageHeavy("");
    setUser_id("");
    setUser_name("");
    setPendingRequest(false);
    setValidated(false);
    setShowEditVisitModal({ show: false });
  }

  function handleClose() {
    clearFields();
  }

  function handleDeleteOnCalendar() {
    setShowEditVisitModal({ ...showEditVisitModal, show: false });
    setShowDeleteVisitModal({
      id: showEditVisitModal.id,
      name: showEditVisitModal.name,
      show: true,
    });
  }

  async function handleEditVisit() {
    const data = {
      code3c_id,
      client_id,
      client_name,
      property_id,
      property_name,
      property_imageLight,
      property_imageHeavy,
      user_id,
      user_name,
      visitDate: visitDate === "" ? "" : new Date(moment(visitDate)),
    };

    dataSchema
      .validate(data, { abortEarly: false })
      .then(async function () {
        setValidated(true);
        const response = await editVisit(data, showEditVisitModal.id);
        if (response) {
          getData(code3c_id);
          clearFields();
          setFormErr([]);
        }
      })
      .catch(function (err) {
        setFormErr(err.inner);
        setPendingRequest(false);
        toast.error("Existem campos com erros.");
      });
  }

  function catchErrors(errorField) {
    return (
      <Form.Control.Feedback type="invalid">
        {formErr.map((err) => {
          if (err.path === errorField) {
            return <p>{err.errors}</p>;
          }
          return null;
        })}
      </Form.Control.Feedback>
    );
  }

  function catchInvalid(errorField) {
    if (formErr.filter((err) => err.path === errorField).length >= 1) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    if (showEditVisitModal.visitDate) {
      setVisitDate(moment(showEditVisitModal.visitDate).format("YYYY-MM-DD"));
      setClient_id(showEditVisitModal.client_id);
      setClient_name(showEditVisitModal.client_name);
      setProperty_id(showEditVisitModal.property_id);
      setProperty_name(showEditVisitModal.property_name);
      setProperty_imageLight(showEditVisitModal.property_imageLight);
      setProperty_imageHeavy(showEditVisitModal.property_imageHeavy);
      setUser_id(showEditVisitModal.user_id);
      setUser_name(showEditVisitModal.user_name);
    }
  }, [showEditVisitModal]);

  return (
    <Container className="ubuntuFont">
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        className="ubuntuFont"
        show={showEditVisitModal.show}
        onHide={handleClose}
        centered
      >
        <Modal.Header className="text-center" closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-center"
          >
            <FaEdit className="text-primary" />
            &emsp;Editar Visita
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated}>
            <Table
              striped
              bordered
              hover
              size="sm"
              className="rounded border-0"
            ></Table>
            <Form.Row>
              <Form.Group as={Col} md={6}>
                <Form.Label>Produtor</Form.Label>
                <Form.Control disabled type="text" value={client_name} />
              </Form.Group>
              <Form.Group as={Col} md={6}>
                <Form.Label>Propriedade</Form.Label>
                <Form.Control disabled type="text" value={property_name} />
              </Form.Group>
            </Form.Row>
            <Form.Group>
              <Form.Label>Visitante</Form.Label>
              <Form.Control disabled type="text" value={user_name} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Data da Visita</Form.Label>
              <Form.Control
                required
                isInvalid={catchInvalid("visitDate")}
                type="date"
                value={visitDate}
                onChange={(e) => setVisitDate(e.target.value)}
              />
              {catchErrors("visitDate")}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {showCalendar && (
            <Button
              variant="outline-danger"
              className="mr-auto"
              onClick={() => handleDeleteOnCalendar()}
            >
              Apagar
            </Button>
          )}
          <Button
            disabled={pendingRequest ? true : false}
            variant="success"
            className="mr-2"
            onClick={() => handleEditVisit() + setPendingRequest(true)}
          >
            Salvar
          </Button>
          <Button variant="danger" onClick={() => handleClose()}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default EditVisit;
