import React from "react";

import { Container, Modal, Button } from "react-bootstrap";

import { FaTrashAlt } from "react-icons/fa";
import { deleteHarvest } from "../../../controllers/ControllerHarvest";

export default function DeleteHarvestModal(props) {
  const {
    showDeleteHarvestModal,
    setShowDeleteHarvestModal,
    getData,
    code3c_id,
  } = props;

  function clearHarvests() {
    setShowDeleteHarvestModal(false);
  }

  function handleClose() {
    setShowDeleteHarvestModal(!showDeleteHarvestModal);
  }

  async function handleDeleteHarvest() {
    const response = await deleteHarvest(
      showDeleteHarvestModal.name,
      showDeleteHarvestModal.id,
    );
    if (response) {
      getData(code3c_id);
      clearHarvests();
    }
  }

  return (
    <Container className="ubuntuFont">
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        className="ubuntuFont"
        show={showDeleteHarvestModal.show}
        onHide={handleClose}
        centered
      >
        <Modal.Header className="text-center" closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-center"
          >
            <FaTrashAlt className="text-danger" />
            &emsp;Apagar Propriedade?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          Tem certeza que quer apagar o propriedade:&nbsp;
          {showDeleteHarvestModal.name}?
          <Container className="my-3">
            <Button
              variant="danger"
              className="mr-2"
              onClick={() => handleDeleteHarvest()}
              style={{ width: "120px" }}
            >
              Apagar
            </Button>
            <Button
              variant="dark"
              onClick={() => handleClose()}
              style={{ width: "120px" }}
            >
              Cancelar
            </Button>
          </Container>
        </Modal.Body>
      </Modal>
    </Container>
  );
}
