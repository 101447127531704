import React, { useState, useEffect } from "react";

import { Table, Button, Spinner, Row } from "react-bootstrap";

import { searchCultivation_CultivationVarieties } from "../../../controllers/ControllerCultivationVarieties";

function TableSearchCultivationVarieties(props) {
  const {
    code3c_id,
    client_id,
    property_id,
    cultivation_id,
    cultivationVarieties_id,
    inputSearch,
    handleCultivationVarieties,
    clearCultivation,
    showSearchCultivationVarietiesModal,
  } = props;

  const [cultivationVarietiesList, setCultivationVarietiesList] = useState([]);
  const [pending, setPending] = useState(false);

  useEffect(() => {
    async function get3cCultivationVarieties() {
      const response = await searchCultivation_CultivationVarieties(
        code3c_id,
        cultivation_id,
      );
      if (response) {
        setCultivationVarietiesList(response);
        setPending(true);
      }
    }
    get3cCultivationVarieties();
    // eslint-disable-next-line
  }, [showSearchCultivationVarietiesModal, property_id]);

  return cultivationVarietiesList.length === 0 && !pending ? (
    <Row>
      <Spinner className="mx-auto my-5 text-system" animation="border" />
    </Row>
  ) : (
    <Table striped bordered hover size="sm" className="rounded">
      {client_id !== "" && (
        <thead>
          <tr>
            <th width="100%" colSpan="3" className="align-middle text-center">
              <Button
                variant="outline-danger"
                size="sm"
                onClick={() => clearCultivation()}
              >
                Voltar
              </Button>
            </th>
          </tr>
        </thead>
      )}
      <thead>
        <tr>
          <th width="2%">#</th>
          <th width="88%">Variação/Híbrido</th>
          <th width="10%" className="text-center">
            Selecionar
          </th>
        </tr>
      </thead>
      <tbody>
        {cultivationVarietiesList
          .sort(function (a, b) {
            var nameA = a.name.toLowerCase(),
              nameB = b.name.toLowerCase();
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
          })
          .filter(
            (item) =>
              item.id.indexOf(inputSearch) !== -1 ||
              item.name.toUpperCase().indexOf(inputSearch.toUpperCase()) !== -1,
          )
          .map((cultivationVarieties, index) => (
            <tr key={index}>
              <td width="2%" className="align-middle">
                <b>{index + 1}</b>
              </td>
              <td width="63%" className="align-middle">
                {cultivationVarieties.name}
              </td>
              <td width="10%" className="text-center align-middle">
                <Button
                  disabled={
                    cultivationVarieties_id === cultivationVarieties.id
                      ? true
                      : false
                  }
                  className="bg-system"
                  size="sm"
                  onClick={() =>
                    handleCultivationVarieties(
                      cultivationVarieties.id,
                      cultivationVarieties.name,
                    )
                  }
                >
                  Selecionar
                </Button>
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
}

export default TableSearchCultivationVarieties;
