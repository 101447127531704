import React from "react";

import { Container, Modal, Button } from "react-bootstrap";

import { FaTrashAlt } from "react-icons/fa";
import { deleteField } from "../../../controllers/ControllerField";

export default function DeleteFieldModal(props) {
  const {
    showDeleteFieldModal,
    setShowDeleteFieldModal,
    getData,
    code3c_id,
  } = props;

  function clearFields() {
    setShowDeleteFieldModal(false);
  }

  function handleClose() {
    setShowDeleteFieldModal(!showDeleteFieldModal);
  }

  async function handleDeleteField() {
    const response = await deleteField(
      showDeleteFieldModal.name,
      showDeleteFieldModal.id,
    );
    if (response) {
      getData(code3c_id);
      clearFields();
    }
  }

  return (
    <Container className="ubuntuFont">
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        className="ubuntuFont"
        show={showDeleteFieldModal.show}
        onHide={handleClose}
        centered
      >
        <Modal.Header className="text-center" closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-center"
          >
            <FaTrashAlt className="text-danger" />
            &emsp;Apagar Propriedade?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          Tem certeza que quer apagar o propriedade:&nbsp;
          {showDeleteFieldModal.name}?
          <Container className="my-3">
            <Button
              variant="danger"
              className="mr-2"
              onClick={() => handleDeleteField()}
              style={{ width: "120px" }}
            >
              Apagar
            </Button>
            <Button
              variant="dark"
              onClick={() => handleClose()}
              style={{ width: "120px" }}
            >
              Cancelar
            </Button>
          </Container>
        </Modal.Body>
      </Modal>
    </Container>
  );
}
