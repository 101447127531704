import React from "react";

import { Card, CardDeck, Container, Spinner, Row, Col } from "react-bootstrap";

import { maskBr } from "js-brasil";

function ResumeDocument(props) {
  const {
    toggleSpinner,
    clientList,
    dateNow,
    propertyList,
    fieldList,
    cultivationList,
    cultivationVarietiesList,
    visitList,
    visitedList,
    propertiesVisitedList,
    fieldsVisitedList,
    recommendationList,
    code3cData,
  } = props;

  return (
    <Container>
      <h5 className="text-center text-system">CANSHO FORÇA AGRO</h5>
      <hr />
      <Row className="my-2">
        <Col md={1}>NOME:</Col>
        <Col md={8}>
          <b>{code3cData.name}</b>
        </Col>
        <Col className="text-right">Nº:</Col>
        <Col className="text-right">
          <b>{code3cData.id}</b>
        </Col>
      </Row>
      <p className="my-2">
        {code3cData.socialID.length === 14 ? "CNPJ:" : "CPF:"} &emsp;
        <b>
          {code3cData.socialID.length === 14
            ? maskBr.cnpj(code3cData.socialID)
            : maskBr.cpf(code3cData.socialID)}
        </b>
      </p>
      <hr />
      <h5 className="text-center text-system">RESUMO DE ATIVIDADES</h5>
      <CardDeck className="mt-3 text-center">
        <Card className="text-center text-system-alt">
          <Card.Body className="px-0">
            <h6>PRODUTORES</h6>
            <hr />
            {toggleSpinner ? (
              <Spinner className="mx-auto text-system" animation="border" />
            ) : (
              <>
                <h6>{clientList.length}</h6>
                <small>
                  <small>CADASTRADOS</small>
                </small>
              </>
            )}
          </Card.Body>
        </Card>
        <Card className="text-center text-system-alt">
          <Card.Body className="px-0">
            <h6>PROPRIEDADES</h6>
            <hr />
            {toggleSpinner ? (
              <Spinner className="mx-auto text-system" animation="border" />
            ) : (
              <>
                <h6>{propertyList.length}</h6>
                <small>
                  <small>CADASTRADOS</small>
                </small>
              </>
            )}
          </Card.Body>
        </Card>
        <Card className="text-center text-system-alt">
          <Card.Body className="px-0">
            <h6>TALHÕES</h6>
            <hr />
            {toggleSpinner ? (
              <Spinner className="mx-auto text-system" animation="border" />
            ) : (
              <>
                <h6>{fieldList.length}</h6>
                <small>
                  <small>CADASTRADO</small>
                </small>
              </>
            )}
          </Card.Body>
        </Card>
      </CardDeck>
      <CardDeck className="mt-3 text-center">
        <Card className="text-center text-system-alt">
          <Card.Body className="px-0">
            <h6>CULTURAS</h6>
            <hr />
            {toggleSpinner ? (
              <Spinner className="mx-auto text-system" animation="border" />
            ) : (
              <>
                <h6>{cultivationList.length}</h6>
                <small>
                  <small>HABILITADOS</small>
                </small>
              </>
            )}
          </Card.Body>
        </Card>
        <Card className="text-center text-system-alt">
          <Card.Body className="px-0">
            <h6>VARIANTES/HÍBRIDOS</h6>
            <hr />
            {toggleSpinner ? (
              <Spinner className="mx-auto text-system" animation="border" />
            ) : (
              <>
                <h6>{cultivationVarietiesList.length}</h6>
                <small>
                  <small>HABILITADOS</small>
                </small>
              </>
            )}
          </Card.Body>
        </Card>
      </CardDeck>
      <CardDeck className="mt-3 text-center">
        <Card className="text-center text-system-alt">
          <Card.Body className="px-0">
            <h6>VISITAS ABERTAS</h6>
            <hr />
            {toggleSpinner ? (
              <Spinner className="mx-auto text-system" animation="border" />
            ) : (
              <>
                <h6>{visitList.length}</h6>
                <small>
                  <small>EM ABERTO</small>
                </small>
              </>
            )}
          </Card.Body>
        </Card>
        <Card className="text-center text-system-alt">
          <Card.Body className="px-0">
            <h6>VISITAS FINALIZADAS</h6>
            <hr />
            {toggleSpinner ? (
              <Spinner className="mx-auto text-system" animation="border" />
            ) : (
              <>
                <h6>{visitedList.length}</h6>
                <small>
                  <small>FINALIZADAS</small>
                </small>
              </>
            )}
          </Card.Body>
        </Card>
      </CardDeck>
      <CardDeck className="mt-3 text-center">
        <Card className="text-center text-system-alt">
          <Card.Body className="px-0">
            <h6>
              PROPRIEDADES
              <br /> VISITADAS
            </h6>
            <hr />
            {toggleSpinner ? (
              <Spinner className="mx-auto text-system" animation="border" />
            ) : (
              <>
                <h6>{propertiesVisitedList.length}</h6>
                <small>
                  <small>VISITADAS E FINALIZADAS</small>
                </small>
              </>
            )}
          </Card.Body>
        </Card>
        <Card className="text-center text-system-alt">
          <Card.Body className="px-0">
            <h6>
              TALHÕES
              <br /> VISITADOS
            </h6>
            <hr />
            {toggleSpinner ? (
              <Spinner className="mx-auto text-system" animation="border" />
            ) : (
              <>
                <h6>{fieldsVisitedList.length}</h6>
                <small>
                  <small>VISITADOS E FINALIZADOS</small>
                </small>
              </>
            )}
          </Card.Body>
        </Card>
        <Card className="text-center text-system-alt">
          <Card.Body className="px-0">
            <h6>
              RECOMENDAÇÕES
              <br /> REALIZADAS
            </h6>
            <hr />
            {toggleSpinner ? (
              <Spinner className="mx-auto text-system" animation="border" />
            ) : (
              <>
                <h6>{recommendationList.length}</h6>
                <small>
                  <small>GERADAS</small>
                </small>
              </>
            )}
          </Card.Body>
        </Card>
      </CardDeck>
      <small>
        <small className="text-muted text-center">{dateNow}</small>
      </small>
    </Container>
  );
}

export default ResumeDocument;
