import React, { useState } from "react";

import { Container, Modal, Button, Form } from "react-bootstrap";

import { FaSearch } from "react-icons/fa";

import TableSearchClient from "../modalComponents/tableSearchClient";
import TableSearchProperty from "../modalComponents/tableSearchProperty";

function SearchProperty(props) {
  const {
    showSearchPropertyModal,
    setShowSearchPropertyModal,
    handleClient,
    clearClient,
    handleProperty,
    client_id,
    property_id,
    code3c_id,
  } = props;

  const [inputSearch, setInputSearch] = useState("");

  function handleClose() {
    setShowSearchPropertyModal(!showSearchPropertyModal);
  }

  return (
    <Container className="ubuntuFont">
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        className="ubuntuFont"
        show={showSearchPropertyModal}
        onHide={handleClose}
        centered
      >
        <Modal.Header className="text-center" closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-center"
          >
            <FaSearch className="text-system" />
            &emsp;
            {client_id === "" ? "Selecione Produtor" : "Selecione Proprietário"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            type="text"
            placeholder={
              client_id === "" ? "Pesquisar Produtor" : "Pesquisar Proprietário"
            }
            size="sm"
            className="mb-3 px-3"
            value={inputSearch}
            onChange={(e) => setInputSearch(e.target.value)}
            required
          />
          <Container className="table-max-height">
            {client_id === "" ? (
              <TableSearchClient
                code3c_id={code3c_id}
                client_id={client_id}
                inputSearch={inputSearch}
                handleClient={handleClient}
                clearClient={clearClient}
                showSearchPropertyModal={showSearchPropertyModal}
              />
            ) : (
              <TableSearchProperty
                client_id={client_id}
                property_id={property_id}
                inputSearch={inputSearch}
                handleProperty={handleProperty}
                clearClient={clearClient}
                showSearchPropertyModal={showSearchPropertyModal}
              />
            )}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => handleClose()}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default SearchProperty;
