import React from "react";

import { Container, Row, Col } from "react-bootstrap";

import moment from "moment";
import "moment/locale/pt-br";

import { maskBr } from "js-brasil";

import logoSystem from "../../../assets/imgs/logo-dashboard.png";

function GeneratorUrl(photoName) {
  return `https://firebasestorage.googleapis.com/v0/b/erp-cansho.appspot.com/o/FAGRO%2F${photoName}?alt=media`;
}

function RecommendationDocument(props) {
  const {
    visit,
    harvest,
    field,
    recommendation,
    TableRecommendationType,
    showImages,
    showDescription,
    code3cData,
  } = props;

  return (
    <Container className="p-0">
      <Row>
        <Col md={2}>
          <img
            src={logoSystem}
            alt="systemLogo"
            style={{ width: "150px", height: "126px" }}
          />
        </Col>
        <Col md={10} className="my-auto">
          <div className="border-system-alt">
            <h2 className="text-center text-system mx-0 my-2">
              Recomendação Agronômica
            </h2>
          </div>
          <Row className="text-size-minus-12 px-2">
            <Col md={1} className="font-weight-bold">
              Empresa:
            </Col>
            <Col md={3} className="text-center">
              {code3cData.name}
            </Col>
            <Col md={1} className="font-weight-bold">
              {code3cData.socialID.length === 14 ? "CNPJ:" : "CPF:"} &emsp;
            </Col>
            <Col md={3} className="text-center">
              {code3cData.socialID.length === 14
                ? maskBr.cnpj(code3cData.socialID)
                : maskBr.cpf(code3cData.socialID)}
            </Col>
            <Col md={1} className="font-weight-bold">
              Visita:
            </Col>
            <Col md={3} className="text-left">
              {visit.id}
            </Col>
          </Row>
        </Col>
      </Row>
      <hr className="border-hr" />
      <Row className="text-size-minus px-2">
        <Col md={4} className="font-weight-bold">
          Produtor
        </Col>
        <Col md={4} className="font-weight-bold">
          Propriedade
        </Col>
        <Col md={4} className="font-weight-bold">
          Data da Visita
        </Col>
      </Row>
      <Row className="text-size-minus px-2">
        <Col md={4} className="text-center">
          {visit.client_name}
        </Col>
        <Col md={4} className="text-center">
          {visit.property_name}
        </Col>
        <Col md={4} className="text-center">
          {moment(visit.visitDate.toDate()).format("L")}
        </Col>
      </Row>
      <hr className="border-hr" />
      <Row className="text-size-minus">
        <Col md={3} className="font-weight-bold">
          Safra:
        </Col>
        <Col md={4}>
          {harvest.cultivation_name +
            " " +
            moment(harvest.harvestYearStart.toDate()).format("YY") +
            "-" +
            (parseInt(moment(harvest.harvestYearStart.toDate()).format("YY")) +
              1)}
        </Col>
      </Row>
      <Row className="text-size-minus">
        <Col md={3} className="font-weight-bold">
          Variação/Híbrido:
        </Col>
        <Col md={4}>{harvest.cultivationVarieties_name}</Col>
      </Row>
      <Row className="text-size-minus">
        <Col md={3} className="font-weight-bold">
          Início:
        </Col>
        <Col md={4}>
          {moment(harvest.harvestYearStart.toDate()).format("MM/YYYY")}
        </Col>
      </Row>
      <Row className="text-size-minus">
        <Col md={3} className="font-weight-bold">
          Final:
        </Col>
        <Col md={4}>
          {harvest.harvestYearEnd
            ? moment(harvest.harvestYearStart.toDate()).format("MM/YYYY")
            : "Em Andamento"}
        </Col>
      </Row>
      <br />
      <div id="container-dont-break">
        <Row className="text-size-minus">
          <Col md={6}>
            <Row className="text-size-minus">
              <Col md={6} className="font-weight-bold">
                Nome do Talhão:
              </Col>
              <Col md={6}>{field.field_name}</Col>
            </Row>
            <Row className="text-size-minus">
              <Col md={6} className="font-weight-bold">
                Nº do Talhão:
              </Col>
              <Col md={6}>{field.fieldNumber}</Col>
            </Row>
            <Row className="text-size-minus">
              <Col md={6} className="font-weight-bold">
                Data de Plantio:
              </Col>
              <Col md={6}>
                {field.plantingDate
                  ? moment(field.plantingDate.toDate()).format("L")
                  : "Sem data de Plantio"}
              </Col>
            </Row>
            <Row className="text-size-minus">
              <Col md={6} className="font-weight-bold">
                Data de Emergência:
              </Col>
              <Col md={6}>
                {field.emergencyDate
                  ? moment(field.emergencyDate.toDate()).format("L")
                  : "Sem data de Plantio"}
              </Col>
            </Row>
            <Row className="text-size-minus">
              <Col md={6} className="font-weight-bold">
                Fert. Base:
              </Col>
              <Col md={6}>
                {field.basicFertilization
                  ? field.basicFertilization
                  : "Não Possui"}
              </Col>
            </Row>
            <Row className="text-size-minus">
              <Col md={6} className="font-weight-bold">
                Fert. de Cobertura:
              </Col>
              <Col md={6}>
                {field.coverFertilization
                  ? field.coverFertilization
                  : "Não Possui"}
              </Col>
            </Row>
          </Col>
          <Col>
            <h6 className="text-system text-center">
              Diagnóstico: {field.field_name}
            </h6>
            <p className="text-justify text-size-minus-12">
              {recommendation.diagnosis
                ? recommendation.diagnosis
                : "Sem diagnóstico"}
            </p>
          </Col>
        </Row>
      </div>
      <br />
      <h6 className="text-system text-center">
        Defensivos Recomendados para {field.field_name}
      </h6>
      <TableRecommendationType pesticideList={recommendation.pesticides} />
      <br />
      {recommendation.gallery.map((image, index) => (
        <div id="container-dont-break">
          <Row>
            {showImages && (
              <Col md={showDescription ? 6 : 12}>
                <h6 className="text-system text-center">
                  Imagem {index + 1} - {field.field_name}
                </h6>
                <Row className={showDescription && "border-system-alpha"}>
                  <img
                    className="mx-auto imgDoc rounded"
                    alt="visitImg"
                    src={GeneratorUrl(image.imageHeavy)}
                  />
                </Row>
              </Col>
            )}
            {showDescription && (
              <Col md={showImages ? 6 : 12}>
                <h6 className="text-system text-center">
                  Descrição
                  {showImages ? ": Imagem " + (index + 1) : ": " + (index + 1)}
                </h6>
                <p className="text-justify text-size-minus-12 my-0">
                  {image.note}
                </p>
              </Col>
            )}
          </Row>
          <br />
        </div>
      ))}
    </Container>
  );
}

export default RecommendationDocument;
