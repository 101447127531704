import React from "react";

import { Form, Button, Table, Col } from "react-bootstrap";

function FormAddVisit(props) {
  const {
    clearProperty,
    client_name,
    property_name,
    user_name,
    visitDate,
    setVisitDate,
    catchErrors,
    catchInvalid,
    validated,
  } = props;

  return (
    <Form noValidate validated={validated}>
      <Table striped bordered hover size="sm" className="rounded border-0">
        <thead>
          <tr>
            <th
              width="100%"
              colSpan="3"
              className="align-middle text-center border-0"
            >
              <Button
                variant="outline-danger"
                size="sm"
                onClick={() => clearProperty()}
              >
                Voltar
              </Button>
            </th>
          </tr>
        </thead>
      </Table>
      <Form.Row>
        <Form.Group as={Col} md={6}>
          <Form.Label>Produtor</Form.Label>
          <Form.Control disabled type="text" value={client_name} />
        </Form.Group>
        <Form.Group as={Col} md={6}>
          <Form.Label>Propriedade</Form.Label>
          <Form.Control disabled type="text" value={property_name} />
        </Form.Group>
      </Form.Row>
      <Form.Group>
        <Form.Label>Visitante</Form.Label>
        <Form.Control disabled type="text" value={user_name} />
      </Form.Group>
      <Form.Group>
        <Form.Label>Data da Visita</Form.Label>
        <Form.Control
          required
          isInvalid={catchInvalid("visitDate")}
          type="date"
          value={visitDate}
          onChange={(e) => setVisitDate(e.target.value)}
        />
        {catchErrors("visitDate")}
      </Form.Group>
    </Form>
  );
}

export default FormAddVisit;
