import { firebaseDB } from "../configurations/firebaseConfig";
import { toast } from "react-toastify";

import moment from "moment";
import "moment/locale/pt-br";

const response = firebaseDB.collection("harvest");

async function addHarvest(data) {
  try {
    const dataDB = await response.add(data);
    toast.success(
      "Safra " +
        data.cultivation_name +
        " " +
        moment(data.harvestYearStart).format("YY") +
        "-" +
        (parseInt(moment(data.harvestYearStart).format("YY")) + 1) +
        " foi adicionado!",
      3000,
    );
    return dataDB.id;
  } catch (err) {
    console.log(err);
    toast.error("Parece que tem algo errado com seu cadastro!", 3000);
  }
}

async function getHarvests(id) {
  try {
    const data = await response.where("code3c_id", "==", id).get();
    const allData = [];
    data.docs.forEach((item) => {
      allData.push({ ...item.data(), id: item.id });
    });
    return allData;
  } catch (err) {
    console.log(err);
    toast.error("Não foi possível ler os dados.");
  }
}

async function getPropertyHarvests(property_id) {
  try {
    const data = await response.where("property_id", "==", property_id).get();
    const allData = [];
    data.docs.forEach((item) => {
      allData.push({ ...item.data(), id: item.id });
    });
    return allData;
  } catch (err) {
    console.log(err);
    toast.error("Não foi possível ler os dados.");
  }
}

async function searchHarvest(id) {
  try {
    const data = await response.doc(id).get();
    return { ...data.data(), id: id };
  } catch (err) {
    console.log(err);
    toast.error("Não foi possível ler os dados.");
  }
}

async function editHarvest(data, id) {
  try {
    const dataDBExists = await response.doc(id).get();
    if (!dataDBExists) {
      return [];
    } else {
      await response.doc(id).set(data);
      toast.success("Produtor " + data.name + " foi editado!", 3000);
      return true;
    }
  } catch (err) {
    console.log(err);
    toast.error("Parece que tem algo errado com sua edição!", 3000);
  }
}

async function deleteHarvest(name, id) {
  try {
    await response.doc(id).delete();
    toast.success("Produtor " + name + " foi apagado!", 3000);
    return true;
  } catch (err) {
    console.log(err);
    toast.error("Parece que tem algo errado com sua edição!", 3000);
  }
}

export {
  addHarvest,
  getHarvests,
  getPropertyHarvests,
  searchHarvest,
  editHarvest,
  deleteHarvest,
};
