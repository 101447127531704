import React, { useState, useEffect } from "react";

import {
  Container,
  Navbar,
  Carousel,
  Card,
  OverlayTrigger,
  Row,
  Button,
  Form,
} from "react-bootstrap";

import {
  FaPlusCircle,
  FaImages,
  FaImage,
  FaExclamationCircle,
} from "react-icons/fa";

import { searchVisit } from "../../../controllers/ControllerVisit";

import TableRecommendationType from "./tableRecommendationType";
import TooltipRecommendation from "./tooltipRecommendation";

import AddImagesModal from "./addImages";
import NewRecommendationDoc from "../pageModals/newRecommendationDoc";

import RecommendationDocument from "../pageDocuments/recommendationDocument";

import moment from "moment";
import "moment/locale/pt-br";
import { editRecommendation } from "../../../controllers/ControllerRecommendation";

function GeneratorUrl(photoName) {
  return `https://firebasestorage.googleapis.com/v0/b/erp-cansho.appspot.com/o/FAGRO%2F${photoName}?alt=media`;
}

function JumboRecommendations(props) {
  const {
    recommendation,
    recommendationList,
    setRecommendationList,
    // field,
    // harvest,
    code3cData,
    code3c_id,
    getNewRecommendations,
  } = props;

  const [visit, setVisit] = useState("");
  const [toggleGallery, setToggleGallery] = useState(true);
  const [showAddImagesModal, setShowAddImagesModal] = useState(false);
  const [showNewRecommendationDocModal, setShowNewRecommendationDocModal] =
    useState({ show: false });

  const [editDiagnosis, setEditDiagnosis] = useState(false);
  const [newDiagnosis, setNewDiagnosis] = useState("");

  const [editNote, setEditNote] = useState({ edit: false, index: -1 });
  const [newNote, setNewNote] = useState("");

  async function getRecommendations() {
    setVisit(await searchVisit(recommendation.agroVisits_id));
    setNewDiagnosis(recommendation.diagnosis);
  }

  async function handleSaveNewDiagnosis() {
    const response = await editRecommendation(
      {
        diagnosis: newDiagnosis,
        updatedDiagnosis: new Date(),
      },
      recommendation.id
    );
    if (response) {
      getNewRecommendations();
      setEditDiagnosis(false);
    }
  }

  function handleEditNewNote(imageNote, index) {
    setEditNote({ edit: true, index });
    setNewNote(imageNote);
  }

  const printRecommention = () => {
    const url = `https://us-central1-erp-cansho.cloudfunctions.net/pdfGenerator/recommendation/${recommendation.id}`;
    window.open(url, "_blank");
  };

  async function handleSaveNewNote(image) {
    let newGallery = [];

    // eslint-disable-next-line
    recommendation.gallery.map((nI) => {
      if (image === nI) {
        newGallery.push({ ...nI, note: newNote });
        return 0;
      }
      newGallery.push(nI);
      return 0;
    });

    console.log(newGallery);

    const response = await editRecommendation(
      { gallery: newGallery },
      recommendation.id
    );
    if (response) {
      getNewRecommendations();
      setEditNote({ edit: false, index: -1 });
    }
  }

  useEffect(() => {
    if (recommendation) {
      getRecommendations();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Container fluid className="rounded bg-light border-system px-0 mb-3">
      <AddImagesModal
        showAddImagesModal={showAddImagesModal}
        setShowAddImagesModal={setShowAddImagesModal}
        code3c_id={code3c_id}
        recommendation={recommendation}
        recommendationList={recommendationList}
        setRecommendationList={setRecommendationList}
        gallery={recommendation.gallery}
      />
      <NewRecommendationDoc
        showNewRecommendationDocModal={showNewRecommendationDocModal}
        setShowNewRecommendationDocModal={setShowNewRecommendationDocModal}
        Document={RecommendationDocument}
        code3cData={code3cData}
      />
      <Navbar className="justify-content-center">
        <h6 className="my-0">
          <b>
            {visit.visitDate &&
              "Data da Visita: " +
                moment(visit.visitDate.toDate()).format("LL")}
          </b>
        </h6>
      </Navbar>
      <Navbar className="justify-content-between">
        <h6 className="my-0 text-justify">
          DIAGNÓSTICO:&emsp;
          {recommendation.updatedDiagnosis ? (
            <small>
              Modificado em{" "}
              {moment(recommendation.updatedDiagnosis.toDate()).format("LLL")}
            </small>
          ) : (
            ""
          )}
        </h6>
        {editDiagnosis ? (
          <div>
            <Button
              className="btn-success mr-1"
              size="sm"
              onClick={() => {
                handleSaveNewDiagnosis();
              }}
            >
              Salvar
            </Button>
            <Button
              className="btn-danger"
              size="sm"
              onClick={() => {
                setEditDiagnosis(false);
              }}
            >
              Cancelar
            </Button>
          </div>
        ) : (
          <Button
            className="bg-system"
            size="sm"
            onClick={() => {
              setEditDiagnosis(true);
            }}
          >
            Editar Campo
          </Button>
        )}
      </Navbar>
      <div className="mx-3 mb-3">
        {editDiagnosis ? (
          <Form.Control
            placeholder="Anotações"
            as="textarea"
            rows="3"
            value={newDiagnosis}
            onChange={(e) => setNewDiagnosis(e.target.value)}
          />
        ) : (
          <b
            className="mx-3 mb-5"
            style={{ width: "50% !important", overflow: "hidden" }}
          >
            {recommendation.diagnosis && recommendation.diagnosis}
          </b>
        )}
      </div>
      {recommendation.pesticides && (
        <>
          <h5 className="text-center">
            <b>Defensivos Recomendados</b>
          </h5>
          <TableRecommendationType pesticideList={recommendation.pesticides} />
        </>
      )}
      <h5 className="text-center">
        <b>Galeria</b>
      </h5>
      <h5 className="text-center link">
        <FaPlusCircle
          className="text-system text-center"
          onClick={() => {
            setShowAddImagesModal(!showAddImagesModal);
          }}
        />
        &emsp;
        {toggleGallery ? (
          <FaImage
            className="text-system text-center"
            onClick={() => {
              setToggleGallery(!toggleGallery);
            }}
          />
        ) : (
          <FaImages
            className="text-system text-center"
            onClick={() => {
              setToggleGallery(!toggleGallery);
            }}
          />
        )}
      </h5>
      {toggleGallery ? (
        <Carousel className="m-3 gallery-wh mx-auto">
          {recommendation.gallery.map((image, index) => (
            <Carousel.Item
              key={index}
              className="gallery-wh bg-system rounded border-system"
            >
              <img
                className="d-block mx-auto gallery-wh"
                src={GeneratorUrl(image.imageHeavy)}
                alt={image.note}
              />
              <Carousel.Caption>
                <h5 className="cut-text">
                  {image.afterUp ? (
                    <>
                      <OverlayTrigger
                        placement="left"
                        delay={{ show: 250, hide: 400 }}
                        overlay={TooltipRecommendation}
                      >
                        <FaExclamationCircle className="text-warning" />
                      </OverlayTrigger>
                      {" " + image.note}
                    </>
                  ) : (
                    <>{image.note}</>
                  )}
                </h5>
                <p>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={
                      "https://www.google.com/maps?&z=10&q=loc:" +
                      image.latitude +
                      "+" +
                      image.longitude +
                      "&||=" +
                      image.latitude +
                      "+" +
                      image.longitude
                    }
                  >
                    Vá para o Mapa
                  </a>
                </p>
              </Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
      ) : (
        <>
          {recommendation.gallery.map((image, index) => (
            <Card className="card-wh mx-auto mb-3">
              <Card.Img
                variant="top"
                src={GeneratorUrl(image.imageHeavy)}
                className="gallery-wh"
              />
              <Card.Body>
                <Card.Title>
                  <p
                    style={{ overflow: "auto", maxHeight: "150px" }}
                    className="text-justify px-2"
                  >
                    {image.afterUp ? (
                      <>
                        <OverlayTrigger
                          placement="left"
                          delay={{ show: 250, hide: 400 }}
                          overlay={TooltipRecommendation}
                        >
                          <FaExclamationCircle className="text-warning" />
                        </OverlayTrigger>
                        {" " + image.note}
                      </>
                    ) : (
                      <div>
                        <Navbar className="justify-content-between px-0">
                          <h6 className="my-0 text-justify">
                            Observações:&emsp;
                          </h6>
                          {editNote.edit && editNote.index === index ? (
                            <div>
                              <Button
                                className="btn-success mr-1"
                                size="sm"
                                onClick={() => {
                                  handleSaveNewNote(image);
                                }}
                              >
                                Salvar
                              </Button>
                              <Button
                                className="btn-danger"
                                size="sm"
                                onClick={() => {
                                  setEditNote({ edit: false, index: -1 });
                                }}
                              >
                                Cancelar
                              </Button>
                            </div>
                          ) : (
                            <Button
                              className="bg-system"
                              size="sm"
                              onClick={() => {
                                handleEditNewNote(image.note, index);
                              }}
                            >
                              Editar Campo
                            </Button>
                          )}
                        </Navbar>
                        {editNote.edit && editNote.index === index ? (
                          <Form.Control
                            placeholder="Anotações"
                            as="textarea"
                            rows="3"
                            value={newNote}
                            onChange={(e) => setNewNote(e.target.value)}
                          />
                        ) : (
                          <p className="mx-3 mb-5">{image.note}</p>
                        )}
                      </div>
                    )}
                  </p>
                </Card.Title>
                <Card.Text>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={
                      "https://www.google.com/maps?&z=10&q=loc:" +
                      image.latitude +
                      "+" +
                      image.longitude +
                      "&||=" +
                      image.latitude +
                      "+" +
                      image.longitude
                    }
                  >
                    Vá para o Mapa
                  </a>
                </Card.Text>
              </Card.Body>
            </Card>
          ))}
        </>
      )}
      <Row className="my-3">
        <Button
          size="sm"
          variant="outline-dark"
          className="mx-auto"
          onClick={() => {
            printRecommention();
          }}
          // onClick={() => {
          //   setShowNewRecommendationDocModal({
          //     visit,
          //     field,
          //     harvest,
          //     recommendation,
          //     TableRecommendationType,
          //     show: true,
          //   });
          // }}
        >
          Exportar Recomendação
        </Button>
      </Row>
    </Container>
  );
}

export default JumboRecommendations;
