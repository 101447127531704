import React, { useState } from "react";

import { Container, Modal, Button, Form } from "react-bootstrap";

import { toast } from "react-toastify";
import { FaPlusCircle } from "react-icons/fa";

import { dataSchema } from "../../../functions/harvest";

import { addHarvest } from "../../../controllers/ControllerHarvest";

import TableSearchClient from "../modalComponents/tableSearchClient";
import TableSearchProperty from "../modalComponents/tableSearchProperty";
import TableSearchField from "../modalComponents/tableSearchField";
import TableSearchCultivation from "../modalComponents/tableSearchCultivation";
import TableSearchCultivationVarieties from "../modalComponents/tableSearchCultivationVarieties";

import FormAddHarvest from "../modalComponents/formAddHarvest";

import moment from "moment";
import "moment/locale/pt-br";

function AddHarvest(props) {
  const {
    showAddHarvestModal,
    setShowAddHarvestModal,
    code3c_id,
    getData,
  } = props;

  const [harvestYearStart, setHarvestYearStart] = useState("");

  const [note, setNote] = useState("");

  const [validated, setValidated] = useState(false);
  const [formErr, setFormErr] = useState([]);

  const [inputSearch, setInputSearch] = useState("");

  const [client_id, setClient_id] = useState("");
  const [client_name, setClient_name] = useState("");
  const [property_id, setProperty_id] = useState("");
  const [property_name, setProperty_name] = useState("");
  const [cultivation_id, setCultivation_id] = useState("");
  const [cultivation_name, setCultivation_name] = useState("");
  const [cultivationVarieties_id, setCultivationVarieties_id] = useState("");
  const [cultivationVarieties_name, setCultivationVarieties_name] = useState(
    "",
  );
  const [fields, setFields] = useState([]);
  const [showFields, setShowFields] = useState(false);

  const [pendingRequest, setPendingRequest] = useState(false);

  async function handleClient(client_id, client_name) {
    setClient_id(client_id);
    setClient_name(client_name);
  }

  function clearClient() {
    setClient_id("");
    setClient_name("");
    setProperty_id("");
    setProperty_name("");
  }

  async function handleProperty(property_id, property_name) {
    setProperty_id(property_id);
    setProperty_name(property_name);
  }

  function clearProperty() {
    setCultivation_id("");
    setCultivation_name("");
    setProperty_id("");
    setProperty_name("");
  }

  async function handleCultivation(cultivation_id, cultivation_name) {
    setCultivation_id(cultivation_id);
    setCultivation_name(cultivation_name);
  }

  function clearCultivation() {
    setCultivationVarieties_id("");
    setCultivationVarieties_name("");
    setCultivation_id("");
    setCultivation_name("");
  }

  async function handleCultivationVarieties(
    cultivationVarieties_id,
    cultivationVarieties_name,
  ) {
    setCultivationVarieties_id(cultivationVarieties_id);
    setCultivationVarieties_name(cultivationVarieties_name);
  }

  function clearCultivationVarieties() {
    setCultivationVarieties_id("");
    setCultivationVarieties_name("");
  }

  function clearAll() {
    setCultivationVarieties_id("");
    setCultivationVarieties_name("");
    setCultivation_id("");
    setCultivation_name("");
    setProperty_id("");
    setProperty_name("");
    setClient_id("");
    setClient_name("");
  }

  function insertField(field) {
    setFields([...fields, field]);
  }

  function removeField(field_id) {
    setFields(fields.filter((item) => field_id !== item.field_id));
  }

  function clearFields() {
    setFormErr([]);
    setHarvestYearStart("");
    setFields([]);
    setNote("");
    setValidated(false);
    setPendingRequest(false);
    setShowAddHarvestModal(false);
  }

  function handleClose() {
    clearFields();
  }

  async function handleAddHarvest() {
    const data = {
      code3c_id,
      client_id,
      client_name,
      property_id,
      property_name,
      cultivation_id,
      cultivation_name,
      cultivationVarieties_id,
      cultivationVarieties_name,
      fields,
      harvestYearStart:
        harvestYearStart === "" ? "" : new Date(moment(harvestYearStart)),
      note,
    };

    dataSchema
      .validate(data, { abortEarly: false })
      .then(async function () {
        setFormErr([]);
        setValidated(true);
        const response = await addHarvest(data);
        if (response) {
          getData();
        }
        clearFields();
      })
      .catch(function (err) {
        console.log(err.inner);
        setPendingRequest(false);
        setFormErr(err.inner);
        toast.error("Existem campos com erros.");
      });
  }

  function catchErrors(errorHarvest) {
    return (
      <Form.Control.Feedback type="invalid">
        {formErr.map((err) => {
          if (err.path === errorHarvest) {
            return <p>{err.errors}</p>;
          }
          return null;
        })}
      </Form.Control.Feedback>
    );
  }

  function catchInvalid(errorHarvest) {
    if (formErr.filter((err) => err.path === errorHarvest).length >= 1) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <Container className="ubuntuFont">
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        className="ubuntuFont"
        show={showAddHarvestModal}
        onHide={handleClose}
        centered
      >
        <Modal.Header className="text-center" closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-center"
          >
            <FaPlusCircle className="text-success" />
            &emsp;Nova Safra
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {cultivationVarieties_id === "" && (
            <Form.Control
              type="text"
              placeholder={
                client_id === ""
                  ? "Pesquisar Produtor"
                  : property_id === ""
                  ? "Pesquisar Proprietário"
                  : cultivation_id === ""
                  ? "Pesquisar Cultura"
                  : cultivationVarieties_id === ""
                  ? "Pesquisar Variação/Híbrido"
                  : ""
              }
              size="sm"
              className="mb-3 px-3"
              value={inputSearch}
              onChange={(e) => setInputSearch(e.target.value)}
              required
            />
          )}
          <Container className="table-max-height">
            {client_id === "" ? (
              <TableSearchClient
                code3c_id={code3c_id}
                client_id={client_id}
                inputSearch={inputSearch}
                handleClient={handleClient}
                clearClient={clearClient}
                showAddHarvestModal={showAddHarvestModal}
              />
            ) : property_id === "" ? (
              <TableSearchProperty
                client_id={client_id}
                property_id={property_id}
                inputSearch={inputSearch}
                handleProperty={handleProperty}
                clearClient={clearClient}
                showAddHarvestModal={showAddHarvestModal}
              />
            ) : cultivation_id === "" ? (
              <TableSearchCultivation
                code3c_id={code3c_id}
                client_id={client_id}
                property_id={property_id}
                cultivation_id={cultivation_id}
                inputSearch={inputSearch}
                handleCultivation={handleCultivation}
                clearProperty={clearProperty}
                showAddHarvestModal={showAddHarvestModal}
              />
            ) : cultivationVarieties_id === "" ? (
              <TableSearchCultivationVarieties
                code3c_id={code3c_id}
                client_id={client_id}
                property_id={property_id}
                cultivation_id={cultivation_id}
                cultivationVarieties_id={cultivationVarieties_id}
                inputSearch={inputSearch}
                handleCultivationVarieties={handleCultivationVarieties}
                clearCultivation={clearCultivation}
                showAddHarvestModal={showAddHarvestModal}
              />
            ) : showFields ? (
              <TableSearchField
                property_id={property_id}
                inputSearch={inputSearch}
                insertField={insertField}
                removeField={removeField}
                fields={fields}
                staticFields={[]}
                setShowFields={setShowFields}
              />
            ) : (
              <FormAddHarvest
                clearCultivationVarieties={clearCultivationVarieties}
                clearAll={clearAll}
                client_name={client_name}
                property_name={property_name}
                cultivation_name={cultivation_name}
                cultivationVarieties_name={cultivationVarieties_name}
                harvestYearStart={harvestYearStart}
                setHarvestYearStart={setHarvestYearStart}
                note={note}
                setNote={setNote}
                fields={fields}
                setShowFields={setShowFields}
                catchErrors={catchErrors}
                catchInvalid={catchInvalid}
                validated={validated}
              />
            )}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          {!showFields && cultivationVarieties_id !== "" && (
            <Button
              disabled={pendingRequest ? true : false}
              variant="success"
              className="mr-2"
              onClick={() => handleAddHarvest() + setPendingRequest(true)}
            >
              Salvar
            </Button>
          )}
          <Button variant="danger" onClick={() => handleClose()}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default AddHarvest;
