import React, { useState, useEffect } from "react";

import { Container, Modal, Button, Form, Col } from "react-bootstrap";

import { toast } from "react-toastify";
import { FaEdit, FaToggleOff, FaToggleOn } from "react-icons/fa";

import { dataSchema } from "../../../functions/client";
import { validateBr, maskBr } from "js-brasil";

import {
  editClient,
  searchEditSocialIDClient,
} from "../../../controllers/ControllerClient";
import { consultCep } from "../../../controllers/ControllerCep";

function EditClient(props) {
  const {
    showEditClientModal,
    setShowEditClientModal,
    getData,
    code3c_id,
  } = props;

  const [name, setName] = useState("");
  const [socialID, setSocialID] = useState("");
  const [stateSubscription, setStateSubscription] = useState(" ");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [neighborhood, setNeighborhood] = useState("");
  const [street, setStreet] = useState("");
  const [houseNumber, setHouseNumber] = useState("");

  const [docType, setDocType] = useState(true);

  const [validated, setValidated] = useState(false);
  const [formErr, setFormErr] = useState([]);
  const [staticData, setStaticData] = useState({});

  const [pendingRequest, setPendingRequest] = useState(false);

  function clearFields() {
    setFormErr([]);
    setName("");
    setSocialID("");
    setStateSubscription(" ");
    setPhone("");
    setEmail("");
    setPostalCode("");
    setState("");
    setCity("");
    setNeighborhood("");
    setStreet("");
    setHouseNumber("");
    setPendingRequest(false);
    setValidated(false);
    setShowEditClientModal({ show: false });
  }

  function handleClose() {
    clearFields();
  }

  async function getAddress(CEP) {
    setPostalCode(CEP);
    if (CEP.replace("-", "").length === 8) {
      const AddressInfo = await consultCep(CEP);
      setPostalCode(AddressInfo.cep);
      setState(AddressInfo.uf);
      setCity(AddressInfo.localidade);
      setNeighborhood(AddressInfo.bairro);
      setStreet(AddressInfo.logradouro);
    }
  }

  function formatPhone(Phone) {
    setPhone(Phone);
    if (validateBr.telefone(Phone) && Phone.length >= 10) {
      setPhone(maskBr.telefone(Phone));
    } else if (!validateBr.telefone(Phone)) {
      setPhone(Phone.replace(/[&\\#,+()$~%.'":*?<>{}-\s/]/g, ""));
    }
  }

  function changeDocType() {
    setDocType(!docType);
    setSocialID("");
  }

  function formatSocialID(CNPJ_CPF) {
    if (docType) {
      setSocialID(CNPJ_CPF);
      if (validateBr.cnpj(CNPJ_CPF) && CNPJ_CPF.length === 14) {
        setSocialID(maskBr.cnpj(CNPJ_CPF));
      } else if (!validateBr.cnpj(CNPJ_CPF)) {
        setSocialID(CNPJ_CPF.replace(/[&\\#,+()$~%.'":*?<>{}-\s/]/g, ""));
      }
    } else {
      setSocialID(CNPJ_CPF);
      if (validateBr.cpf(CNPJ_CPF) && CNPJ_CPF.length === 11) {
        setSocialID(maskBr.cpf(CNPJ_CPF));
      } else if (!validateBr.cpf(CNPJ_CPF)) {
        setSocialID(CNPJ_CPF.replace(/[&\\#,+()$~%.'":*?<>{}-\s/]/g, ""));
      }
    }
  }

  async function handleEditClient() {
    const data = {
      typeOf: "complexClient",
      code3c_id,
      name,
      socialID: socialID.replace(/[&\\#,+()$~%.'":*?<>{}-\s/]/g, ""),
      stateSubscription,
      phone: phone.replace(/[&\\#,+()$~%.'":*?<>{}-\s/]/g, ""),
      email,
      address: {
        postalCode,
        state,
        city,
        neighborhood,
        street,
        houseNumber,
      },
    };

    dataSchema
      .validate(data, { abortEarly: false, context: { docType: docType } })
      .then(async function () {
        if (JSON.stringify(staticData) !== JSON.stringify(data)) {
          const exists = await searchEditSocialIDClient(
            code3c_id,
            data.socialID,
            showEditClientModal.id,
          );
          if (!exists) {
            const response = await editClient(data, showEditClientModal.id);
            if (response) {
              getData(code3c_id);
              clearFields();
              setPendingRequest(false);
              setFormErr([]);
            }
          } else {
            setFormErr([]);
            setValidated(true);
            setPendingRequest(false);
            toast.warn("Esse produtor já possui cadastro!");
          }
        } else {
          clearFields();
        }
      })
      .catch(function (err) {
        setFormErr(err.inner);
        setPendingRequest(false);
        toast.error("Existem campos com erros.");
      });
  }

  function catchErrors(errorField) {
    return (
      <Form.Control.Feedback type="invalid">
        {formErr.map((err) => {
          if (err.path === errorField) {
            return <p>{err.errors}</p>;
          }
          return null;
        })}
      </Form.Control.Feedback>
    );
  }

  function catchInvalid(errorField) {
    if (formErr.filter((err) => err.path === errorField).length >= 1) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    if (showEditClientModal.name) {
      setName(showEditClientModal.name);
      setSocialID(
        showEditClientModal.socialID.length === 11
          ? maskBr.cpf(showEditClientModal.socialID)
          : maskBr.cnpj(showEditClientModal.socialID),
      );
      setStateSubscription(showEditClientModal.stateSubscription);
      setPhone(maskBr.telefone(showEditClientModal.phone));
      setEmail(showEditClientModal.email);
      setPostalCode(showEditClientModal.postalCode);
      setState(showEditClientModal.state);
      setCity(showEditClientModal.city);
      setNeighborhood(showEditClientModal.neighborhood);
      setStreet(showEditClientModal.street);
      setHouseNumber(showEditClientModal.houseNumber);
      setDocType(showEditClientModal.socialID.length === 11 ? false : true);

      setStaticData({
        typeOf: "complexClient",
        code3c_id: code3c_id,
        name: showEditClientModal.name,
        socialID: showEditClientModal.socialID,
        stateSubscription: showEditClientModal.stateSubscription,
        phone: showEditClientModal.phone,
        email: showEditClientModal.email,
        address: {
          postalCode: showEditClientModal.postalCode,
          state: showEditClientModal.state,
          city: showEditClientModal.city,
          neighborhood: showEditClientModal.neighborhood,
          street: showEditClientModal.street,
          houseNumber: showEditClientModal.houseNumber,
        },
      });
    }
    // eslint-disable-next-line
  }, [showEditClientModal]);

  return (
    <Container className="ubuntuFont">
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        className="ubuntuFont"
        show={showEditClientModal.show}
        onHide={handleClose}
        centered
      >
        <Modal.Header className="text-center" closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-center"
          >
            <FaEdit className="text-primary" />
            &emsp;Editar Produtor : {showEditClientModal.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated}>
            <Form.Group>
              <Form.Label>Nome do Produtor</Form.Label>
              <Form.Control
                required
                isInvalid={catchInvalid("name")}
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value.toUpperCase())}
              />
              {catchErrors("name")}
            </Form.Group>
            <Form.Group>
              <Form.Label>
                {docType ? (
                  <>
                    <h4>
                      <FaToggleOff
                        className="text-system link"
                        onClick={() => changeDocType()}
                      />
                      &nbsp;CNPJ
                    </h4>
                  </>
                ) : (
                  <>
                    <h4>
                      <FaToggleOn
                        className="text-system link"
                        onClick={() => changeDocType()}
                      />
                      &nbsp;CPF
                    </h4>
                  </>
                )}
              </Form.Label>
              <Form.Control
                type="text"
                value={socialID}
                isInvalid={catchInvalid("socialID")}
                maxLength={docType ? "18" : "14"}
                onChange={(e) => formatSocialID(e.target.value)}
              />
              {catchErrors("socialID")}
            </Form.Group>
            <Form.Group>
              <Form.Label>Inscrição Estadual</Form.Label>
              <Form.Control
                type="text"
                isInvalid={catchInvalid("stateSubscription")}
                value={stateSubscription}
                maxLength="10"
                onChange={(e) => setStateSubscription(e.target.value)}
              />
              {catchErrors("stateSubscription")}
            </Form.Group>
            <Form.Text className="text-muted text-center mb-3">
              Informações de Contato.
            </Form.Text>
            <Form.Row>
              <Form.Group as={Col} md="6">
                <Form.Label>Telefone</Form.Label>
                <Form.Control
                  type="text"
                  isInvalid={catchInvalid("phone")}
                  value={phone}
                  onChange={(e) => formatPhone(e.target.value)}
                />
                {catchErrors("phone")}
              </Form.Group>
              <Form.Group as={Col} md="6">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  isInvalid={catchInvalid("email")}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {catchErrors("email")}
              </Form.Group>
            </Form.Row>
            <Form.Text className="text-muted text-center mb-3">
              Informações de Endereço.
            </Form.Text>
            <Form.Row>
              <Form.Group as={Col} md="6">
                <Form.Label>CEP</Form.Label>
                <Form.Control
                  type="text"
                  maxLength="9"
                  value={postalCode}
                  isInvalid={catchInvalid("address.postalCode")}
                  onChange={(e) => getAddress(e.target.value)}
                />
                {catchErrors("address.postalCode")}
              </Form.Group>
              <Form.Group as={Col} md="6">
                <Form.Label>Estado</Form.Label>
                <Form.Control
                  disabled
                  placeholder="Insira o CEP"
                  type="text"
                  value={state}
                  isInvalid={catchInvalid("address.state")}
                  onChange={(e) => setState(e.target.value)}
                />
                {catchErrors("address.state")}
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="6">
                <Form.Label>Cidade</Form.Label>
                <Form.Control
                  disabled
                  placeholder="Insira o CEP"
                  type="text"
                  value={city}
                  isInvalid={catchInvalid("address.city")}
                  onChange={(e) => setCity(e.target.value)}
                />
                {catchErrors("address.city")}
              </Form.Group>
              <Form.Group as={Col} md="6">
                <Form.Label>Bairro</Form.Label>
                <Form.Control
                  type="text"
                  value={neighborhood}
                  isInvalid={catchInvalid("address.neighborhood")}
                  onChange={(e) => setNeighborhood(e.target.value)}
                />
                {catchErrors("address.neighborhood")}
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="10">
                <Form.Label>Rua</Form.Label>
                <Form.Control
                  type="text"
                  value={street}
                  isInvalid={catchInvalid("address.street")}
                  onChange={(e) => setStreet(e.target.value)}
                />
                {catchErrors("address.street")}
              </Form.Group>
              <Form.Group as={Col} md="2">
                <Form.Label>Nº</Form.Label>
                <Form.Control
                  type="text"
                  value={houseNumber}
                  isInvalid={catchInvalid("address.houseNumber")}
                  onChange={(e) => setHouseNumber(e.target.value)}
                />
                {catchErrors("address.houseNumber")}
              </Form.Group>
            </Form.Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            className="mr-2"
            disabled={pendingRequest ? true : false}
            onClick={() => handleEditClient() + setPendingRequest(true)}
          >
            Salvar
          </Button>
          <Button variant="danger" onClick={() => handleClose()}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default EditClient;
