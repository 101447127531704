import { firebaseDB } from "../configurations/firebaseConfig";
import { toast } from "react-toastify";

const response = firebaseDB.collection("agroVisits");

async function addVisit(data) {
  try {
    const dataDB = await response.add(data);
    toast.success("A Visita foi adicionada!", 3000);
    return dataDB.id;
  } catch (err) {
    console.log(err);
    toast.error("Parece que tem algo errado com seu cadastro!", 3000);
  }
}

async function getVisits(id) {
  try {
    const data = await response.where("code3c_id", "==", id).get();
    const allData = [];
    data.docs.forEach((item) => {
      allData.push({ ...item.data(), id: item.id });
    });
    return allData;
  } catch (err) {
    console.log(err);
    toast.error("Não foi possível ler os dados.");
  }
}

async function searchVisit(id) {
  try {
    const data = await response.doc(id).get();
    return { ...data.data(), id: id };
  } catch (err) {
    console.log(err);
    toast.error("Não foi possível ler os dados.");
  }
}

async function editVisit(data, id) {
  try {
    const dataDBExists = await response.doc(id).get();
    if (!dataDBExists) {
      return [];
    } else {
      await response.doc(id).set(data);
      toast.success("A Visita foi editado!", 3000);
      return true;
    }
  } catch (err) {
    console.log(err);
    toast.error("Parece que tem algo errado com sua edição!", 3000);
  }
}

async function editNoteVisit(data, id) {
  try {
    const dataDBExists = await response.doc(id).get();
    if (!dataDBExists) {
      return [];
    } else {
      await response.doc(id).update(data);
      console.log(data);
      toast.success("A Visita foi editado!", 3000);
      return true;
    }
  } catch (err) {
    console.log(err);
    toast.error("Parece que tem algo errado com sua edição!", 3000);
  }
}

async function deleteVisit(name, id) {
  try {
    await response.doc(id).delete();
    toast.success("A Visita foi apagada!", 3000);
    return true;
  } catch (err) {
    console.log(err);
    toast.error("Parece que tem algo errado com sua edição!", 3000);
  }
}

export {
  addVisit,
  getVisits,
  searchVisit,
  editVisit,
  editNoteVisit,
  deleteVisit,
};
