import React, { useState } from "react";

import { Container, Modal, Button, Form } from "react-bootstrap";

import { toast } from "react-toastify";
import { FaPlusCircle } from "react-icons/fa";

import { dataSchema } from "../../../functions/field";

import { addField } from "../../../controllers/ControllerField";

function AddField(props) {
  const {
    showAddFieldModal,
    setShowAddFieldModal,
    fieldList,
    setFieldList,
    code3c_id,
    client_id,
    client_name,
    property_id,
    property_name,
    // fieldNumberList,
    getData,
  } = props;

  const [name, setName] = useState("");

  const [fieldNumber, setFieldNumber] = useState(0);
  const [fieldArea, setFieldArea] = useState(0);

  const [validated, setValidated] = useState(false);
  const [formErr, setFormErr] = useState([]);

  const [pendingRequest, setPendingRequest] = useState(false);

  function clearFields() {
    setFormErr([]);
    setName("");
    setFieldNumber(0);
    setFieldArea(0);
    setValidated(false);
    setPendingRequest(false);
    setShowAddFieldModal(false);
    getData();
  }

  function handleClose() {
    clearFields();
  }

  async function handleAddField() {
    const data = {
      code3c_id,
      client_id,
      client_name,
      property_id,
      property_name,
      name,
      fieldNumber: parseInt(fieldNumber),
      fieldArea: parseFloat(fieldArea),
    };

    dataSchema
      .validate(data, {
        abortEarly: false,
        // context: { fieldNumberList: fieldNumberList },
      })
      .then(async function () {
        setFormErr([]);
        setValidated(true);
        const response = await addField(data);
        setFieldList([...fieldList, { id: response, ...data }]);
        clearFields();
      })
      .catch(function (err) {
        if (err.inner) {
          console.log(err.inner);
          setFormErr(err.inner);
        } else {
          console.log(err);
          setFormErr([]);
        }
        setPendingRequest(false);
        toast.error("Existem campos com erros.");
      });
  }

  function catchErrors(errorField) {
    return (
      <Form.Control.Feedback type="invalid">
        {formErr.map((err) => {
          if (err.path === errorField) {
            return <p>{err.errors}</p>;
          }
          return null;
        })}
      </Form.Control.Feedback>
    );
  }

  function catchInvalid(errorField) {
    if (formErr.filter((err) => err.path === errorField).length >= 1) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <Container className="ubuntuFont">
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        className="ubuntuFont"
        show={showAddFieldModal}
        onHide={handleClose}
        centered
      >
        <Modal.Header className="text-center" closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-center"
          >
            <FaPlusCircle className="text-success" />
            &emsp;Nova Propriedade
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated}>
            <Form.Group>
              <Form.Label>Nome do Talhão</Form.Label>
              <Form.Control
                required
                isInvalid={catchInvalid("name")}
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value.toUpperCase())}
              />
              {catchErrors("name")}
            </Form.Group>
            <Form.Group>
              <Form.Label>Número do Talhão</Form.Label>
              <Form.Control
                required
                isInvalid={catchInvalid("fieldNumber")}
                type="number"
                value={fieldNumber}
                onChange={(e) => setFieldNumber(e.target.value)}
              />
              {catchErrors("fieldNumber")}
            </Form.Group>
            <Form.Group>
              <Form.Label>Área do Talhão (ha)</Form.Label>
              <Form.Control
                required
                isInvalid={catchInvalid("fieldArea")}
                type="number"
                value={fieldArea}
                onChange={(e) => setFieldArea(e.target.value)}
              />
              <Form.Text className="text-muted">
                Insira a área do seu talhão em ha (hectares), precisa ser maior
                que 0 (zero).
              </Form.Text>
              {catchErrors("fieldArea")}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={pendingRequest ? true : false}
            variant="success"
            className="mr-2"
            onClick={() => handleAddField() + setPendingRequest(true)}
          >
            Salvar
          </Button>
          <Button variant="danger" onClick={() => handleClose()}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default AddField;
