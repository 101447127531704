import React from "react";

import { Table, Button } from "react-bootstrap";

import { parsePesticideType } from "../../../functions/pesticide";

function TablePesticide(props) {
  const {
    pesticideList,
    inputSearch,
    inputFilter,
    setShowEditPesticideModal,
    setShowDeletePesticideModal,
    toggleSpinner,
  } = props;
  return (
    <Table striped bordered hover size="sm" className="rounded">
      <thead>
        <tr>
          <th width="2%">#</th>
          <th width="63%">Defensivo</th>
          <th width="15%" className="text-center">
            Tipo
          </th>
          <th width="10%" className="text-center">
            Editar
          </th>
          <th width="10%" className="text-center">
            Apagar
          </th>
        </tr>
      </thead>
      <tbody>
        {pesticideList.length === 0 && toggleSpinner === true && (
          <tr>
            <td width="100%" colSpan="6" className="align-middle text-center">
              Nenhum Defensivo foi Encontrado
            </td>
          </tr>
        )}
        {pesticideList
          .sort(function (a, b) {
            var nameA = a.name.toLowerCase(),
              nameB = b.name.toLowerCase();
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
          })
          .filter(
            (pesticideType) =>
              parsePesticideType(pesticideType.typeOf)
                .toUpperCase()
                .indexOf(inputFilter.toUpperCase()) !== -1,
          )
          .filter(
            (item) =>
              item.id.indexOf(inputSearch) !== -1 ||
              item.name.toUpperCase().indexOf(inputSearch.toUpperCase()) !== -1,
          )
          .map((pesticide, index) => (
            <tr key={index}>
              <td width="2%" className="align-middle">
                <b>{index + 1}</b>
              </td>
              <td width="63%" className="align-middle">
                {pesticide.name}
              </td>
              <td width="15%" className="text-center  align-middle">
                {parsePesticideType(pesticide.typeOf)}
              </td>
              <td width="10%" className="text-center align-middle">
                <Button
                  className="bg-system"
                  size="sm"
                  onClick={() =>
                    setShowEditPesticideModal({
                      id: pesticide.id,
                      name: pesticide.name,
                      typeOf: pesticide.typeOf,
                      show: true,
                    })
                  }
                >
                  Editar
                </Button>
              </td>
              <td width="10%" className="text-center align-middle">
                <Button
                  variant="outline-danger"
                  size="sm"
                  onClick={() =>
                    setShowDeletePesticideModal({
                      id: pesticide.id,
                      name: pesticide.name,
                      show: true,
                    })
                  }
                >
                  Apagar
                </Button>
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
}

export default TablePesticide;
