import React, { useEffect, useState, useContext } from "react";

import {
  Container,
  Row,
  Spinner,
  Navbar,
  Button,
  Form,
  FormControl,
} from "react-bootstrap";

import {
  FaPlusCircle,
  FaFilter,
  FaListOl,
  FaRegCalendarAlt,
} from "react-icons/fa";

import TableVisit from "../../components/visit/pageComponents/tableVisit";
import CalendarVisit from "../../components/visit/pageComponents/calendarVisit";

import AddVisit from "../../components/visit/pageModals/addVisit";
import EditVisit from "../../components/visit/pageModals/editVisit";
import DeleteVisit from "../../components/visit/pageModals/deleteVisit";

import FilterVisitor from "../../components/visit/pageModals/filterVisitor";

import { getVisits } from "../../controllers/ControllerVisit";

import { AuthContext } from "../../configurations/authorization";

import moment from "moment";
import "moment/locale/pt-br";

function Visit() {
  const { code3cData } = useContext(AuthContext);

  const [showCalendar, setShowCalendar] = useState(true);

  const [visitList, setVisitList] = useState([]);
  const [visitEvents, setVisitEvents] = useState([]);
  const [visitsFilter, setVisitsFilter] = useState("");

  const [inputSearch, setInputSearch] = useState("");
  const [inputFilter, setInputFilter] = useState("");

  const [toggleSpinner, setToggleSpinner] = useState(false);

  const [showAddVisitModal, setShowAddVisitModal] = useState({ show: false });
  const [showEditVisitModal, setShowEditVisitModal] = useState({
    show: false,
  });
  const [showDeleteVisitModal, setShowDeleteVisitModal] = useState({
    show: false,
  });

  const [showFilterVisitorModal, setShowFilterVisitorModal] = useState(false);

  function handleVisitor(types) {
    setInputFilter(types);
    setShowFilterVisitorModal(false);
  }
  function clearVisitor(types) {
    setInputFilter("");
    setShowFilterVisitorModal(false);
  }

  async function getData() {
    const dataResponse = await getVisits(code3cData.id);
    if (dataResponse) {
      setVisitList(dataResponse);
      setVisitEvents(
        dataResponse.map((visit) => {
          if (visit.endVisit) {
            return {
              className: ["link", "bg-system2"],
              id: visit.id,
              title: visit.property_name,
              start: moment(visit.visitDate.toDate()).format("YYYY-MM-DD"),
              visitDate: visit.visitDate,
              endVisit: visit.endVisit,
              client_id: visit.client_id,
              client_name: visit.client_name,
              property_id: visit.property_id,
              property_name: visit.property_name,
              property_imageLight: visit.property_imageLight,
              property_imageHeavy: visit.property_imageHeavy,
              user_id: visit.user_id,
              user_name: visit.user_name,
              show: true,
            };
          } else {
            return {
              className: ["link", "bg-system"],
              id: visit.id,
              title: visit.property_name,
              start: moment(visit.visitDate.toDate()).format("YYYY-MM-DD"),
              visitDate: visit.visitDate,
              client_id: visit.client_id,
              client_name: visit.client_name,
              property_id: visit.property_id,
              property_name: visit.property_name,
              property_imageLight: visit.property_imageLight,
              property_imageHeavy: visit.property_imageHeavy,
              user_id: visit.user_id,
              user_name: visit.user_name,
              show: true,
            };
          }
        })
      );
      setToggleSpinner(true);
    }
  }
  function handleTypeFilter(item) {
    setVisitsFilter(item.target.value);
  }

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  return (
    <Container
      className="rounded bg-white my-3 pb-2 px-0 ubuntuFont"
      style={{ height: " 475.4px" }}
    >
      <AddVisit
        showAddVisitModal={showAddVisitModal}
        setShowAddVisitModal={setShowAddVisitModal}
        code3c_id={code3cData.id}
        getData={getData}
      />
      <EditVisit
        showEditVisitModal={showEditVisitModal}
        setShowEditVisitModal={setShowEditVisitModal}
        getData={getData}
        showCalendar={showCalendar}
        setShowDeleteVisitModal={setShowDeleteVisitModal}
        code3c_id={code3cData.id}
      />
      <DeleteVisit
        showDeleteVisitModal={showDeleteVisitModal}
        setShowDeleteVisitModal={setShowDeleteVisitModal}
        getData={getData}
        showCalendar={showCalendar}
        showEditVisitModal={showEditVisitModal}
        setShowEditVisitModal={setShowEditVisitModal}
        code3c_id={code3cData.id}
      />

      <FilterVisitor
        showFilterVisitorModal={showFilterVisitorModal}
        setShowFilterVisitorModal={setShowFilterVisitorModal}
        handleVisitor={handleVisitor}
        clearVisitor={clearVisitor}
        inputFilter={inputFilter}
        code3c_id={code3cData.id}
      />

      <h2 className="text-center py-3">
        VISITAS&emsp;
        <Button
          size="sm"
          variant="outline-secondary"
          onClick={() => setShowCalendar(!showCalendar)}
        >
          {showCalendar ? <FaListOl /> : <FaRegCalendarAlt />}
        </Button>
      </h2>
      <hr className="mt-0" />
      {showCalendar ? (
        <CalendarVisit
          visitEvents={visitEvents}
          setShowAddVisitModal={setShowAddVisitModal}
          setShowEditVisitModal={setShowEditVisitModal}
        />
      ) : (
        <>
          <Navbar
            bg="white"
            variant="white"
            className="mb-3 justify-content-between"
          >
            <Navbar.Brand className="link">
              <Button
                variant="outline-success"
                onClick={() => {
                  setShowAddVisitModal({ show: true });
                }}
              >
                <FaPlusCircle className="text-outline-success" />
                &nbsp; Nova Visita
              </Button>
              <Button
                variant="outline-primary"
                className="mx-1"
                onClick={() => setShowFilterVisitorModal(true)}
              >
                <FaFilter className="text-outline-primary" />
                &nbsp; {inputFilter === "" ? "Filtrar Visitante" : inputFilter}
              </Button>
            </Navbar.Brand>
            <Form inline className="justify-content-end">
              <Form.Control
                as="select"
                className="mx-1"
                onClick={handleTypeFilter.bind(this)}
              >
                <option value={""}>Todas as Visitas</option>
                <option value={"nFinished"}>Visitas Não Finalizadas</option>
                <option value={"Finished"}>Visitas Finalizadas</option>
              </Form.Control>
              <FormControl
                type="text"
                className="mx-1"
                placeholder="Pesquisar defensivo"
                value={inputSearch}
                onChange={(e) => setInputSearch(e.target.value)}
              />
            </Form>
          </Navbar>
          <Container className="table-max-height">
            {visitList === 0 && !toggleSpinner ? (
              <Row>
                <Spinner
                  className="mx-auto my-5 text-system"
                  animation="border"
                />
              </Row>
            ) : (
              <TableVisit
                visitList={visitList}
                inputSearch={inputSearch}
                inputFilter={inputFilter}
                visitsFilter={visitsFilter}
                setShowEditVisitModal={setShowEditVisitModal}
                setShowDeleteVisitModal={setShowDeleteVisitModal}
              />
            )}
          </Container>
        </>
      )}
    </Container>
  );
}

export default Visit;
