import React, { useContext } from "react";

import { Container, Row, Col, Spinner } from "react-bootstrap";

import { AuthContext } from "../../configurations/authorization";

import logo from "../../assets/imgs/logo-dashboard.png";

function Dashboard() {
  const { code3cData, userData, systemData, system3cData } = useContext(
    AuthContext,
  );

  return (
    <Container
      className="rounded bg-white my-3 pb-2 px-0 ubuntuFont"
      style={{ height: " 475.4px" }}
    >
      <h2 className="text-center py-3">DASHBOARD</h2>
      <hr className="mt-0" />
      <Container className="mx-auto px-5">
        {code3cData.id === "" ? (
          <Row className="my-auto">
            <Spinner className="mx-auto my-5 text-system" animation="border" />
          </Row>
        ) : (
          <>
            <Row>
              <Col>
                <h1 className="text-center">
                  <Container className="border-0">
                    <img
                      alt="Logo"
                      className="mx-auto"
                      src={logo}
                      style={{ width: "128px" }}
                    />
                  </Container>
                </h1>
              </Col>
            </Row>
            <Row>
              <Col className="text-center">
                <>
                  <h4>Bem Vindo </h4>
                  <h4 className="text-system">{userData.name}</h4>
                </>
              </Col>
            </Row>
            <Row className="justify-content-md-center my-4">
              <Col xs lg="3">
                Empresa: <b>{code3cData.name}.</b>
              </Col>
              <Col xs lg="3">
                Cargo:{" "}
                <b>
                  {systemData.function === "administrator"
                    ? "ADMINISTRADOR"
                    : systemData.function === "visitor"
                    ? "VISITANTE"
                    : "DESENVOLVEDOR"}
                </b>
                .
              </Col>
            </Row>
            {systemData.function === "administrator" && (
              <>
                <Row className="justify-content-md-center  my-4">
                  <Col xs lg="3">
                    Nº Do Cliente: <b>{code3cData.id}.</b>
                  </Col>
                  <Col xs lg="3">
                    Plano:{" "}
                    <b>{system3cData.systemBilling.systemAccounts} USUÁRIOS.</b>
                  </Col>
                </Row>
                <Row className="my-4">
                  <Col className="mx-auto"></Col>
                </Row>
              </>
            )}
          </>
        )}
      </Container>
    </Container>
  );
}

export default Dashboard;
