import * as yup from "yup";
import { pt } from "yup-locale-pt";

yup.setLocale(pt);

const dataSchema = yup.object().shape({
  code3c_id: yup.string().min(1).required(),
  client_id: yup.string().min(1).required(),
  client_name: yup.string().min(1).required(),
  property_id: yup.string().min(1).required(),
  property_name: yup.string().min(1).required(),
  cultivation_id: yup.string().required(),
  cultivation_name: yup.string().required(),
  cultivationVarieties_id: yup.string().required(),
  cultivationVarieties_name: yup.string().required(),
  fields: yup.array().required(),
  harvestYearStart: yup
    .date()
    .label("Data de Início")
    .typeError("Você precisa selecionar uma data válida.")
    .required(),
  harvestYearEnd: yup
    .date()
    .typeError("Você precisa selecionar uma data válida.")
    .label("Data de Final"),
  note: yup.string(),
});

export { dataSchema };
