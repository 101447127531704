import { useEffect, useState, useContext } from "react";

import {
  Container,
  Row,
  Spinner,
  Navbar,
  Button,
  Form,
  FormControl,
} from "react-bootstrap";

import { FaPlusCircle } from "react-icons/fa";

import TableUser from "../../components/user/pageComponents/tableUser";

import AddUser from "../../components/user/pageModals/addUser";
import EditUser from "../../components/user/pageModals/editUser";
import DeleteUser from "../../components/user/pageModals/deleteUser";

import { getUsers } from "../../controllers/ControllerUser";

import { AuthContext } from "../../configurations/authorization";

function User() {
  const { accessToken, code3cData, system3cData } = useContext(AuthContext);

  const [userList, setUserList] = useState([]);
  const [accountsNumber, setAccountsNumber] = useState(20);

  const [inputSearch, setInputSearch] = useState("");

  const [toggleSpinner, setToggleSpinner] = useState(false);

  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showEditUserModal, setShowEditUserModal] = useState({ show: false });
  const [showDeleteUserModal, setShowDeleteUserModal] = useState({
    show: false,
  });

  async function getData() {
    setAccountsNumber(system3cData.systemBilling.systemAccounts);
    const dataResponse = await getUsers(code3cData.id);
    if (dataResponse) {
      setUserList(
        dataResponse.map((user) => {
          var [systemFAGRO] = user.systems.filter(
            (stm) => stm.systemId === "FAGRO"
          );
          return { ...user, systems: systemFAGRO };
        })
      );
      setToggleSpinner(true);
    }
  }

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  return (
    <Container
      className="rounded bg-white my-3 pb-2 px-0 ubuntuFont"
      style={{ height: " 475.4px" }}
    >
      <AddUser
        showAddUserModal={showAddUserModal}
        setShowAddUserModal={setShowAddUserModal}
        code3c_id={code3cData.id}
        token={accessToken}
        getData={getData}
      />
      <EditUser
        showEditUserModal={showEditUserModal}
        setShowEditUserModal={setShowEditUserModal}
        getData={getData}
        code3c_id={code3cData.id}
        token={accessToken}
      />
      <DeleteUser
        showDeleteUserModal={showDeleteUserModal}
        setShowDeleteUserModal={setShowDeleteUserModal}
        getData={getData}
        code3c_id={code3cData.id}
        token={accessToken}
      />

      <h2 className="text-center py-3">USUÁRIOS</h2>
      <hr className="mt-0" />
      <Navbar
        bg="white"
        variant="white"
        className="mb-3 justify-content-between"
      >
        <Navbar.Brand className="link">
          <Button
            variant="outline-success"
            //disabled={accountsNumber <= userList.length ? true : false}
            onClick={() => {
              setShowAddUserModal(!showAddUserModal);
            }}
          >
            <FaPlusCircle className="text-outline-success" />
            &nbsp; Novo Usuário
          </Button>
        </Navbar.Brand>
        <Form inline className="justify-content-end">
          <FormControl
            type="text"
            placeholder="Pesquisar usuário"
            className="mr-sm-2 justify-content-end"
            value={inputSearch}
            onChange={(e) => setInputSearch(e.target.value)}
          />
        </Form>
      </Navbar>
      <Container className="table-max-height">
        {userList === 0 && !toggleSpinner ? (
          <Row>
            <Spinner className="mx-auto my-5 text-system" animation="border" />
          </Row>
        ) : (
          <TableUser
            userList={userList}
            inputSearch={inputSearch}
            setShowEditUserModal={setShowEditUserModal}
            setShowDeleteUserModal={setShowDeleteUserModal}
            accountsNumber={accountsNumber}
          />
        )}
      </Container>
    </Container>
  );
}

export default User;
