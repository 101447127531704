import { firebaseDB } from "../configurations/firebaseConfig";
import { toast } from "react-toastify";

const response = firebaseDB.collection("pesticide");

async function addPesticide(data) {
  try {
    const dataDB = await response.add(data);
    toast.success("Defensivo " + data.name + " foi adicionado!", 3000);
    return dataDB.id;
  } catch (err) {
    console.log(err);
    toast.error("Parece que tem algo errado com seu cadastro!", 3000);
  }
}

async function getPesticides(id) {
  try {
    const data = await response.where("code3c_id", "==", id).get();
    const allData = [];
    data.docs.forEach((item) => {
      allData.push({ ...item.data(), id: item.id });
    });
    return allData;
  } catch (err) {
    console.log(err);
    toast.error("Não foi possível ler os dados.");
  }
}

async function searchPesticide(id) {
  try {
    const data = await response.doc(id).get();
    return { ...data.data(), id: id };
  } catch (err) {
    console.log(err);
    toast.error("Não foi possível ler os dados.");
  }
}

async function editPesticide(data, id) {
  try {
    const dataDBExists = await response.doc(id).get();
    if (!dataDBExists) {
      return [];
    } else {
      await response.doc(id).set(data);
      toast.success("Defensivo " + data.name + " foi editado!", 3000);
      return true;
    }
  } catch (err) {
    console.log(err);
    toast.error("Parece que tem algo errado com sua edição!", 3000);
  }
}

async function deletePesticide(name, id) {
  try {
    await response.doc(id).delete();
    toast.success("Defensivo " + name + " foi apagado!", 3000);
    return true;
  } catch (err) {
    console.log(err);
    toast.error("Parece que tem algo errado com sua edição!", 3000);
  }
}

export {
  addPesticide,
  getPesticides,
  searchPesticide,
  editPesticide,
  deletePesticide,
};
