import React, { useState, useEffect } from "react";

import { Container, Modal, Button, Table, Form } from "react-bootstrap";

import { FaSearch } from "react-icons/fa";

import { search3cCultivations } from "../../../controllers/ControllerCultivation";

function SearchCultivation(props) {
  const {
    showSearchCultivationModal,
    setShowSearchCultivationModal,
    handleCultivation,
    clearCultivation,
    cultivation_id,
    code3c_id,
  } = props;

  const [cultivationList, setCultivationList] = useState([]);
  const [inputSearch, setInputSearch] = useState("");

  function handleClose() {
    setShowSearchCultivationModal(!showSearchCultivationModal);
  }

  useEffect(() => {
    async function get3cCultivations() {
      const response = await search3cCultivations(code3c_id);
      if (response) {
        setCultivationList(response);
      }
    }
    get3cCultivations();
  }, [showSearchCultivationModal, code3c_id]);

  return (
    <Container className="ubuntuFont">
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        className="ubuntuFont"
        show={showSearchCultivationModal}
        onHide={handleClose}
        centered
      >
        <Modal.Header className="text-center" closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-center"
          >
            <FaSearch className="text-system" />
            &emsp;Selecione uma Cultura
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            type="text"
            placeholder="Pesquisar cultura"
            size="sm"
            className="mb-3 px-3"
            value={inputSearch}
            onChange={(e) => setInputSearch(e.target.value)}
            required
          />
          <Container className="table-max-height">
            <Table striped bordered hover size="sm" className="rounded">
              <thead>
                <tr>
                  <th width="2%">#</th>
                  <th width="88%">Cultura</th>
                  <th width="10%" className="text-center">
                    Selecionar
                  </th>
                </tr>
              </thead>
              <tbody>
                {cultivation_id !== "" && (
                  <tr>
                    <td
                      width="100%"
                      colSpan="3"
                      className="align-middle text-center"
                    >
                      <Button
                        variant="outline-danger"
                        size="sm"
                        onClick={() => clearCultivation()}
                      >
                        Limpar Cultura
                      </Button>
                    </td>
                  </tr>
                )}
                {cultivationList.length === 0 && (
                  <tr>
                    <td
                      width="100%"
                      colSpan="6"
                      className="align-middle text-center"
                    >
                      Nenhuma Cultura Encontrada
                    </td>
                  </tr>
                )}
                {cultivationList
                  .sort(function (a, b) {
                    var nameA = a.name.toLowerCase(),
                      nameB = b.name.toLowerCase();
                    if (nameA < nameB) return -1;
                    if (nameA > nameB) return 1;
                    return 0;
                  })
                  .filter(
                    (item) =>
                      item.id.indexOf(inputSearch) !== -1 ||
                      item.name
                        .toUpperCase()
                        .indexOf(inputSearch.toUpperCase()) !== -1,
                  )
                  .map((cultivation, index) => (
                    <tr key={index}>
                      <td width="2%" className="align-middle">
                        <b>{index + 1}</b>
                      </td>
                      <td width="63%" className="align-middle">
                        {cultivation.name}
                      </td>
                      <td width="10%" className="text-center align-middle">
                        <Button
                          disabled={
                            cultivation_id === cultivation.id ? true : false
                          }
                          className="bg-system"
                          size="sm"
                          onClick={() =>
                            handleCultivation(cultivation.id, cultivation.name)
                          }
                        >
                          Selecionar
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => handleClose()}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default SearchCultivation;
