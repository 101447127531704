import React, { useEffect, useState, useContext } from "react";

import {
  Container,
  Row,
  Spinner,
  Navbar,
  Button,
  Form,
  FormControl,
} from "react-bootstrap";

import { FaFilter } from "react-icons/fa";

import TableVisit from "../../components/recommendation/pageComponents/tableVisit";

import EditVisit from "../../components/recommendation/pageModals/editVisit";
import DeleteVisit from "../../components/recommendation/pageModals/deleteVisit";

import FilterVisitor from "../../components/recommendation/pageModals/filterVisitor";

import { getVisits } from "../../controllers/ControllerVisit";

import { AuthContext } from "../../configurations/authorization";

function Recommendation() {
  const { code3cData } = useContext(AuthContext);

  const [visitList, setVisitList] = useState([]);

  const [inputSearch, setInputSearch] = useState("");
  const [inputFilter, setInputFilter] = useState("");

  const [toggleSpinner, setToggleSpinner] = useState(false);

  const [showEditVisitModal, setShowEditVisitModal] = useState({
    show: false,
  });
  const [showDeleteVisitModal, setShowDeleteVisitModal] = useState({
    show: false,
  });

  const [showFilterVisitorModal, setShowFilterVisitorModal] = useState(false);

  function handleVisitor(types) {
    setInputFilter(types);
    setShowFilterVisitorModal(false);
  }
  function clearVisitor(types) {
    setInputFilter("");
    setShowFilterVisitorModal(false);
  }

  async function getData() {
    const dataResponse = await getVisits(code3cData.id);
    if (dataResponse) {
      setVisitList(dataResponse);
      setToggleSpinner(true);
    }
  }

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  return (
    <Container
      className="rounded bg-white my-3 pb-2 px-0 ubuntuFont"
      style={{ height: " 475.4px" }}
    >
      <EditVisit
        showEditVisitModal={showEditVisitModal}
        setShowEditVisitModal={setShowEditVisitModal}
        getData={getData}
        code3c_id={code3cData.id}
      />
      <DeleteVisit
        showDeleteVisitModal={showDeleteVisitModal}
        setShowDeleteVisitModal={setShowDeleteVisitModal}
        getData={getData}
        code3c_id={code3cData.id}
      />

      <FilterVisitor
        showFilterVisitorModal={showFilterVisitorModal}
        setShowFilterVisitorModal={setShowFilterVisitorModal}
        handleVisitor={handleVisitor}
        clearVisitor={clearVisitor}
        inputFilter={inputFilter}
        code3c_id={code3cData.id}
      />

      <h2 className="text-center py-3">RECOMENDAÇÕES</h2>
      <hr className="mt-0" />
      <Navbar
        bg="white"
        variant="white"
        className="mb-3 justify-content-between"
      >
        <Navbar.Brand className="link">
          <Button
            variant="outline-primary"
            className=" mx-1"
            onClick={() => setShowFilterVisitorModal(true)}
          >
            <FaFilter className="text-outline-primary" />
            &nbsp; {inputFilter === "" ? "Filtrar Visitante" : inputFilter}
          </Button>
        </Navbar.Brand>
        <Form inline className="justify-content-end">
          <FormControl
            type="text"
            placeholder="Pesquisar visita"
            className="mr-sm-2 justify-content-end"
            value={inputSearch}
            onChange={(e) => setInputSearch(e.target.value)}
          />
        </Form>
      </Navbar>
      <Container className="table-max-height">
        {visitList === 0 && !toggleSpinner ? (
          <Row>
            <Spinner className="mx-auto my-5 text-system" animation="border" />
          </Row>
        ) : (
          <TableVisit
            getData={getData}
            visitList={visitList}
            inputSearch={inputSearch}
            inputFilter={inputFilter}
            setShowEditVisitModal={setShowEditVisitModal}
            setShowDeleteVisitModal={setShowDeleteVisitModal}
          />
        )}
      </Container>
    </Container>
  );
}

export default Recommendation;
