import React, { createRef, useState } from "react";

import { FaPlusCircle } from "react-icons/fa";

import { Container, Modal, Button, Row, Col } from "react-bootstrap";
import { FaRegCheckCircle, FaRegCircle } from "react-icons/fa";

import html2pdf from "html2pdf.js";
import moment from "moment";

const ref = createRef();

function NewVisitDoc(props) {
  const {
    showNewVisitDocModal,
    setShowNewVisitDocModal,
    Document,
    code3cData,
  } = props;

  const [showImages, setShowImages] = useState(true);
  const [showDescription, setShowDescription] = useState(true);

  function handleClose() {
    setShowNewVisitDocModal({
      ...showNewVisitDocModal,
      show: false,
    });
  }

  function toPdfSave() {
    const divToDisplay = document.getElementById("div1");
    var opt = {
      margin: 0.3,
      filename: "myfile.pdf",
      image: { type: "jpeg", quality: 0.8 },
      pagebreak: {
        avoid: "#container-dont-break",
        before: "#field-break-page",
      },
      html2canvas: {
        scale: 2,
        dpi: 300,
        letterRendering: true,
        useCORS: true,
      },
      jsPDF: { unit: "in", format: "letter" },
    };

    html2pdf()
      .set(opt)
      .from(divToDisplay)
      .save(
        code3cData.name.replace(" ", "").toLowerCase() +
          "-visita-" +
          moment().format("L") +
          ".pdf",
      );
  }

  return (
    <Container className="ubuntuFont">
      <Modal
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        className="ubuntuFont"
        show={showNewVisitDocModal.show}
        onHide={handleClose}
        centered
      >
        <Modal.Header className="text-center" closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-center"
          >
            <FaPlusCircle className="text-success" />
            &emsp;Exportar Relatório
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col className="text-center">
                <h5 className="text-system">CONFIGURAÇÕES DO DOCUMENTO</h5>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col className="text-center" md={6}>
                {showImages ? (
                  <FaRegCheckCircle
                    className="text-system link"
                    onClick={() => setShowImages(false)}
                  />
                ) : (
                  <FaRegCircle
                    className="link"
                    onClick={() => setShowImages(true)}
                  />
                )}
                &emsp; Exportar Imagens
              </Col>
              <Col className="text-center" md={6}>
                {showDescription ? (
                  <FaRegCheckCircle
                    className="text-system link"
                    onClick={() => setShowDescription(false)}
                  />
                ) : (
                  <FaRegCircle
                    className="link"
                    onClick={() => setShowDescription(true)}
                  />
                )}
                &emsp; Exportar Descrição
              </Col>
            </Row>
            <Row className="mx-auto">
              <Button
                variant="success"
                className="mx-auto"
                onClick={() => {
                  toPdfSave();
                }}
              >
                Exportar em PDF
              </Button>
            </Row>
          </Container>
          <div
            id="div"
            className="mx-auto border my-4 p-5"
            style={{
              width: "894px",
              backgroundColor: "white",
              boxShadow: "0 0 1em rgba(0,0,0,.21)",
            }}
          >
            <div
              id="div1"
              ref={ref}
              className="p-2"
              style={{
                width: "750px",
                backgroundColor: "white",
              }}
            >
              <Document
                visit={showNewVisitDocModal.visit}
                harvestList={showNewVisitDocModal.harvestList}
                recommendationList={showNewVisitDocModal.recommendationList}
                TableRecommendationType={
                  showNewVisitDocModal.TableRecommendationType
                }
                showImages={showImages}
                showDescription={showDescription}
                code3cData={code3cData}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            className="mr-2"
            onClick={() => {
              toPdfSave();
            }}
          >
            Exportar em PDF
          </Button>
          <Button variant="danger" onClick={() => handleClose()}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default NewVisitDoc;
